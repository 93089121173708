import React, { useContext, useEffect, useRef, useState } from 'react';
import Busy from '../components/Busy';
import ScrollEndTrigger from '../components/ScrollEndTrigger';
import { ErrorStatusModal, SuccessStatusModal } from '../components/StatusModal';
import Table from '../components/Table';
import CardLayout from '../layouts/CardLayout';
import Util from '../Util';
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import Paths from '../Paths';
import Button from '../components/Button';
import InputField from '../components/InputField';
import ActionHeader from '../components/ActionHeader';

function CreatePump(props) {

    const
        [state, setState] = useState({
            formActive: false,
            formDone: false,
            text: '',
            error: false,

            gettingTanks: false,
            gettingTanksDone: false,
            gettingTanksError: false,
            gettingTanksText: '',
            tanks: null
        }),
        [errors, setErrors] = useState({}),

        { user: { outletId } } = useContext(Util.UserContext),

        navigate = useNavigate();


    useEffect(() => {
        if (state.tanks !== null) return;

        Util.request.get(`gettanks/${outletId}`)
            .then(res => {
                setState(o => ({
                    ...o,
                    gettingTanks: false,
                    gettingTanksDone: true,
                    gettingTanksError: !res.status,
                    tanks: res.status ? res.data : false,
                    gettingTanksText: res.message
                }))
            });

        setState(o => ({ ...o, gettingTanks: true }));
    }, [])


    const handleSubmit = e => {
        e.preventDefault();

        Util.request.post('createpump', new FormData(e.target))
            .then(res => {
                if (res.status) {
                    setState(o => ({ ...o, formActive: false, formDone: true, text: res.message, error: false }));
                    setErrors({});
                } else {
                    if (typeof res.message === 'string')
                        res.message = { 'unique_name': [{ message: res.message }] }
                    setErrors(res.message);
                    setState(o => ({ ...o, formActive: false, formDone: true, text: '', error: true }));
                }
            })

        setState(o => ({ ...o, formActive: true }));
    };

    return (
        <CardLayout
            pageName='Create pump' pageIcon='plus'
            cardHeader={(state.tanks && state.tanks.length === 0) && <ActionHeader title='You have no tanks.'/>}
        >

            <Busy busy={state.formActive} busyText='Creating pump...' />

            <Busy busy={state.gettingTanks} />

            <SuccessStatusModal
                onDone={() => setState(o => ({ ...o, formDone: false }))}
                show={state.formDone && !state.error} text={state.text} />



            <ErrorStatusModal
                onDone={() => setState(o => ({ ...o, gettingTanksDone: false }))}
                show={state.gettingTanksDone && state.gettingTanksError} text={state.gettingTanksText} />




            {(function () {
                if (state.tanks && state.tanks.length) {
                    return <form method='post' onSubmit={handleSubmit}>

                        <div className='row'>

                            <div className='mb-3 col-12 col-md-6'>
                                <label className='form-label' htmlFor='unique_name'>Select tank</label>
                                <select className='form-select' name='tank'>
                                    {state.tanks.map(t => <option key={t.tankId} value={t.tankId}>{t.tankName}</option>)}
                                </select>
                            </div>


                            <div className='mb-3 col-12 col-md-6'>
                                <InputField name='unique_name' labelText='Pump name' errs={errors} />
                            </div>


                            <div className='mb-3 col-12 col-md-6'>
                                <InputField name='initial_reading' labelText='Initial reading' attrs={{ type: 'number', min: 0, defaultValue: 0, step: '.001' }} errs={errors} />
                            </div>


                            <div className='mb-3 col-12 col-md-6'>
                                <InputField name='factor' labelText='Deduction factor' attrs={{ type: 'number', min: 0, max:1, defaultValue: 1, step: '.001' }} errs={errors} />
                            </div>
                        </div>


                        <button className='btn btn-primary'>
                            <i className='fa fa-plus me-2'></i>
                            Create pump
                        </button>
                    </form>
                } else if (state.gettingTanksDone && !state.tanks.length) {
                    return <center className='text-muted'>
                        You have no pumps for this Outlet.
                    </center>
                }
            })()}
        </CardLayout>
    );
}

export default CreatePump;