import React, { useEffect, useMemo, useRef } from 'react';
import Util from '../Util';
import InputField from './InputField';
import Button from './Button';



export default function DatePicker({ date, onDateChange = date => { } }) {

    const
        handler = e => {
            e.preventDefault();
            if (document.activeElement) return;
            if (e.code === 'ArrowLeft') skipDay(-1);
            else if (e.code === 'ArrowRight') skipDay(1);
        },
        { current: id } = useRef(`date-${Date.now()}`);

    function skipDay(by) {
        const d = new Date(document.getElementById(id).value);
        d.setDate(d.getDate() + by);
        onDateChange(Util.dateString(d, true));
    }

    useEffect(() => {
        document.body.addEventListener('keyup', handler);

        return () => document.body.removeEventListener('keyup', handler);
    }, []);

    return (
        <div className='row mb-5'>
            <div className='col-12 col-md-4 d-flex align-items-end'>
                <div className='flex-grow-1 me-2'>
                    <InputField name='date' attrs={{ value: date, id, onChange: e => onDateChange(e.target.value) }} labelText='Date picker' type='date' />
                </div>
                <Button icon='arrow-left' outline onClick={e => skipDay(-1)} />
                <div className="ms-2"></div>
                <Button icon='arrow-right' outline onClick={e => skipDay(1)} />
            </div>
        </div>
    )
}
