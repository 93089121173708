import React, { useContext, useEffect, useState } from 'react';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import './App.css';
import Busy from './components/Busy';

import Header from './layouts/Header';
import SideNav from './layouts/SideNav';
import Paths from './Paths';
import Util from './Util';


function App(props) {
    const
        navigate = useNavigate(),
        [userObject, setUserObject] = useState({
            user: Util.getUserObject(),
            setUser(user) {
                Util.setUserObject(user);
                setUserObject({ ...this, user: Util.getUserObject()})
            },
            clearUser(){
                Util.clearUserObject()
            },
            get outletObject(){
                return this.user.___selectedOutlet___;
            },
            setProductTypes(types){

                const user = {...Util.getUserObject()};

                user.productTypes = types;

                Util.setUserObject(user);

                setUserObject({...this, user: Util.getUserObject()});
            },
            get currentOutlet() {
                if( ! this.user ) return -1;

                //Prioritize own outlet, if not available,
                // if (this.user.position.canManageOutlet) return this.user.outletId;

                // check if user can be staff, then get outlet id
                if (this.user.position.canBeStaff) return this.user.staffOutlet.outletId;

                //or else check for some selected outlet
                if(this.user.___selectedOutlet___)
                    return this.user.___selectedOutlet___.outletId;

                return -1
            },
            /**
             * @param {Object} outlet
             */
            setCurrentOutlet(outlet) {
                if( this.user ) {
                    this.user.___selectedOutlet___ = outlet;
                    this.setUser(this.user);
                    return outlet.id;
                }
            }
        }),
        [firstTimeLoad, setFirstTimeLoad] = useState(true),
        location = useLocation()





    useEffect(() => {
        if (userObject.currentOutlet === -1 && !location.pathname.endsWith(Paths.SELECT_OUTLET))
            navigate(Paths.SELECT_OUTLET.$, { replace: true });
    });

    useEffect(() => {
        if (userObject === null)
            navigate(Paths.LOGIN.$, { replace: true });
        else {
            Util.request.get('login/confirm').then(response => {
                if (!response.status || !userObject.user || response.data.username != userObject.user.username) {
                    Util.clearUserObject();
                    navigate(Paths.LOGIN.params({ logout: 1 }).$, { replace: true });
                }
                else setFirstTimeLoad(false);
            });
        }
    }, []);



    return (<Util.UserContext.Provider value={userObject}>
        {userObject.user && <main className='d-flex h-100 fg-color bg-color' data-theme='light'>

            <SideNav />

            <section className='flex-grow-1 d-flex flex-column overflow-hidden'>

                <Header
                    authorized={true} authenticated={!!userObject.user} />

                <div className='flex-grow-1 container-fluid d-flex flex-column overflow-hidden mb-3'>

                    <Outlet />

                </div>

            </section>

            {<Busy busyText='Verifying authentication...' busy={firstTimeLoad} />}
        </main>}
    </Util.UserContext.Provider>);
}

export default App;
