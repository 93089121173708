import React, { useContext, useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Button from '../components/Button';
import ScrollEndTrigger from '../components/ScrollEndTrigger';
import Table from '../components/Table';
import CardLayout from '../layouts/CardLayout';
import Paths from '../Paths';
import Util from '../Util';
import ActionHeader from '../components/ActionHeader';

function ProductTypes(props) {

    const
        { user: { productTypes, position: pos } } = useContext(Util.UserContext),
        navigate = useNavigate();

    return (
        <CardLayout
            pageName='Product types' pageIcon='table'
            cardHeader={<ActionHeader actions={[{
                onClick: () => navigate(Paths.DAILY_SALES.join(Paths.CREATE_PROUDCT_TYPE).abs.$),
                text: 'Create type',
                icon: 'plus',
                showIf: pos.canManageOutlet,
            }]} />}
        >
            <ScrollEndTrigger maxWidth="400px" >
                <Table
                    data={productTypes}
                    tableHead={['Name', 'Current price per litre']}
                    keys={['name', 'pricePerLitre']}
                    actions={{ icon: 'edit', text: "Edit", on: data => navigate(Paths.DAILY_SALES.join(Paths.PRODUCT_TYPE_VIEW.parse(data.id)).abs.$) }}
                />
            </ScrollEndTrigger>



        </CardLayout>
    );
}


export default ProductTypes;