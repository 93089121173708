import React, { useContext, useEffect, useRef, useState } from 'react';
import Busy from '../components/Busy';
import ScrollEndTrigger from '../components/ScrollEndTrigger';
import Table from '../components/Table';
import CardLayout from '../layouts/CardLayout';
import Util from '../Util';
import { useNavigate } from "react-router-dom";
import Button from '../components/Button';
import { ErrorStatusModal, SuccessStatusModal } from '../components/StatusModal';
import Modal from '../components/Modal';

function MeterReadingsClear(props) {
    const
        [state, setState] = useState({
            records: [],
            gettingPumpsDone: false,
            gettingPumps: false,
            gettingPumpsFailed: false,
            gettingPumpsMessage: '',


            clearingReadingDone: false,
            clearingReading: false,
            clearingReadingFailed: false,
            clearingReadingMessage: ''
        }),
        dep = useRef(0),
        [checkedPumps, setCheckedPumps] = useState({}),
        navigate = useNavigate(),
        { currentOutlet: outletId } = useContext(Util.UserContext),
        canClearReading = () => Object.values(checkedPumps).some(v => v);

    useEffect(() => {
        Util.request.get(`getpumps/${outletId}`)
            .then(res => {

                if (res.status)
                    for (const pump of res.data)
                        pump.input = <input name='pump'
                            value={pump.pumpId} className='form-check-input' type='checkbox'
                            onChange={e => setCheckedPumps(o => ({ ...o, [pump.pumpId]: !!e.target.checked }))} />

                setState(o => ({
                    ...o,
                    records: res.status ? res.data : [],
                    gettingPumpsDone: true,
                    gettingPumps: false,
                    gettingPumpsFailed: !res.status,
                    gettingPumpsMessage: res.message
                }));
            });
        setState(o => ({ ...o, gettingPumps: true }));
    }, [dep.current]);

    function handleClearReading() {

        if (!canClearReading()) return;

        const fd = new FormData();

        for (const id in checkedPumps)
            checkedPumps[id] && fd.append('pump', id);

        Util.request.post(`readingsclear/${outletId}`, fd)
            .then(res => {
                res.status && ++dep.current;
                setState(o => ({
                    ...o,
                    clearingReading: false,
                    clearingReadingDone: true,
                    clearingReadingFailed: !res.status,
                    clearingReadingMessage: res.message
                }))
            });

        setState(o => ({ ...o, clearingReading: true }));
    }


    return (
        <CardLayout
            pageName='Clear meter reading'
            pageIcon='minus'
            cardFooter={canClearReading() &&
                <Button icon='minus' text='Clear reading' outline sm
                    attrs={{ type: 'button', 'data-bs-toggle': 'modal', 'data-bs-target': '#confirm-clear' }} />}
        >
            <Busy busyText='Retrieving pumps...' busy={state.gettingPumps} />

            <Busy busyText='Clearing meter readings...' busy={state.clearingReading} />


            <Modal id='confirm-clear' title='Clear meter reading?' on={handleClearReading} type='danger' primary={{ text: 'Yes' }}>
                <p>Are you sure you want to clear meter reading for selected pump(s)?</p>
                <small className='text-muted small'><b>NOTE:</b> This will not clear rolled over readings.</small>
            </Modal>

            <ErrorStatusModal
                show={state.gettingPumpsDone && state.gettingPumpsFailed}
                onDone={() => setState(o => ({ ...o, gettingPumpsDone: false }))}
                text={state.gettingPumpsMessage} />



            <SuccessStatusModal
                show={state.clearingReadingDone && !state.clearingReadingFailed}
                onDone={() => setState(o => ({ ...o, clearingReadingDone: false }))}
                text={state.clearingReadingMessage} />



            <ErrorStatusModal
                show={state.clearingReadingDone && state.clearingReadingFailed}
                onDone={() => setState(o => ({ ...o, clearingReadingDone: false }))}
                text={state.clearingReadingMessage} />



            <ScrollEndTrigger>
                <Table
                    tableHead={['Unique name', 'Last reading', 'Date of reading', '']}
                    keys={['uniqueName', 'lastMeterReading', 'dateOfReading', 'input']}
                    data={state.records}
                />

            </ScrollEndTrigger>
        </CardLayout>
    )
}

export default MeterReadingsClear;