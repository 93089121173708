import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import { BrowserRouter, Route, Routes, Outlet } from 'react-router-dom';
import Analyse from './pages/Analyse';
import Dashboard from './pages/Dashboard';
import DailySales from './pages/DailySales';
import Cashbook from './pages/Cashbook';
import Consignments from './pages/Consignments';
import StockHistory from './pages/StockHistory';
import Stock from './pages/Stock';
import Supply from './pages/Supply';
import SupplyHistory from './pages/SupplyHistory';
import Users from './pages/Users';
import Paths from './Paths'
import Pumps from './pages/Pumps';
import CreatePump from './pages/CreatePump';
import PageOutlets from './pages/Outlets';
import PageOutlet from './pages/Outlet';
import Login from './pages/Login';
import CreateOutlet from './pages/CreateOutlet';
import CreateUser from './pages/CreateUser';
import Tanks from './pages/Tanks';
import CreateTank from './pages/CreateTank';
import MeterReadings from './pages/MeterReadings';
import SupplyEntries from './pages/SupplyEntries';
import Credit from './pages/Credit';
import Expense from './pages/Expense';
import RecordsHistory from './pages/RecordsHistory';
import AssignPump from './pages/AssignPump';
import CashRemit from './pages/CashRemit';
import CashRemitHistory from './pages/CashRemitHistory';
import CashRemitView from './pages/CashRemitView';
import ProductTypes from './pages/ProductTypes';
import RecordsEntries from './pages/RecordsEntries';
import Staff from './pages/Staff';
import CreateStaff from './pages/CreateStaff';
import ViewStaff from './pages/ViewStaff';
import TankTransfer from './pages/TankTransfer';
import TankTransferHistory from './pages/TankTransferHistory';
import SelectOutlet from './pages/SelectOutlet';
import ProductTypesView from './pages/ProductTypesView';
import CreateProductTypes from './pages/CreateProductTypes';
import TankView from './pages/TankView';
import Tellers from './pages/Tellers';
import UploadTeller from './pages/UploadTeller';
import TotalizerOffsets from './pages/TotalizerOffsets';
import ResetTotalizer from './pages/ResetTotalizer';
import ChangePassword from './pages/ChangePassword';
import Profile from './pages/Profile';
import PumpReading from './pages/PumpReading';
import Error404 from './pages/Error404';
import PumpRollover from './pages/PumpRollover';
import Customers from './pages/Customers';
import CreateCustomer from './pages/CreateCustomer';
import PurchaseHistory from './pages/PurchaseHistory';
import Subscriptions from './pages/Subscriptions';
import CreateSubscription from './pages/CreateSubscription';
import MeterReadingsClear from './pages/MeterReadingsClear';
import MakePurchase from './pages/MakePurchase';
import PostpaidCustomers from './pages/PostpaidCustomers';
import CreatePostpaid from './pages/CreatePostpaid';
import TransferFunds from './pages/TransferFunds';
import PostpaidPurchaseHistory from './pages/PostpaidPurchaseHistory';
import PostpaidMakePurchase from './pages/PostpaidMakePurchase';
import PostpaidClearedHistory from './pages/PostpaidClearedHistory';
import Slips from './pages/Slips';
import AddSlip from './pages/AddSlip';
import Auditing, { AuditingView } from './pages/Auditing';
import PostpaidMakePayment from './pages/PostpaidMakePayment';
import OverdueTeller from './pages/OverdueTeller';
import OverdueCashbook from './pages/OverdueCashbook';
import OverdueCredits from './pages/OverdueCredits';
import OverdueExpenses from './pages/OverdueExpenses';
import PendingTellers from './components/PendingTellers';
import PumpModify from './pages/PumpModify';
import OverdueReadings from './pages/OverdueReadings';
import TellersVerify from './pages/TellersVerify';
import OverdueSlip from './pages/OverdueSlip';

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
    <BrowserRouter>
        <Routes>
            <Route element={<App />} path={Paths.DASHBOARD.$}>
                <Route index element={<Dashboard />} />




                <Route element={<SelectOutlet />} path={Paths.SELECT_OUTLET.$} />





                <Route element={<Outlet />} path={Paths.TELLERS.$}>
                    <Route element={<Tellers />} index />
                    <Route element={<TellersVerify />} path={Paths.TELLERS_VERIFY.$} />
                    <Route element={<UploadTeller />} path={Paths.UPLOAD_TELLER.$} />
                    <Route element={<PendingTellers />} path={Paths.PENDING_TELLERS.$} />
                </Route>





                <Route element={<Outlet />} path={Paths.ANALYSIS.$}>
                    <Route element={<Analyse />} path={Paths.MAKE_ANALYSIS.$} />
                    <Route element={<Auditing />} path={Paths.AUDITING.$} />
                </Route>




                <Route element={<Outlet />} path={Paths.STOCK.$}>
                    <Route index element={<Stock />} />
                    <Route path={Paths.STOCK_HISTORY.$} element={<StockHistory />} />
                </Route>





                <Route element={<Outlet />} path={Paths.SUPPLY.$}>
                    <Route index element={<Supply />} />
                    <Route path={Paths.SUPPLY_HISTORY.$} element={<SupplyHistory />} />
                    <Route path={Paths.SUPPLY_ENTRIES.$} element={<SupplyEntries />} />
                </Route>





                <Route element={<Outlet />} path={Paths.DAILY_SALES.$}>
                    <Route index element={<DailySales />} />
                    <Route path={Paths.PUMPS.$} element={<Pumps />} />
                    <Route path={Paths.CREATE_PUMP.$} element={<CreatePump />} />
                    <Route path={Paths.ASSIGN_PUMP.$} element={<AssignPump />} />
                    <Route path={Paths.PRODUCT_TYPE.$} element={<ProductTypes />} />
                    <Route path={Paths.PRODUCT_TYPE_VIEW.$} element={<ProductTypesView />} />
                    <Route path={Paths.CREATE_PROUDCT_TYPE.$} element={<CreateProductTypes />} />
                    <Route path={Paths.TANKS.$} element={<Tanks />} />
                    <Route path={Paths.CREATE_TANK.$} element={<CreateTank />} />
                    <Route path={Paths.SALES_HISTORY.$} element={<Consignments />} />
                    <Route path={Paths.METER_READINGS.$} element={<MeterReadings />} />
                    <Route path={Paths.READINGS_ROLLOVER.$} element={<PumpRollover />} />
                    <Route path={Paths.CLEAR_READINGS.$} element={<MeterReadingsClear />} />
                    <Route path={Paths.PUMP_READING.$} element={<PumpReading />} />
                    <Route path={Paths.PUMP_MODIFY.$} element={<PumpModify />} />
                    <Route path={Paths.TANK_TRANSFER.$} element={<TankTransfer />} />
                    <Route path={Paths.TANK_VIEW.$} element={<TankView />} />
                    <Route path={Paths.TOTALIZERS.$} element={<TotalizerOffsets />} />
                    <Route path={Paths.RESET_TOTALIZER.$} element={<ResetTotalizer />} />
                    <Route path={Paths.TANK_TRANSFER_HISTORY.$} element={<TankTransferHistory />} />
                    <Route path={Paths.SLIPS.$} element={<Slips />} />
                    <Route path={Paths.ADD_SLIP.$} element={<AddSlip />} />
                </Route>



                <Route element={<Outlet />} path={Paths.PREPAID.$}>
                    <Route index element={<Customers />} />
                    <Route path={Paths.NEW_CUSTOMER.$} element={<CreateCustomer />} />
                    <Route path={Paths.SUBSCRIPTIONS.$} element={<Subscriptions />} />
                    <Route path={Paths.PURCHASES.$} element={<PurchaseHistory />} />
                    <Route path={Paths.PURCHASE.$} element={<MakePurchase />} />
                    <Route path={Paths.SUBSCRIBE.$} element={<CreateSubscription />} />
                    <Route path={Paths.TRANSFER_FUNDS.$} element={<TransferFunds />} />
                </Route>



                <Route element={<Outlet />} path={Paths.POSTPAID.$}>
                    <Route index element={<PostpaidCustomers />} />
                    <Route path={Paths.NEW_POSTPAID.$} element={<CreatePostpaid />} />
                    <Route path={Paths.POSTPAID_PURCHASES.$} element={<PostpaidPurchaseHistory />} />
                    <Route path={Paths.POSTPAID_PURCHASE.$} element={<PostpaidMakePurchase />} />
                    <Route path={Paths.POSTPAID_CLEARED_PURCHASES.$} element={<PostpaidClearedHistory />} />
                    <Route path={Paths.POSTPAID_MAKE_PAYMENT.$} element={<PostpaidMakePayment />} />
                </Route>



                <Route element={<Outlet />} path={Paths.OVERDUE.$}>
                    <Route index element={<PostpaidCustomers />} />
                    <Route path={Paths.TELLERS.$} element={<OverdueTeller />} />
                    <Route path={Paths.CASHBOOK.$} element={<OverdueCashbook />} />
                    <Route path={Paths.CREDITS.$} element={<OverdueCredits />} />
                    <Route path={Paths.EXPENSES.$} element={<OverdueExpenses />} />
                    <Route path={Paths.OUTLETS.$} element={<OverdueReadings />} />
                    <Route path={Paths.SLIPS.$} element={<OverdueSlip />} />
                </Route>




                <Route element={<Outlet />} path={Paths.CASHBOOK.$}>
                    <Route index element={<Cashbook />} />
                </Route>




                <Route element={<Outlet />} path={Paths.CREDITS.$}>
                    <Route index element={<Credit />} />
                </Route>




                <Route element={<Outlet />} path={Paths.EXPENSES.$}>
                    <Route index element={<Expense />} />
                </Route>



                <Route element={<RecordsHistory />} path={Paths.RECORDS_HISTORY.$} />
                <Route element={<RecordsEntries />} path={Paths.RECORDS_ENTRIES.$} />




                <Route element={<Outlet />} path={Paths.OUTLET.$}>
                    <Route index element={<PageOutlet />} />
                    <Route element={<CreateOutlet />} path={Paths.CREATE_OUTLET.$} />
                    <Route element={<PageOutlets />} path={Paths.OUTLETS.$} />
                </Route>




                <Route element={<Outlet />} path={Paths.STAFF.$}>
                    <Route index element={<Staff />} />
                    <Route element={<CreateStaff />} path={Paths.CREATE_STAFF.$} />
                    <Route element={<ViewStaff />} path={Paths.VIEW_STAFF.$} />
                </Route>




                <Route element={<Outlet />} path={Paths.CREATE_OUTLET.$}></Route>

                <Route element={<Profile />} path={Paths.PROFILE.$}></Route>


                <Route element={<ChangePassword />} path={Paths.CHANGE_PASSWORD.$}></Route>




                <Route element={<Outlet />} path={Paths.USERS.$}>
                    <Route index element={<Users />} />
                    <Route element={<CreateUser />} path={Paths.CREATE_OUTLET.$} />
                </Route>



                <Route element={<Outlet />} path={Paths.CASH_REMIT.$}>
                    <Route index element={<CashRemit />} />
                    <Route element={<CashRemitHistory />} path={Paths.CASH_REMIT_HISTORY.$} />
                    <Route element={<CashRemitView />} path={Paths.CASH_REMIT_VIEW.$} />
                </Route>


            </Route>
            <Route element={<Login />} path={Paths.LOGIN.$}></Route>

            <Route element={<AuditingView />} path={Paths.AUDITING.$}></Route>

            <Route element={<Error404 />} path='*'></Route>
        </Routes>
    </BrowserRouter>
);
