import React from 'react';

//https://ronaldmnyim.com
//http://localhost:80
class Request {
    constructor(host = 'https://ronaldmnyim.com', port = 443) {
        this.__host = host;
        this.__port = port;
    }

    __getURL(url) {
        return `${this.__host}/api/${url}`;
    }

    __parse(responseText) {
        try {
            return JSON.parse(responseText);
        } catch (e) {
            return ({ status: false, message: 'Invalid server response.' })
        }
    }

    async post(url, formData = null) {
        const inst = this;
        return new Promise((res) => {
            const xhr = new XMLHttpRequest();
            // xhr.setRequestHeader('');
            xhr.open('POST', this.__getURL(url));
            xhr.onload = function () { res(inst.__parse(this.responseText)); }
            xhr.onerror = function () { res({ status: false, message: 'Server or connection error.' }) }
            xhr.withCredentials = true;
            xhr.send(formData);
        });
    }
    async get(url, param = {}) {
        return new Promise((res) => {
            const
                xhr = new XMLHttpRequest(),
                inst = this;
            xhr.open('GET', `${this.__getURL(url)}?${Object.entries(param).map(([k, v]) => `${k}=${v}`).join('&')}`);
            xhr.onload = function () { res(inst.__parse(this.responseText)); }
            xhr.onerror = function () { res({ status: false, message: 'Server or connection error.' }) }
            xhr.withCredentials = true;
            xhr.send();
        });
    }
}



const Util = {};


Util.CASHBOOK_TYPES = [
    { name: 'Sales of day', onlyOverdue: false },
    { name: 'Rollover entry', onlyOverdue: false },
    { name: 'Subscription', onlyOverdue: true },
    { name: 'Credit paid', onlyOverdue: true },
    { name: 'Other', onlyOverdue: false },
]



Util.PRODUCT_TYPE = ['PMS', 'AGO', 'DPK'];
Util.POSITIONS = ['Branch Manager', 'Director', 'General Manager'];
Util.USER_KEY = '___user___';
Util.RESULT_LENGTH = 100;


Util.request = new Request();
Util.UserContext = React.createContext(null);
Util.outletProductsKeys = [{ product: 'PMS', key: 'pmsTanks' }, { product: 'DPK', key: 'dpkTanks' }, { product: 'AGO', key: 'agoTanks' }]


Util.overdueKey = 'overdue';

Util.getUserObject = function () {
    return JSON.parse(localStorage.getItem(Util.USER_KEY));
}
Util.setUserObject = function (o) {
    Util.clearUserObject();
    localStorage.setItem(Util.USER_KEY, JSON.stringify(o));
}
Util.clearUserObject = function () {
    localStorage.removeItem(Util.USER_KEY);
}
Util.throttle = function (func, ms) {

    let suspended = false,
        savedArgs,
        savedThis;

    return function wrapper() {

        if (suspended) { // (2)
            savedArgs = arguments;
            savedThis = this;
            return;
        }
        suspended = true;

        func.apply(this, arguments); // (1)

        setTimeout(function () {
            suspended = false; // (3)
            wrapper.apply(savedThis, savedArgs);
            // savedArgs = savedThis = null;
        }, ms);
    }
}

Util.all = function (...values) {
    return values.every(v => !!v);
}

Util.any = function (...values) {
    return values.some(v => !!v);
}

Util.format = function (number) {
    return Intl.NumberFormat().format(number);
}

Util.filterPositions = (level, positions) => positions.filter(pos => pos.level < level);


Util.dateString = (date, iso = true) => {
    const d = date || new Date(), m = d.getMonth() + 1, day = d.getDate();
    return `${d.getFullYear()}-${iso ? `${m}`.padStart(2, '0') : m}-${iso ? `${day}`.padStart(2, '0') : day}`;

}

Util.today = (iso = false) => Util.dateString(null, iso);


Util.now = () => {
    const d = new Date(), h = String(d.getHours()), m = String(d.getMinutes()), s = String(d.getSeconds());
    return `${h.padStart(2, 0)}:${m.padStart(2, 0)}`;
}

Util.resolveResponseMessage = response => typeof response.message === 'string' ? response.message : 'Operation failed!';
Util.resolveFormError = response => typeof response.message === 'string' ? {} : response.message



export default Util;