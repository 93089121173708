import React, { useState, useEffect, useRef } from 'react';
import Busy from '../components/Busy';
import Button from '../components/Button';
import InputField from '../components/InputField';
import CardLayout from '../layouts/CardLayout';
import Util from '../Util';
import { ErrorStatusModal, SuccessStatusModal } from '../components/StatusModal';
import SelectInput from '../components/SelectInput';
import { useParams } from 'react-router-dom';



function PostpaidMakePurchase(props) {
    const
        [state, setState] = useState({
            gettingData: false,
            gettingDataDone: false,
            gettingDataMessage: '',
            gettingDataFailed: false,

            makingPurchase: false,
            makingPurchaseDone: false,
            makingPurchaseMessage: '',
            makingPurchaseFailed: false,

            data: { products: [], customer: {} },

            errs: {}
        }),
        [maxDiscount, setMaxDiscout] = useState(0),
        [discount, setDiscount] = useState(0),
        [litres, setLiters] = useState(0),
        { customerId = 0 } = useParams();


    function handleSetSelected({ target }) {
        setMaxDiscout(state.data.products
            .find(o => o.id === Number(target.value)).pricePerLitre || 0);
    }


    useEffect(() => {

        Util.request.get(`bundle/ppmakepurchase/${customerId}`)
            .then(res => {
                if (res.status) {
                    res.data.products = res.data.products.map(o => ({ ...o, name: `${o.name} - N${o.pricePerLitre}/L` }))
                }
                setState(o => ({
                    ...o,
                    gettingData: false,
                    gettingDataDone: true,
                    gettingDataFailed: !res.status,
                    gettingDataMessage: Util.resolveResponseMessage(res),
                    data: res.status ? res.data : state.data,
                    errs: Util.resolveFormError(res)
                }));
            });

        setState(o => ({ ...o, gettingData: true }));
    }, [])


    function handleMakePurchase(e) {

        e.preventDefault();

        const fd = new FormData(e.target);
        fd.append('postpaid_customer', customerId);

        Util.request.post(`ppcustomer/makepurchase`, fd)
            .then(res => {
                setState(o => ({
                    ...o,
                    makingPurchase: false,
                    makingPurchaseDone: true,
                    makingPurchaseMessage: Util.resolveResponseMessage(res),
                    makingPurchaseFailed: !res.status,

                    errs: Util.resolveFormError(res)
                }))
            });

        setState(o => ({ ...o, makingPurchase: true }));
    }

    return (
        <CardLayout
            cardElement='form'
            cardElementProps={{ onSubmit: handleMakePurchase }}
            pageName="postpaid purchase"
            pageIcon="receipt"
            cardFooter={<Button text='Make postpaid purchase' icon='receipt' />}>

            <Busy busy={state.makingPurchase} busyText='Making purchase...' />

            <Busy busy={state.gettingData} busyText='Getting customer data...' />

            <SuccessStatusModal
                show={state.makingPurchaseDone && !state.makingPurchaseFailed}
                onDone={() => setState(o => ({ ...o, makingPurchaseDone: false }))}
                text={state.makingPurchaseMessage} />
            {
                typeof state.makingPurchaseMessage === 'string' &&
                <ErrorStatusModal
                    show={state.makingPurchaseDone && state.makingPurchaseFailed}
                    onDone={() => setState(o => ({ ...o, makingPurchaseDone: false }))}
                    text={state.makingPurchaseMessage} />}


            <div className='row'>

                <div className='col-12 mb-4'>
                    <InputField name='customer' attrs={{ readOnly: true, disabled: true }} labelText='Customer' value={state.data.customer.name} labelIcon='user' />
                </div>

                <div className='col-12 mb-4'>
                    <SelectInput labelText='Product'
                        onChange={handleSetSelected} name='product' errs={state.errs}
                        defaultOption='Select product'
                        data={state.data.products} keys={['id', 'name']} />
                </div>

                <div className='col-12 col-md-6 mb-4'>
                    <InputField name='litres' errs={state.errs} labelText='Litres'
                        type='number' attrs={{ min: .01, value: litres, onInput: e => setLiters(Number(e.target.value)), step: .01 }}
                        labelIcon='gas-pump' />
                </div>

                <div className='col-12 col-md-6 mb-4'>
                    <InputField name='discount' errs={state.errs} labelText='Discount'
                        type='number' attrs={{ min: 0, max: maxDiscount, onInput: e => setDiscount(Number(e.target.value)), value: discount, step: .01 }}
                        labelIcon='arrow-down' />
                </div>

            </div>

            <hr />

            <div className=''>
                {
                    discount === 0 ?
                        <p className='text-primary m-0 h5'>Amount: N{Util.format(litres * maxDiscount)}</p> :
                        <div>
                            <p className='text-muted m-0'><del>Amount: N{Util.format(litres * maxDiscount)}</del></p>
                            <p className='text-primary m-0 h5'>Amount: N{Util.format(litres * (maxDiscount - discount))}</p>
                        </div>
                }
            </div>

        </CardLayout>
    );
}



export default PostpaidMakePurchase;