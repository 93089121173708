import React, { useEffect, useState, useContext, useRef } from 'react';
import CardLayout from '../layouts/CardLayout';
import Util from '../Util';
import Busy from '../components/Busy';
import { ErrorStatusModal, SuccessStatusModal } from '../components/StatusModal';
import Button from '../components/Button';
import Table from '../components/Table';
import { useParams } from 'react-router-dom';
import InputField from '../components/InputField';
import Modal from "../components/Modal";


function PumpReading(props) {
    let
        d = new Date(),

        dates = useRef({
            startDate: Util.today(),
            stopDate: Util.today(),
        }),

        inputs = useRef({}),

        [state, setState] = useState({
            gettingData: false,
            gettingDataDone: false,
            gettingDataText: '',
            gettingDataFailed: false,

            modifyingData: false,
            modifyingDataDone: false,
            modifyingDataText: '',
            modifyingDataFailed: false,

            data: [],
        }),

        { pumpId } = useParams(),

        { user: { position } } = useContext(Util.UserContext);



    useEffect(() => {
        const { startDate, stopDate } = dates.current;

        Util.request.get(`pumpreadings/${pumpId}/${startDate}/${stopDate}`)
            .then(res => {

                let data = res.status ? res.data : [];

                setState(o => ({
                    ...o,
                    gettingData: false,
                    gettingDataDone: true,
                    gettingDataFailed: !res.status,
                    gettingDataText: res.message,
                    data
                }));


            })

        setState(o => ({ ...o, gettingData: true }));

    }, [dates.current.startDate, dates.current.stopDate]);



    return (
        <CardLayout
            cardFooter={position.canModifyMeterReading && <Button icon='edit'
                attrs={{ 'data-bs-target': '#confirm-modify-modal', 'data-bs-toggle': 'modal' }}
                text='Modiy readings' type='button' />}
            pageName='Pump reading' pageIcon='calculator'
            cardHeader={<h6 className='m-0 text-primary'>Reading from {dates.current.startDate} to {dates.current.stopDate}</h6>}
        >
            <Busy busy={state.gettingData} busyText='Retrieving pump reading...' />

            <Busy busy={state.modifyingData} busyText='Modifying reading...' />


            <ErrorStatusModal
                show={state.gettingDataDone && state.gettingDataFailed}
                onDone={() => setState(o => ({ ...o, gettingDataDone: false }))}
                text={state.gettingDataText} />


            <SuccessStatusModal
                show={state.modifyingDataDone && !state.modifyingDataFailed}
                onDone={() => setState(o => ({ ...o, modifyingDataDone: false }))}
                text={state.modifyingDataText} />


            <ErrorStatusModal
                show={state.modifyingDataDone && state.modifyingDataFailed}
                onDone={() => setState(o => ({ ...o, modifyingDataDone: false }))}
                text={state.modifyingDataText} />



            <div>
                <div className='row mb-5'>
                    <div className='col-12 col-md-6 col-lg-2'>
                        <InputField
                            attrs={{ value: dates.current.startDate, onChange: e => dates.current.startDate = e.target.value, step: .001 }}
                            type='date' name='startDate' labelText='Start date'
                            labelIcon='calendar' />
                    </div>

                    <div className='col-12 col-md-6 col-lg-2'>
                        <InputField
                            attrs={{ value: dates.current.stopDate, onChange: e => dates.current.stopDate = e.target.value, step: .001 }}
                            type='date' name='stopDate' labelText='Stop date' labelIcon='calendar' />
                    </div>

                    <div className='col-12 mt-1'>
                        <Button type='button' sm text='Get' icon='download' onClick={$ => setState(o => ({ ...o }))} />
                    </div>
                </div>
            </div>




            <Table
                tableHead={['Date', 'Time', 'Reading', 'Price At Reading', 'Attendant']}
                keys={['date', 'time', 'meterReading', 'par', 'attendant']}
                data={state.data.map(o => ({ ...o, par: `N${Util.format(o.priceAtReading)}` }))} />

        </CardLayout>
    );
}

export default PumpReading;