import React, { useContext, useEffect, useState, useRef } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import Busy from '../components/Busy';
import Button from '../components/Button';
import ScrollEndTrigger from '../components/ScrollEndTrigger';
import Table from '../components/Table';
import CardLayout from '../layouts/CardLayout';
import Paths from '../Paths';
import Util from '../Util';
import Modal from '../components/Modal';
import { useQueryState } from '../hooks';
import { ErrorStatusModal, SuccessStatusModal } from '../components/StatusModal';
import ActionHeader from '../components/ActionHeader';

function Subscriptions(props) {
    const
        [state, setState] = useState({
            gettingSubs: false,
            gettingSubsDone: false,
            gettingSubsMessage: false,
            gettingSubsFailed: false,
            subs: []
        }),
        deleter = useQueryState(),
        deleteRef = useRef(0),

        { customerId = 0 } = useParams(),

        navigate = useNavigate(),

        { user: { position } } = useContext(Util.UserContext);


    useEffect(() => {

        Util.request.get(`subs/list/${customerId}`)
            // -DMF
            .then(res => setState(o => ({
                gettingSubs: false,
                gettingSubsDone: true,
                gettingSubsFailed: !res.status,
                gettingSubsMessage: res.message,
                subs: res.status ? res.data : state.subs
            })))

        setState(o => ({ ...o, gettingSubs: true }));
    }, [deleteRef.current]);

    function handleDeleteEntry() {
        Util.request.post(`subs/list/${customerId}/delete`)
            .then(res => {
                ++deleteRef.current;
                deleter.fromResponse(res)
            })
        deleter.busy('Deleting subscription entry.');
    }


    return (
        <CardLayout
            pageName="Subscriptions"
            pageIcon="list"
            cardFooter={<div className='d-flex justify-content-end'>
                <a href='#' data-bs-target='#modal-delete-entry' data-bs-toggle='modal' className='text-danger text-decoration-none'><b>Delete last entry</b></a>
            </div>}
            cardHeader={<ActionHeader actions={[{
                text: 'Transfer funds',
                icon: 'exchange',
                onClick: () => navigate(Paths.PREPAID.join(Paths.TRANSFER_FUNDS.parse(customerId)).abs.$),
                showIf: position.canCreateSubscription,

            }, {
                text: 'New subscription',
                icon: 'plus',
                onClick: () => navigate(Paths.PREPAID.join(Paths.SUBSCRIBE.parse(customerId)).abs.$),
                showIf: position.canCreateSubscription,

            },]} />}>

            <Modal on={handleDeleteEntry} type='danger' id='modal-delete-entry' title={'Delete entry?'}>
                <p>Are you sure you want to delete last entry?</p>
                <small><b>PS:</b> This operation will fail if any purchase has been made since last subscription was created.</small>
            </Modal>

            <deleter.Busy />
            <deleter.Modal />
            <Busy busy={state.gettingSubs} busyText='Retrieving purchase history...' />




            <ScrollEndTrigger>
                <Table
                    keys={['date', 'time', '$amount', 'subscriptionType']}
                    tableHead={['Date', 'Time', 'Amount', 'Type']}
                    data={state.subs.map(sub => {
                        sub.$amount = `N${Util.format(sub.amount)}`;
                        return sub;
                    })}
                />
            </ScrollEndTrigger>
        </CardLayout>
    );
}


export default Subscriptions;