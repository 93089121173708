import React, { useContext, useEffect, useState } from 'react';
import Busy from '../components/Busy';
import ScrollEndTrigger from '../components/ScrollEndTrigger';
import CardLayout from '../layouts/CardLayout';
import Util from '../Util';
import { useNavigate, useParams } from "react-router-dom";
import Button from '../components/Button';
import Paths from '../Paths';
import { ErrorStatusModal, SuccessStatusModal } from '../components/StatusModal'
import InputField from '../components/InputField';


// All the cashbooks by dates
function ResetTotalizer(props) {

    const
        date = new Date(),
        [state, setState] = useState(
            {

                resettingDone: false,
                resetting: false,
                resettingError: false,
                resettingText: '',

                gettingPumpDone: false,
                gettingPump: false,
                gettingPumpError: false,
                gettingPumpText: '',

                pump: null,

                errs: {}
            }),
        { pumpId = 0 } = useParams(),

        navigate = useNavigate();

    useEffect(() => {
        Util.request.get(`getpump/${pumpId}`)
            .then(res => {
                setState(o => ({
                    ...o,
                    gettingPump: false,
                    gettingPumpgDone: true,
                    gettingPumpgText: res.message,
                    gettingPumpgError: !res.status,

                    pump: res.data
                }));
            });
        setState(o => ({ ...o, gettingPump: true }));
    }, [])

    const cardHeader = <div className='d-md-flex mb-2 mb-md-0 align-items-center justify-content-between'>
        <h6 className='text-primary m-0'>New meter value for: {state.pump ? state.pump.uniqueName : <i className='small text-muted'>No pump</i>}</h6>

    </div>;

    function handleResetReading(e) {
        e.preventDefault();

        const
            fd = new FormData(e.target),
            files = e.target.querySelector('[type=file]').files;

        if (files.length === 0) {
            setState(o => ({
                ...o,
                resettingDone: true,
                resetting: false,
                resettingError: true,
                resettingText: 'Image is required.',
            }))
            return;
        }
        fd.append('image', files[0]);

        Util.request.post(`readingreset/${pumpId}`, fd)
            .then(res => {
                setState(o => ({
                    ...o,
                    resettingText: Util.resolveResponseMessage(res),
                    resetting: false,
                    resettingDone: true,
                    resettingError: !res.status,
                    pump: res.status ? res.data : state.pump,
                    errs: Util.resolveFormError(res)
                }))
            });

        setState(o => ({ ...o, resetting: true }))
    }

    return (
        <CardLayout
            pageName='Pump reset'
            pageIcon='refresh'
            cardHeader={cardHeader}
            cardFooter={<Button icon='refresh' text='Reset' />}

            cardElement='form'
            cardElementProps={{ onSubmit: handleResetReading }}
        >

            <Busy busy={state.resetting} fixed text={`Resetting reading...`} />
            <Busy busy={state.gettingPump} fixed text={`Retrieving pump...`} />

            <SuccessStatusModal
                show={state.resettingDone && !state.resettingError}
                onDone={() => setState(o => ({ ...o, resettingDone: false }))}
                text={state.resettingText} />



            <ErrorStatusModal
                show={state.resettingDone && state.resettingError}
                onDone={() => setState(o => ({ ...o, resettingDone: false }))}
                text={state.resettingText} />



            <ErrorStatusModal
                show={state.gettingPumpDone && state.gettingPumpError}
                onDone={() => setState(o => ({ ...o, gettingPumpDone: false }))}
                text={state.gettingPumpgText} />

            <ScrollEndTrigger maxHeight='430px'>
                <div className='mb-4'>
                    <InputField type='number' name='__'
                        attrs={{ disabled: true }}
                        value={state.pump && state.pump.lastMeterReading}
                        labelText='Current reading'
                        errs={state.errs} />
                </div>

                <div className='mb-4'>
                    <InputField type='file' name='image' errs={state.errs} attrs={{ accept: 'image/*' }} labelText='Meter image' />
                </div>
                <InputField type='number' errs={state.errs} attrs={{ step: .01 }} name='meter_reading' labelText='New reading' />
            </ScrollEndTrigger>
        </CardLayout>
    )
}

export default ResetTotalizer;