import React, { useContext, useEffect, useRef, useState } from 'react';
import { useParams, Link } from "react-router-dom";
import Busy from '../components/Busy';
import ScrollEndTrigger from '../components/ScrollEndTrigger';
import { ErrorStatusModal, SuccessStatusModal } from '../components/StatusModal';
import Table from '../components/Table';
import CardLayout from '../layouts/CardLayout';
import Util from '../Util';

function StockHistory(props) {

    const
        [state, setState] = useState({
            gettingStock: false,
            gettingStockDone: false,
            gettingStockFailed: false,
            gettingStockText: '',
            stocks: null
        }),

        modalDuration = 1700,

        { currentOutlet: outletId } = useContext(Util.UserContext);

    useEffect(() => {

        if (state.stocks !== null) return;

        Util.request.get(`stocks/${outletId}`)
            .then(res => {
                setState({
                    gettingStock: false,
                    gettingStockDone: true,
                    gettingStockFailed: !res.status,
                    gettingStockText: res.message,
                    stocks: res.status ? res.data : []
                });
            });

        setState(o => ({ ...o, gettingStock: true }))
    }, [])

    return <CardLayout pageName='Stock history' pageIcon='clock'>


        <Busy busy={state.gettingStock} busyText='Retrieving stocks...' />

        <SuccessStatusModal
            onDone={() => setState(o => ({ ...o, gettingStockDone: false }))}
            show={state.gettingStockDone && !state.gettingStockFailed}
            text={state.gettingStockText}
            duration={modalDuration}
        />

        <ErrorStatusModal
            onDone={() => setState(o => ({ ...o, gettingStockDone: false }))}
            show={state.gettingStockDone && state.gettingStockFailed}
            text={state.gettingStockText}
        />

        {
            state.stocks && <ScrollEndTrigger maxHeight={'70vh'}>
                <table className='table table-border table-striped'>
                    <thead>
                        <tr>
                            <th>DATE</th>
                            {
                                Object.keys(state.stocks)
                                    .map((name, i) => <th key={`${name}`}>{name.toUpperCase()}</th>)
                            }
                        </tr>
                    </thead>
                    <tbody>
                        {(function () {

                            if (Object.values(state.stocks)[0].length < 1)
                                return <tr><td colSpan={99999} className='text-center text-muted'>Table is empty</td></tr>

                            let rows = [], values = Object.values(state.stocks), cols;


                            for (let row = 0; row < values[0].length; ++row) {
                                cols = [<td key={-1}>{values[0][row].date}</td>];

                                for (let col = 0; col < values.length; ++col) {
                                    cols.push(<td key={col+row}>{values[col][row].stockValue}</td>)
                                }
                                rows.push(<tr key={row}>{cols}</tr>)

                            }





                            return rows;
                        })()}
                    </tbody>
                </table>
            </ScrollEndTrigger>
        }
    </CardLayout>
}

/*  ,
                                ...cache.current[consignmentId]*/

export default StockHistory;