import React, { useState } from 'react';
import Busy from '../components/Busy';
import Button from '../components/Button';
import InputField from '../components/InputField';
import CardLayout from '../layouts/CardLayout';
import Util from '../Util';
import { ErrorStatusModal, SuccessStatusModal } from '../components/StatusModal'



function CreatePostpaid(props) {
    const
        [state, setState] = useState({
            creatingCustomer: false,
            creatingCustomerDone: false,
            creatingCustomerFailed: false,
            creatingCustomerMessage: '',
            errs: {},
        });


    function handleCreateCustomer(e) {

        e.preventDefault();

        Util.request.post(`ppcustomer/create`, new FormData(e.target))
            .then(res => setState(o => ({
                creatingCustomer: false,
                creatingCustomerDone: true,
                creatingCustomerMessage: Util.resolveResponseMessage(res),
                creatingCustomerFailed: !res.status,
                errs: Util.resolveFormError(res)
            })));

        setState(o => ({ ...o, creatingCustomer: true }))
    }


    return (
        <CardLayout
            cardElement='form'
            cardElementProps={{ onSubmit: handleCreateCustomer }}
            pageName="new postpaid customer"
            pageIcon="plus"
            cardFooter={<Button text='Add postpaid' icon='plus' />}>

            <Busy busy={state.creatingCustomer} busyText='Creating postpaid customer...' />


            <SuccessStatusModal
                show={state.creatingCustomerDone && !state.creatingCustomerFailed}
                onDone={() => setState(o => ({ ...o, creatingCustomerDone: false }))}
                text={state.creatingCustomerMessage} />





            <div className='row'>

                <div className='col-12 col-md-6 mb-4'>
                    <InputField name='name' labelText='Names' errs={state.errs} labelIcon='user' />
                </div>

                <div className='col-12 col-md-6 mb-4'>
                    <InputField name='phone' errs={state.errs} labelText='Phone' labelIcon='phone' />
                </div>
                {/* 
                <div className='col-12 col-md-6 mb-4'>
                    <div className="form-check">
                        <input className="form-check-input" type="checkbox" name='allow_credit_purchase' id="flexCheckDefault" />
                        <label className="form-check-label" htmlFor="flexCheckDefault">
                            Allow credit purchase
                        </label>
                    </div>
                </div> */}

            </div>


        </CardLayout>
    );
}


export default CreatePostpaid;