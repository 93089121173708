import React, { useContext, useEffect, useRef, useState } from 'react';
import Busy from '../components/Busy';
import ScrollEndTrigger from '../components/ScrollEndTrigger';
import { ErrorStatusModal, SuccessStatusModal } from '../components/StatusModal';
import Table from '../components/Table';
import CardLayout from '../layouts/CardLayout';
import Util from '../Util';
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import Paths from '../Paths';
import Button from '../components/Button';
import NumberInput from '../components/NumberInput';

function Expense(props) {




    const
        { current: rerender } = useRef({ $: 1 }),
        createRow = key => ({ subHead: '', value: 0 }),
        [rows, setRows] = useState([createRow()]),
        [state, setState] = useState({ formActive: false, formDone: false, error: false, text: '' });


    //add listener
    useEffect(() => {

        const keyupHandler = e => {
            e.preventDefault();
            e.stopPropagation();
            if (e.code === 'NumpadAdd' && document.activeElement.matches('[name=amount]'))
                setRows(r => [...r, createRow(++rerender.$)]);
        };

        document.addEventListener('keyup', keyupHandler);
        return () => document.removeEventListener('keyup', keyupHandler);
    }, []);

    //target subhead after rerender
    useEffect(() => {
        document.querySelector('tr:last-child [name=subhead]').select();
    }, [rerender.$]);

    function handleSubheadInput(e, i) {
        rows[i].subHead = e.target.value;
        setRows([...rows]);
    }

    function handleValueInput(e, n, i) {
        rows[i].value = n;
        setRows([...rows]);
    }

    function handleRemoveRow(index) {
        setRows(rows.filter(($, i) => i !== index));
    }

    function handleSubmit(e) {
        e.preventDefault();

        Util.request.post('addexpense', new FormData(e.target))
            .then(res => {
                setState(o => ({
                    formActive: false,
                    formDone: true,
                    error: !res.status,
                    text: typeof res.message === 'string' ? res.message : 'Operation failed'
                }));
                res.status && setRows([createRow()]);
            });

        setState(o => ({ ...o, formActive: true }));

    }

    const footer = <div className='d-flex justify-content-between'>
        <Button icon='save' text='Save' />
        <Button icon='plus' type='button' outline sm onClick={() => setRows([...rows, createRow()])} />
    </div>


    return (
        <CardLayout cardElement='form' cardElementProps={{ method: 'post', onSubmit: handleSubmit }}
            pageName='Enter Expenses' pageIcon='list' cardFooter={footer}>

            <Busy busy={state.formActive} busyText='Saving expense entries...' />


            <SuccessStatusModal onDone={() => setState(o => ({ ...o, formDone: false }))} show={state.formDone && !state.error} text={state.text} />


            <ErrorStatusModal onDone={() => setState(o => ({ ...o, formDone: false }))} show={state.formDone && state.error} text={state.text} />


            <ScrollEndTrigger maxHeight='370px' trigger={'false'}>
                <table className='table table-striped table-bordered fg-color'>
                    <thead className='text-primary'>
                        <tr>
                            <th></th>
                            <th>Subhead</th>
                            <th>Amount</th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
                        {rows.map((o, i, rows) => <tr key={`row-${i}`}>
                            <th>{i + 1}</th>
                            <td><input className='form-control form-control-sm' name='subhead' value={o.subHead} onInput={e => handleSubheadInput(e, i)} /></td>
                            <td><NumberInput style={{ maxWidth: '10rem' }} name='amount' className='form-control form-control-sm' type='number' step='.001' min={0} value={o.value} onNumberInput={(e, n) => handleValueInput(e, n, i)} /></td>
                            <td>{i > 0 && <Button secondary icon='times' type='button' sm outline onClick={e => handleRemoveRow(i)} />}</td>
                        </tr>)}
                    </tbody>
                    <tfoot>
                        <tr><td></td>
                            <td></td>
                            <td className='text-muted'>N{Util.format(rows.reduce((a, o) => a + o.value, 0))}</td>
                            <td></td></tr>
                    </tfoot>
                </table>
            </ScrollEndTrigger>
        </CardLayout>
    );
}

export default Expense;
