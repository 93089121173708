import React from 'react';

/**
 * 
 * @param {{
 * errs: {[index:string]: Array<object>},
 * id:string|undefined,
 * label:string|undefined,
 * icon:string|undefined,
 * name:string|undefined,
 * defaultValue: string|undefined,
 * value: string|undefined,
 * disabled: boolean | undefined,
 * multiple: boolean | undefined,
 * onChange: React.ChangeEventHandler<HTMLSelectElement> | undefined,
 * data: Array<object>,
 * option: (data: object) => React.DetailedHTMLProps<React.OptionHTMLAttributes<HTMLOptionElement>, HTMLOptionElement>,
 * }} 
 * @returns 
 */
function SelectInput2({
    disabled,
    data,
    name,
    id,
    defaultValue,
    value,
    onChange,
    multiple,
    option,
    errs,
    label,
    icon,
    ...props
}) {
    if (!name) return null;

    return (
        <div>
            <label className='form-label text-muted' htmlFor={id}>
                {!!label && <i className={`fa fa-${label} me-2`}></i>}
                {label}
            </label>

            <select
                className={`form-select ${(!!errs[name] ? 'error' : '')}`}
                id={id}
                name={name}
                defaultValue={defaultValue}
                value={value}
                disabled={disabled}
                onChange={onChange}
                multiple={multiple}
                {...props}
            >
                {data.map((o, i) => {
                    const { value, children, key, ...props } = option(o);
                    return <option key={key || i} value={value} {...props}>{children}</option>
                })}
            </select>
            {errs[name] && errs[name].map((e, i) => <span key={i}>{e.message}</span>)}
        </div>
    );
}

SelectInput2.defaultProps = {
    errs: {},
}

export default SelectInput2;