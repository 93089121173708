import React, { useContext, useEffect, useRef, useState } from 'react';
import Busy from '../components/Busy';
import ScrollEndTrigger from '../components/ScrollEndTrigger';
import { ErrorStatusModal, SuccessStatusModal } from '../components/StatusModal';
import Table from '../components/Table';
import CardLayout from '../layouts/CardLayout';
import Util from '../Util';
import { useNavigate, useParams, useSearchParams } from "react-router-dom";



function RecordsEntries(props) {
    const
        [state, setState] = useState({ records: [], formDone: false, formActive: false, error: false }),
        LENGTH = 50,
        { user: { outletId } } = useContext(Util.UserContext),
        navigate = useNavigate(),
        [searchParams] = useSearchParams(),
        { entry } = useParams(),
        record = {
            cashbookentries: 'Cashbook entries',
            creditentries: 'Credit entries',
            expenseentries: 'Expense entries',
        };

    useEffect(() => {
        Util.request.get(`${entry}/${outletId}/${searchParams.get('date') || '1970-1-1'}`)
            .then(res => {
                if (res.status) {
                    let sum = 0;
                    for (const o of res.data) {
                        o.total = Number(o.amount) + sum;
                        sum += Number(o.amount);
                    }

                }
                setState({
                    records: res.status ? res.data : [],
                    formDone: true,
                    formActive: false,
                    error: !res.status
                })
            });
        setState(o => ({ ...o, formActive: true }));
    }, []);


    return (
        <CardLayout pageName='Record entries' pageIcon='list' cardHeader={<h6 className='m-0 text-primary'>{record[entry]}</h6>}>
            <ScrollEndTrigger maxHeight='430px'>
                <Table
                    tableHead={['Date', 'Subhead', 'Amount', 'Cummulative']}
                    keys={['date', 'subhead', 'amount', 'total']}
                    data={state.records}
                />
            </ScrollEndTrigger>
        </CardLayout>
    )
}
export default RecordsEntries;