import React, { useContext, useEffect, useState } from 'react';
import CardLayout from '../layouts/CardLayout';
import Util from '../Util';
import Busy from '../components/Busy';
import ScrollEndTrigger from '../components/ScrollEndTrigger';
import { ErrorStatusModal } from '../components/StatusModal';
import Button from '../components/Button';
import Table from '../components/Table';
import InputField from '../components/InputField';
import DatePicker from '../components/DatePicker';
import ActionHeader from '../components/ActionHeader';

function Analyse(props) {
    let
        d = new Date(),

        [state, setState] = useState({
            gettingData: false,
            gettingDataDone: false,
            gettingDataText: '',
            gettingDataFailed: false,

            postpaid: [],
            prepaid: [],
            expenses: [],
            credits: [],
            cashbook: [],
            analysis: {},
            slips: [],
            rtt: [],  //return to tank
            tellerSum: 0,

            analysisNotice: false,

            savingAnalysis: false,
            savingAnalysisDone: false,
            savingAnalysisFailed: false,
            savingAnalysisText: '',

            selectedDate: Util.today(true)
        }),

        { currentOutlet } = useContext(Util.UserContext);


    function skipDay(by) {
        const d = new Date(state.selectedDate);
        d.setDate(d.getDate() + by);
        setState(o => ({ ...o, selectedDate: Util.dateString(d) }))
    }


    useEffect(() => {
        Util.request.get(`bundle/analysis/${currentOutlet}/${state.selectedDate}`)
            .then(res => {
                setState(o => ({
                    ...o,
                    gettingData: false,
                    gettingDataDone: true,
                    gettingDataText: '',
                    gettingDataFailed: false,

                    expenses: res.data.expenses,
                    credits: res.data.credits,
                    cashbook: res.data.cashbooks,
                    analysis: res.data.analysis || {},
                    prepaid: res.data.prepaid,
                    postpaid: res.data.postpaid,
                    rtt: res.data.rtt || [],
                    slips: res.data.slips || [],
                    tellerSum: res.data.tellerSum,
                    analysisNotice: res.message !== 'Successful' ? res.message : false
                }))
            });

        setState(o => ({ ...o, gettingData: true }));

    }, [state.selectedDate]);

    let totalSales = state.analysis.total_sales || 0;

    let
        totalExpense = state.expenses.reduce((accum, o) => accum + o.amount, 0),
        totalCredit = state.credits.reduce((accum, o) => accum + o.amount, 0),
        totalCash = state.cashbook.filter(o => o.isSalesOfDay).reduce((accum, o) => accum + o.amount, 0),
        rtt = state.rtt.reduce((c, v) => c + (v.rtt * v.price), 0),
        rolloverEntriesTotal = state.cashbook.filter(o => !o.isSalesOfDay).reduce((accum, o) => accum + o.amount, 0),
        tellerSum = state.tellerSum || 0,
        totalPrepaid = state.prepaid.reduce((acc, o) => acc + o.amount, 0),
        totalPostpaid = state.postpaid.reduce((acc, o) => acc + o.amount, 0),
        cashToBank = tellerSum - rolloverEntriesTotal,
        discountSum = [...state.prepaid, ...state.postpaid].reduce((a, o) => a + o.litres * o.discount, 0),
        slipSum = state.slips.reduce((acc, o) => acc + o.amount, 0),
        leftover = totalSales - (rtt + totalCredit + discountSum + slipSum + totalExpense + totalPostpaid + totalPrepaid + cashToBank);





    return (
        <CardLayout
            pageName='Daily analysis' pageIcon='chart-line'
            cardHeader={<ActionHeader title={`Analysis for ${state.selectedDate}`} />}
        >



            <Busy busy={state.gettingData} busyText='Retrieving analysis data...' />



            <Busy busy={state.savingAnalysis} busyText='submitting analysis...' />



            <ErrorStatusModal
                show={state.savingAnalysisDone && state.savingAnalysisFailed}
                onDone={() => setState(o => ({ ...o, savingAnalysisDone: false }))}
                text={state.savingAnalysisText} />



            <ErrorStatusModal
                show={state.gettingDataDone && state.gettingDataFailed}
                onDone={() => setState(o => ({ ...o, gettingDataDone: false }))}
                text={state.gettingDataText} />

            {
                state.analysisNotice &&
                <div className='alert alert-info'>{state.analysisNotice}</div>
            }

            {
                state.expenses && state.cashbook && state.credits &&

                <ScrollEndTrigger
                    stretch
                    maxHeight={!!state.analysisNotice ? '330px' : '400px'}
                >

                    <DatePicker date={state.selectedDate} onDateChange={d => setState(o => ({ ...o, selectedDate: d }))} />

                    {
                        state.rtt.length ? <div className='mb-5'>
                            <h6 className='fw-bold text-primary'>RETURN TO TANK</h6>
                            <div className='table-responsive'>
                                <Table
                                    tableHead={['Tank', 'Litres', 'Price']}
                                    data={state.rtt}
                                    keys={['tankName', 'rtt', 'price']}
                                    tableFoot={['Total', state.rtt.reduce((s, o) => s + o.rtt, 0), state.rtt.reduce((s, o) => s + o.price, 0)]}
                                />
                            </div>
                        </div> : false
                    }

                    <h6 className='fw-bold text-primary'>ANALYSIS</h6>
                    <div className='table-responsive'>
                        <table className='table table-striped table-sm table-bordered mb-5'>
                            <thead className='text-primary'>
                                <tr>
                                    <th></th>
                                    <th>Product</th>
                                    <th>Pumps sold</th>
                                    <th>Per price</th>
                                    <th>Amount</th>
                                </tr>
                            </thead>
                            <tbody>
                                {!!state.analysis.total_sales && state.analysis.pump_sales.filter(ps => ps.total_sales).map((ps, i) => (
                                    <tr key={i}>
                                        <th>{i + 1}</th>
                                        <td>{ps.type}</td>
                                        <td>
                                            <table className="table table-striped table-sm table-bordered">
                                                <thead>
                                                    <tr>
                                                        <th>Pump</th>
                                                        <th>Per price</th>
                                                        <th>Amount</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {ps.pump_sales.filter(ps => ps.total_sales > 0).map((ps, i) => (
                                                        <tr key={i}>
                                                            <td>{ps.name}</td>
                                                            <td>
                                                                <table className='table table-bordered table-striped table-sm'>
                                                                    <thead>
                                                                        <tr>
                                                                            <th>Litres</th>
                                                                            <th>Price</th>
                                                                            <th>Amount</th>
                                                                        </tr>
                                                                    </thead>
                                                                    <tbody>
                                                                        {ps.per_price.filter(pp => pp.litres).map((pp, i) => (
                                                                            <tr key={i}>
                                                                                <td>{Util.format(pp.litres)}L</td>
                                                                                <td>N{Util.format(pp.price)}</td>
                                                                                <td>N{Util.format(pp.litres * pp.price)}</td>
                                                                            </tr>
                                                                        ))}
                                                                    </tbody>

                                                                </table>
                                                            </td>
                                                            <td>N{Util.format(ps.total_sales)}</td>
                                                        </tr>
                                                    ))}
                                                </tbody>

                                            </table>
                                        </td>
                                        <td>
                                            <table className="table table-striped table-sm table-bordered">
                                                <thead className='text-primary'>
                                                    <tr>
                                                        <th>Litres</th>
                                                        <th>Price</th>
                                                        <th>Amount</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {ps.per_price.map((pp, i) => (
                                                        <tr key={i}>
                                                            <td>{Util.format(pp.litres)}L</td>
                                                            <td>N{Util.format(pp.price)}</td>
                                                            <td>N{Util.format(pp.litres * pp.price)}</td>
                                                        </tr>
                                                    ))}
                                                </tbody>
                                            </table>

                                        </td>
                                        <td>N{Util.format(ps.total_sales)}</td>
                                    </tr>
                                ))}
                            </tbody>
                            <tfoot className='text-primary text-center' style={{fontSize: '1.6rem'}}>
                                <tr>
                                    <th colSpan={4}>
                                        PUMP SALES
                                    </th>
                                    <th>
                                        N{Util.format(state.analysis.total_sales)}
                                    </th>
                                </tr>
                            </tfoot>
                        </table>
                    </div>



                    <div className='table-responsive'>
                        <table className='table table-striped stable-hover mb-5'>
                            <thead className='text-primary'>
                                <tr>
                                    {!!slipSum && <th>POS sales</th>}
                                    {!!totalExpense && <th>Expenses</th>}
                                    {!!totalCredit && <th>Credit</th>}
                                    {!!rtt && <th>RTT</th>}
                                    {!!totalPrepaid && <th>Prepaid</th>}
                                    {!!totalPostpaid && <th>Postpaid</th>}
                                    {!!discountSum && <th>Discount sum</th>}
                                    <th>Expected cash</th>
                                    <th>Cash to bank</th>
                                    <th>Cash leftover</th>
                                </tr>
                            </thead>
                            <tbody>
                                {(function () {

                                    return <tr>
                                        {!!slipSum && <td>N{Util.format(slipSum)}</td>}
                                        {!!totalExpense && <td>N{Util.format(totalExpense)}</td>}
                                        {!!totalCredit && <td>N{Util.format(totalCredit)}</td>}
                                        {!!rtt && <td>N{Util.format(rtt)}</td>}
                                        {!!totalPrepaid && <td>N{Util.format(totalPrepaid)}</td>}
                                        {!!totalPostpaid && <td>N{Util.format(totalPostpaid)}</td>}
                                        {!!discountSum && <td>N{Util.format(discountSum)}</td>}
                                        <td>N{Util.format(Math.max(totalSales - (totalCredit + discountSum + slipSum + totalExpense + totalPrepaid + totalPostpaid), 0))}</td>
                                        <td>N{Util.format(Math.max(cashToBank, 0))}</td>
                                        <td>N{Util.format(
                                            Math.max(leftover, 0)
                                        )}</td>
                                    </tr>
                                })()}
                            </tbody>
                        </table>
                    </div>

                    <div className='row'>




                        {!!state.prepaid.length && <div className='col-12 mb-5'>
                            <h6 className='text-primary mt-3'>PREPAID SALES</h6>
                            <Table
                                data={state.prepaid.slice().map(_o => {
                                    let o = { ..._o };
                                    o.amount = `N${Util.format(o.amount)}`;
                                    o.price = `N${Util.format(o.price)}`;
                                    o.discount = `N${Util.format(o.discount)}`;
                                    return o
                                })}
                                keys={['customer.name', 'product.name', 'price', 'discount', 'litres', 'amount']}
                                tableHead={['Customer', 'Product', 'Price', 'Disount', 'Litres', 'Amount']}
                                tableFoot={['', '', 'Total: N' + Util.format(state.prepaid.reduce((a, { amount }) => a + amount, 0))]}
                            />
                        </div>}


                        {!!state.postpaid.length && <div className='col-12 mb-5'>
                            <h6 className='text-primary mt-3'>POSTPAID CREDIT SALES</h6>
                            <Table
                                data={state.postpaid.slice().map(_o => {
                                    let o = { ..._o };
                                    o.amount = `N${Util.format(o.amount)}`;
                                    o.price = `N${Util.format(o.price)}`;
                                    o.discount = `N${Util.format(o.discount)}`;
                                    return o
                                })}
                                keys={['postpaidCustomer.name', 'price', 'discount', 'litres', 'amount']}
                                tableHead={['Customer', 'Price', 'Disount', 'Litres', 'Amount']}
                                tableFoot={['', '', '', '', 'Total: N' + Util.format(state.postpaid.reduce((a, { amount }) => a + amount, 0))]}
                            />
                        </div>}



                        {
                            [
                                { n: 'Expenses', o: state.expenses },
                                { n: 'Credits', o: state.credits },
                                // { n: 'Cashbook', o: state.cashbook },
                            ].filter(r => r.o.length).map(({ n, o: data }, i) => {
                                return <div key={i} className='col-12 mb-5'>
                                    <h6 className='text-primary'>{n.toUpperCase()}</h6>
                                    <Table
                                        data={data.map($ => {
                                            const o = { ...$ };
                                            o.amount = `N${Util.format(o.amount)}`;
                                            return o;
                                        })}
                                        keys={['subhead', 'amount']}
                                        tableHead={['Subhead', 'Amount']}
                                        tableFoot={['', 'Total: N' + Util.format(data.reduce((a, { amount }) => a + amount, 0))]}
                                    />
                                </div>
                            })
                        }


                        <div className='col-12 mb-5'>
                            <h6 className='text-primary'>CASHBOOK</h6>
                            <Table
                                data={state.cashbook.map((data, i) => {
                                    const o = { ...data };
                                    o.amount = `N${Util.format(o.amount)}`;
                                    return o;
                                })}
                                keys={['subhead', 'amount', 'type']}
                                tableHead={['Subhead', 'Amount', 'Type']}
                                tableFoot={['', 'Total: N' + Util.format(state.cashbook.reduce((a, { amount }) => a + amount, 0))]}
                            />
                        </div>


                        {!!state.slips.length && <div className='col-12 mb-5'>
                            <h6 className='text-primary mt-3'>POS SALES</h6>
                            <Table
                                data={state.slips.map(o => {
                                    const t = { ...o }
                                    t.amount = `N${Util.format(t.amount)}`;
                                    return t;
                                })}
                                tableHead={['User', 'Amount']}
                                tableFoot={['Total: N' + (Util.format(state.slips.reduce((s, o) => s + o.amount, 0)))]}
                                padRight
                                keys={['attendant', 'amount']}
                            />
                        </div>}
                    </div>
                </ScrollEndTrigger>
            }

        </CardLayout>
    );
}

export default Analyse;