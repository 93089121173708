import React, { useContext, useEffect, useRef, useState } from 'react';
import CardLayout from '../layouts/CardLayout';
import Util from '../Util';
import Busy from '../components/Busy';
import ScrollEndTrigger from '../components/ScrollEndTrigger';
import { ErrorStatusModal, SuccessStatusModal } from '../components/StatusModal';
import Table from '../components/Table';
import { useNavigate } from 'react-router-dom';
import Modal from '../components/Modal';


function Tellers(props) {
    const
        [state, setState] = useState({

            gettingTellers: false,
            gettingTellersDone: false,
            gettingTellersError: false,
            gettingTellersText: '',

            concludingTellers: false,
            concludingTellersDone: false,
            concludingTellersError: false,
            concludingTellersText: '',

            deletingTeller: false,
            deletingTellerDone: false,
            deletingTellerError: false,
            deletingTellerText: '',

            addingTellerImage: false,
            addingTellerImageDone: false,
            addingTellerImageError: false,
            addingTellerImageText: '',

            tellers: [],
        }),

        { currentOutlet, user: { position: pos } } = useContext(Util.UserContext),

        { current: tellerAction } = useRef({ $: 0 });

    useEffect(() => {
        Util.request.get(`gettellers/${currentOutlet}/pending`)
            .then(res => {
                setState(o => ({
                    ...o,
                    gettingTellers: false,
                    gettingTellersDone: true,
                    gettingTellersError: !res.status,
                    gettingTellersText: res.message,

                    tellers: res.status ? res.data : state.tellers
                }))
            })
        setState(o => ({ ...o, gettingTellers: true }));
    }, [tellerAction.$]);

    function handleTellerConfirm({ tellerId }, status) {
        let fd = new FormData, tellers = state.tellers;
        fd.set('status', +Boolean(status));

        Util.request.post(`concludeteller/${tellerId}`, fd)
            .then(res => {
                if (res.status) {
                    setState(o => ({
                        ...o,
                        tellers: state.tellers.filter(t => t.tellerId !== tellerId)
                    }));
                }

                setState(o => ({
                    ...o,
                    concludingTellers: false,
                    concludingTellersDone: true,
                    concludingTellersError: !res.status,
                    concludingTellersText: res.message,
                }))
            });

        setState(o => ({ ...o, concludingTellers: true }))
    }

    function handleDeleteTeller({ tellerId }) {
        Util.request.post(`deleteteller/${tellerId}`)
            .then(res => {
                setState(o => ({
                    ...o,
                    deletingTeller: false,
                    deletingTellerDone: true,
                    deletingTellerError: !res.status,
                    deletingTellerText: res.message,
                    tellers: res.status ? state.tellers.filter(t => t.tellerId !== tellerId) : state.tellers
                }));

            });

        setState(o => ({ ...o, deletingTeller: true }));
    }


    function handleAddTellerImage(e) {
        e.preventDefault();

        const fd = new FormData();

        if (e.target.files.length) {
            fd.set('image', e.target.files[0]);

            Util.request.post(`addtellerimage/${e.target.dataset.id}`, fd)
                .then(res => {
                    ++tellerAction.$;
                    setState(o => ({
                        ...o,
                        addingTellerImage: false,
                        addingTellerImageDone: true,
                        addingTellerImageError: !res.status,
                        addingTellerImageText: res.message,
                    }))
                })

            setState(o => ({ ...o, addingTellerImage: true }));
        }
    }

    return (
        <CardLayout
            pageName='All Pending Tellers' pageIcon='receipt'>

            <Busy busy={state.gettingTellers} busyText='Retrieving tellers...' />

            <Busy busy={state.concludingTellers} busyText='Confirming teller...' />

            <Busy busy={state.deletingTeller} busyText='Deleting teller...' />

            <Busy busy={state.addingTellerImage} busyText='Uploading image...' />

            <ErrorStatusModal
                show={state.gettingTellersDone && state.gettingTellersError}
                onDone={() => setState(o => ({ ...o, gettingTellersDone: false }))}
                text={state.gettingTellersText} />

            <SuccessStatusModal
                show={state.concludingTellersDone && !state.concludingTellersError}
                onDone={() => setState(o => ({ ...o, concludingTellersDone: false }))}
                text={state.concludingTellersText} />

            <SuccessStatusModal
                show={state.addingTellerImageDone && !state.addingTellerImageError}
                onDone={() => setState(o => ({ ...o, addingTellerImageDone: false }))}
                text={state.addingTellerImageText} />



            <ErrorStatusModal
                show={state.addingTellerImageDone && state.addingTellerImageError}
                onDone={() => setState(o => ({ ...o, addingTellerImageDone: false }))}
                text={state.addingTellerImageText} />



            <ErrorStatusModal
                show={state.deletingTellerDone && state.deletingTellerError}
                onDone={() => setState(o => ({ ...o, deletingTellerDone: false }))}
                text={state.deletingTellerText} />

            <SuccessStatusModal
                show={state.deletingTellerDone && !state.deletingTellerError}
                onDone={() => setState(o => ({ ...o, deletingTellerDone: false }))}
                text={state.deletingTellerText} />


            <ErrorStatusModal
                show={state.concludingTellersDone && state.concludingTellersError}
                onDone={() => setState(o => ({ ...o, concludingTellersDone: false }))}
                text={state.concludingTellersText} />


            <ScrollEndTrigger maxHeight='350px'>

                {
                    state.tellers.map((t, i) => <div key={i} style={{ position: 'absolute' }}>
                        <Modal
                            type='danger'
                            id={'teller-' + t.tellerId}
                            primary={{ icon: 'trash', text: 'Delete' }}
                            title='Delete teller?'
                            on={() => handleDeleteTeller(t)}
                        >
                            <p>Are you sure you want to delete this teller: <b>{t.comment}</b>?</p>
                        </Modal>

                        <Modal
                            // type='danger'
                            id={'approve-teller-' + t.tellerId}
                            primary={{ icon: 'check', text: 'Approve' }}
                            title='Delete teller?'
                            on={() => handleTellerConfirm(t, 1)}
                        >
                            <p>Are you sure you <b className='text-primary'>APPROVE</b> of this teller?<b>(N{Util.format(t.amount)})</b></p>
                            <p className='text-center'><small>Note: The attached image, if any, will be removed.</small></p>
                        </Modal>

                        <Modal
                            type='danger'
                            id={'decline-teller-' + t.tellerId}
                            primary={{ icon: 'times', text: 'Decline' }}
                            title='Delete teller?'
                            on={() => handleTellerConfirm(t, 0)}
                        >
                            <p>Are you sure you want to <b className='text-danger'>DECLINE</b> this teller: <b>(N{Util.format(t.amount)})</b>?</p>
                        </Modal>
                    </div>
                    )
                }



                <Table
                    noDataText={`No pending tellers at the moment...`}
                    data={state.tellers.map(o => {
                        const t = { ...o }
                        t.amount = `N${Util.format(t.amount)}`;
                        if (t.hasImage)
                            t.image = <a href={`${t.image}`} target={'_blank'} rel="noreferrer"><i className='fa fa-camera me-2' />View teller</a>;
                        else t.image = (
                            <label style={{ fontWeight: 500, cursor: 'pointer' }} className='text-primary form-label' htmlFor={'image-' + t.tellerId}>
                                <input accept='image/*' onChange={handleAddTellerImage} data-id={t.tellerId} type='file' id={'image-' + t.tellerId} style={{ width: '1px', height: '1px', position: 'absolute', opacity: '.00001' }} />
                                <i className='fa fa-upload'></i>
                                <small> Upload image</small>
                            </label>
                        )
                        return t;
                    })}
                    tableHead={['Date', 'Time', 'Comment', 'Amount', 'Image', 'Status']}
                    tableFoot={['', '', '', 'Total: N' + (Util.format(state.tellers.reduce((s, o) => s + o.amount, 0)))]}
                    keys={['date', 'time', 'comment', 'amount', 'image', 'statusText']}
                    actions={[
                        teller => ({ icon: 'times', text: 'Reject', attrs: { 'data-bs-toggle': 'modal', 'data-bs-target': '#decline-teller-' + teller.tellerId }, hideIf: (!pos.canConcludeTellers || teller.status !== null) }),
                        teller => ({ icon: 'check', text: 'Approve', attrs: { 'data-bs-toggle': 'modal', 'data-bs-target': '#approve-teller-' + teller.tellerId }, hideIf: (!pos.canConcludeTellers || teller.status !== null) }),
                        teller => ({
                            icon: 'trash',
                            text: 'Delete',
                            attrs: { 'data-bs-toggle': 'modal', 'data-bs-target': '#teller-' + teller.tellerId },
                            hideIf: !pos.canDeleteTellers
                        }),
                    ]}
                />
            </ScrollEndTrigger>
        </CardLayout>
    );
}

export default Tellers;
