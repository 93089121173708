import React, { useState, useEffect, useRef } from 'react';
import Busy from '../components/Busy';
import Button from '../components/Button';
import InputField from '../components/InputField';
import CardLayout from '../layouts/CardLayout';
import Util from '../Util';
import { ErrorStatusModal, SuccessStatusModal } from '../components/StatusModal';
import SelectInput from '../components/SelectInput';
import { useParams } from 'react-router-dom';



function CreateSubscription(props) {
    const
        [state, setState] = useState({
            gettingData: false,
            gettingDataDone: false,
            gettingDataMessage: '',
            gettingDataFailed: false,

            creatingSubscription: false,
            creatingSubscriptionDone: false,
            creatingSubscriptionMessage: '',
            creatingSubscriptionFailed: false,

            data: { products: [], customer: { balance: 0 } },

            errs: {}
        }),
        { customerId = 0 } = useParams(),
        { current: count } = useRef({ $: 0 });


    useEffect(() => {

        Util.request.get(`bundle/subs/create/${customerId}`)
            .then(res => {
                setState(o => ({
                    ...o,
                    gettingData: false,
                    gettingDataDone: true,
                    gettingDataFailed: !res.status,
                    gettingDataMessage: Util.resolveResponseMessage(res),
                    data: res.status ? res.data : state.data,
                    errs: Util.resolveFormError(res)
                }));
            });

        setState(o => ({ ...o, gettingData: true }));
    }, [count.$]);


    function handleCreateSubscription(e) {

        e.preventDefault();

        const fd = new FormData(e.target);
        fd.append('customer', customerId);

        Util.request.post(`subs/create/${customerId}`, fd)
            .then(res => {
                res.status && ++count.$;

                setState(o => ({
                    ...o,
                    creatingSubscription: false,
                    creatingSubscriptionDone: true,
                    creatingSubscriptionMessage: Util.resolveResponseMessage(res),
                    creatingSubscriptionFailed: !res.status,

                    errs: Util.resolveFormError(res)
                }))
            });

        setState(o => ({ ...o, creatingSubscription: true }));
    }


    return (
        <CardLayout
            cardElement='form'
            cardElementProps={{ onSubmit: handleCreateSubscription }}
            pageName="Create subscription"
            pageIcon="plus"
            cardFooter={<Button text='Create subscription' icon='plus' />}>

            <Busy busy={state.creatingSubscription} busyText='Creating subscription...' />

            <Busy busy={state.gettingData} />

            <SuccessStatusModal
                show={state.creatingSubscriptionDone && !state.creatingSubscriptionFailed}
                onDone={() => setState(o => ({ ...o, creatingSubscriptionDone: false }))}
                text={state.creatingSubscriptionMessage} />

            <ErrorStatusModal
                show={state.gettingDataDone && state.gettingDataFailed}
                onDone={() => setState(o => ({ ...o, gettingDataDone: false }))}
                text={state.gettingDataMessage} />
            {
                typeof state.creatingSubscriptionMessage === 'string' &&
                <ErrorStatusModal
                    show={state.creatingSubscriptionDone && state.creatingSubscriptionFailed}
                    onDone={() => setState(o => ({ ...o, creatingSubscriptionDone: false }))}
                    text={state.creatingSubscriptionMessage} />}


            <div className='row'>
                <p className="small text-muted m-0 fs-6">Current balance:</p>
                <div className="fs-3 text-primary m-0 mb-3" style={{ fontWeight: 300, lineHeight: '100%' }}>N{Util.format(state.data.customer.balance.toFixed(2))}</div>
                <hr />

                <div className='col-12 col-md-6 mb-4'>
                    <InputField name='_' attrs={{ readOnly: true, disabled: true }} labelText='Customer' value={state.data.customer.name} labelIcon='user' />
                </div>

                <div className='col-12 col-md-6 mb-4'>
                    <InputField name='_' attrs={{ readOnly: true, disabled: true }} labelText='Phone' value={state.data.customer.phone} labelIcon='phone' />
                </div>

                <div className='col-1mb-4'>
                    <InputField name='amount' errs={state.errs} labelText='Amount' type='number' attrs={{ min: 0, step: .01 }} labelIcon='money-bill' />
                </div>

                <div className='col-12 mt-4 mb-3'>
                    <div className="form-check">
                        <input className="form-check-input" name='existing_subscription' type="checkbox" id='existing' />
                        <label className="form-check-label" htmlFor="existing">
                            Existing subscription
                        </label>
                    </div>
                </div>
            </div>
        </CardLayout>
    );
}



export default CreateSubscription;