import React, { useContext, useEffect, useRef, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import Busy from '../components/Busy';
import Button from '../components/Button';
import ScrollEndTrigger from '../components/ScrollEndTrigger';
import Table from '../components/Table';
import Modal from '../components/Modal';
import CardLayout from '../layouts/CardLayout';
import Paths from '../Paths';
import Util from '../Util';
import ActionHeader from '../components/ActionHeader';


function PostpaidClearedHistory(props) {
    const
        [state, setState] = useState({
            gettingHistory: false,
            gettingHistoryDone: false,
            gettingHistoryMessage: '',
            gettingHistoryFailed: false,

            data: { history: [], customer: { name: '' } },

            clearingDebts: false,
            clearingDebtsDone: false,
            clearingDebtsMessage: '',
            clearingDebtsFailed: false,
        }),

        { customerId = 0 } = useParams(),

        navigate = useNavigate(),
        { user: { position } } = useContext(Util.UserContext);



    useEffect(() => {

        Util.request.get(`ppcustomer/cleared/${customerId}`)
            // -DMF
            .then(res => {
                setState(o => ({
                    gettingHistory: false,
                    gettingHistoryDone: true,
                    gettingHistoryFailed: !res.status,
                    gettingHistoryMessage: res.message,
                    data: res.status ? res.data : state.data
                }))
            })

        setState(o => ({ ...o, gettingHistory: true }));
    }, []);

    return (
        <CardLayout
            pageName="postpaid Customer cleared purchases"
            pageIcon="clock"
            cardHeader={<ActionHeader title={`${state.data.customer.name}(PAID)`} actions={[{
                text: 'Make payment',
                icon: 'money-bill',
                onClick: e => navigate(Paths.POSTPAID.join(Paths.POSTPAID_MAKE_PAYMENT.parse(customerId)).abs.$),
                showIf: position.canMakePurchase,
            }]} />

            }>

            <Busy busy={state.gettingHistory} busyText='Retrieving purchase history...' />


            <ScrollEndTrigger maxHeight='380px'>
                <Table
                    keys={['date', 'time', 'amount']}
                    tableHead={['Date', 'Time', 'Amount']}
                    data={state.data.history.map(_h => {
                        let h = { ..._h };

                        h.price = `N${Util.format(h.price)}`;
                        h.litres = Util.format(h.litres);
                        h.amount = `N${Util.format(h.amount)}`;
                        h.balance = `N${Util.format(h.balance)}`;

                        return h;
                    })}
                    padRight={true}
                    tableFoot={[`Total: N${Util.format(state.data.history.reduce((acc, o) => acc + o.amount, 0))}`]}
                />
            </ScrollEndTrigger>
        </CardLayout>
    );
}


export default PostpaidClearedHistory;