import React, { useContext, useEffect, useRef, useState } from 'react';
import Busy from '../components/Busy';
import ScrollEndTrigger from '../components/ScrollEndTrigger';
import Table from '../components/Table';
import CardLayout from '../layouts/CardLayout';
import Util from '../Util';
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import Button from '../components/Button';
import Paths from '../Paths';
import { ErrorStatusModal, SuccessStatusModal } from '../components/StatusModal';

function PumpRollover(props) {
    const
        [state, setState] = useState({
            records: [],
            gettingPumpsDone: false,
            gettingPumps: false,
            gettingPumpsFailed: false,
            gettingPumpsMessage: '',


            rollingOverDone: false,
            rollingOver: false,
            rollingOverFailed: false,
            rollingOverMessage: ''
        }),
        navigate = useNavigate(),
        dep = useRef(0),
        { currentOutlet: outletId } = useContext(Util.UserContext);

    useEffect(() => {
        Util.request.get(`getpumps/${outletId}`)
            .then(res => {

                if (res.status)
                    for (const pump of res.data)
                        pump.input = <input name='pump' className='form-check-input' type='checkbox' checked />

                setState(o => ({
                    ...o,
                    records: res.status ? res.data : [],
                    gettingPumpsDone: true,
                    gettingPumps: false,
                    gettingPumpsFailed: !res.status,
                    gettingPumpsMessage: res.message
                }));
            });
        setState(o => ({ ...o, gettingPumps: true }));
    }, [dep.current]);

    function handleReset(e) {
        e.preventDefault();

        Util.request.post(`readingsrollover/${outletId}`, new FormData(e.target))
            .then(res => {

                res.status && ++dep.current;

                setState(o => ({
                    ...o,
                    rollingOver: false,
                    rollingOverDone: true,
                    rollingOverFailed: !res.status,
                    rollingOverMessage: res.message
                }))
            });

        setState(o => ({ ...o, rollingOver: true }));
    }


    return (
        <CardLayout
            pageName='Reading rollover'
            pageIcon='turn-down'
            cardFooter={<Button icon='turn-down' text='Rollover' />}
            cardElement='form'
            cardElementProps={{ onSubmit: handleReset }}
        >
            <Busy busyText='Retrieving pumps...' busy={state.gettingPumps} />

            <Busy busyText='Rolling over...' busy={state.rollingOver} />



            <ErrorStatusModal
                show={state.gettingPumpsDone && state.gettingPumpsFailed}
                onDone={() => setState(o => ({ ...o, gettingPumpsDone: false }))}
                text={state.gettingPumpsMessage} />



            <SuccessStatusModal
                show={state.rollingOverDone && !state.rollingOverFailed}
                onDone={() => setState(o => ({ ...o, rollingOverDone: false }))}
                text={state.rollingOverMessage} />



            <ErrorStatusModal
                show={state.rollingOverDone && state.rollingOverFailed}
                onDone={() => setState(o => ({ ...o, rollingOverDone: false }))}
                text={state.rollingOverMessage} />



            <ScrollEndTrigger>
                <Table
                    tableHead={['Unique name', 'Last reading', 'Date of reading', 'Rollover']}
                    keys={['uniqueName', 'lastMeterReading', 'dateOfReading', 'input']}
                    data={state.records}
                />

            </ScrollEndTrigger>
        </CardLayout>
    )
}

export default PumpRollover;