import React, { useContext, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import Busy from '../components/Busy';
import ScrollEndTrigger from '../components/ScrollEndTrigger';
import { SuccessStatusModal } from '../components/StatusModal';
import Table from '../components/Table';
import CardLayout from '../layouts/CardLayout';
import Paths from '../Paths';
import Util from '../Util';


function SelectOutlet(props) {
    const
        [state, setState] = useState({
            gettingOutlets: false,
            gettingOutletsFailed: false,
            gettingOutletsText: '',
            outlets: [],

            selectingOutlet: false,
            selectingOutletDone: false,

            reloadingApp: false
        }),

        modalDuration = 1000;

    let ctx = useContext(Util.UserContext);


    function handleOutletSelected(outlet) {
        setTimeout(() => {
            ctx.setCurrentOutlet(outlet);

            setState(o => ({
                ...o,
                selectingOutlet: false,
                selectingOutletDone: true,
                reloadingApp: true
            }));
            setTimeout(() => {
                window.location.reload();
            }, modalDuration);
        }, 1000);

        setState(o => ({ ...o, selectingOutlet: true }));
    }

    useEffect(() => {
        Util.request.get('select_outlets')
            .then(res => {
                setState(o => ({
                    ...o,
                    gettingOutlets: false,
                    gettingOutletsFailed: !res.status,
                    gettingOutletsText: res.message,
                    outlets: res.status ? res.data : false,
                }));
            });

        setState(o => ({ ...o, gettingOutlets: true }))
    }, []);


    return (
        <CardLayout
            pageName="Outlets"
            pageIcon="house">


            <Busy busy={state.gettingOutlets} fixed busyText='Fetching outlets...' />

            <Busy busy={state.selectingOutlet} busyText='Selecting outlets...' />

            <Busy busy={state.reloadingApp} busyText='Reloading app...' />


            <SuccessStatusModal
                onDone={() => setState(o => ({ ...o, selectingOutletDone: false }))}
                show={state.selectingOutletDone && !state.selectingOutlet} text={'Outlet selected'}
                duration={modalDuration}
            />


            <ScrollEndTrigger maxHeight='380px'>
                <table className='table table-striped table-hover'>
                    <thead className='fw-bold text-primary'>
                        <tr>
                            <th></th>
                            <th>Outlet name</th>
                        </tr>
                    </thead>
                    <tbody>
                        {state.outlets.map((o, i) => <tr style={{ cursor: 'pointer' }} key={i} onClick={e => handleOutletSelected(o)}>
                            <th>{i + 1}</th>
                            <td>{o.outletName}</td>
                        </tr>)}
                    </tbody>
                </table>
            </ScrollEndTrigger>
        </CardLayout>
    );
}


export default SelectOutlet;