import React from 'react';
import '../App.css';
import { useLocation } from 'react-router-dom';

function Error404(props) {
    const { pathname } = useLocation();

    
    return (
        <div className='d-flex flex-column align-items-center justify-content-center' style={{ position: 'fixed', left: 0, top: 0, width: '100vw', height: '100vh' }}>
            <small className='small text-success m-0 fw-bold'>Error</small>
            <h1 className='fw-bold text-success m-0' style={{ fontSize: '30vmin', lineHeight: '100%' }}>404</h1>
            <div className='text-muted text-center'>
                <p>
                    The location <b className='text-success'>{pathname}</b>  is not found!
                </p>
                <p>It may have been moved, dropped, or spelt incorrectly.</p>
            </div>

        </div>
    );
}

export default Error404;