import React, { useContext, useEffect, useState } from 'react';
import CardLayout from '../layouts/CardLayout';
import Util from '../Util';
import Busy from '../components/Busy';
import ScrollEndTrigger from '../components/ScrollEndTrigger';
import { ErrorStatusModal, SuccessStatusModal } from '../components/StatusModal';
import InputField from '../components/InputField';


function CreateUser(props) {
    const
        [state, setState] = useState({
            creatingUserFailed: false,
            creatingUserDone: false,
            creatingUserText: '',
            creatingUser: false,

            gettingPositions: false,
            gettingPositionsDone: false,
            gettingPositionsText: '',
            gettingPositionsError: false,
            positions: null,

            gettingOutlets: false,
            gettingOutletsDone: false,
            gettingOutletsText: '',
            gettingOutletsError: false,
            outlets: null,

            selectedPositionCanBeStaff: false
        }),
        [formErrors, setFormErrors] = useState({}),
        context = useContext(Util.UserContext);


    async function handlePositionSelectChange(e) {
        e.preventDefault();

        let 
            position = state.positions.find(pos => pos.id === Number(e.target.value)), 
            outlets = state.outlets;

        if (!position) return;

        if (!state.outlets) {
            setState(o => ({ ...o, gettingOutlets: true }));
            outlets = await Util.request.get('outlets');

            if (outlets.status) outlets = outlets.data;
            else {
                setState(o => ({
                    ...o,
                    gettingOutlets: false,
                    gettingOutletsDone: true,
                    gettingOutletsError: true,
                    gettingOutletsText: outlets.message,
                    outlets: null
                }));
                return
            }
        }

        setState(o => ({
            ...o,
            selectedPositionCanBeStaff: position.canBeStaff,
            gettingOutlets: false,
            gettingOutletsDone: false,
            outlets: outlets
        }))
    }

    useEffect(() => {

        if (state.positions !== null) return;

        Util.request.get('pos')
            .then(res => {
                const filteredPositions = Util.filterPositions(context.user.position.level, res.data);
                setState(o => ({
                    ...o,
                    gettingPositions: false,
                    positions: res.status ? filteredPositions : false,
                    gettingPositionsDone: true,
                    gettingPositionsText: res.message,
                    gettingPositionsError: !res.status
                }));
            })

        setState(o => ({ ...o, gettingPositions: true }));
    }, []);


    const handleSubmit = (e) => {
        e.preventDefault();
        Util.request.post('user/create', new FormData(e.target))
            .then(res => {
                if (res.status) {
                    setState(o => ({ ...o, creatingUserFailed: false, creatingUserDone: true, creatingUserText: res.message, creatingUser: false }));
                    setFormErrors({});
                }
                else {
                    setState(o => ({
                        ...o,
                        creatingUserFailed: true,
                        creatingUserDone: true,
                        creatingUserText: typeof res.message === 'string' ? res.message : 'Failed', creatingUser: false
                    }));
                    if (typeof res.message !== 'string')
                        setFormErrors(res.message);
                }
            });

        setState(o => ({
            ...o,
            creatingUserFailed: false,
            creatingUserDone: false,
            creatingUserText: '',
            creatingUser: true
        }));
    };

    const actionButton = <button type='submit' className='btn btn-primary'>
        <i className='fa fa-send me-2'></i>
        <span>Create user</span>
    </button>;

    return (
        <CardLayout cardElement='form' cardElementProps={{ method: 'post', onSubmit: handleSubmit }}
            pageName='Create user' pageIcon='user-plus' cardFooter={actionButton}>



            <Busy busy={state.creatingUser} busyText='Creating user...' />



            <Busy busy={state.gettingOutlets} busyText='Retrieving outlets...' />



            <Busy busy={state.gettingPositions} busyText='Retrieving user positions...' />



            <SuccessStatusModal
                show={state.creatingUserDone && !state.creatingUserFailed}
                onDone={() => setState(o => ({ ...o, creatingUserDone: false }))}
                text={state.creatingUserText} />



            <ErrorStatusModal
                show={state.gettingOutletsDone && state.gettingOutletsError}
                onDone={() => setState(o => ({ ...o, gettingOutletsDone: false }))}
                text={state.gettingOutletsText} />



            <ErrorStatusModal
                show={state.gettingPositionsDone && state.gettingPositionsError}
                onDone={() => setState(o => ({ ...o, gettingPositionsDone: false }))}
                text={state.gettingPositionsText} />




            <ScrollEndTrigger maxHeight='390px'>
                {
                    Array.isArray(state.positions) && <>
                        <div className='col-12'>
                            <div className='mb-3'>
                                <label className='form-label' htmlFor='position'>Position</label>
                                <select className='form-select' defaultValue='' name='position' onChange={handlePositionSelectChange}>
                                    <option disabled value=''>Select user position</option>
                                    {state.positions.map(pos => <option key={pos.id} value={pos.id}>{pos.name}</option>)}
                                </select>
                            </div>
                        </div>

                        {
                            state.selectedPositionCanBeStaff && <div className='col-12'>
                                <div className='mb-3'>
                                    <label className='form-label' htmlFor='outlet'>Select outlet</label>
                                    <select className='form-select' name='outlet'>
                                        {state.outlets.map(outlet => <option key={outlet.outletId} value={outlet.outletId}>{outlet.outletName}</option>)}
                                    </select>
                                </div>
                            </div>
                        }

                        <div className='row'>


                            <div className='col-md-6'>
                                <div className='mb-3'>
                                    <label className='form-label' htmlFor='first_name'>First name</label>
                                    <input type='text' className={`form-control ${(formErrors.first_name ? 'error' : '')}`} id='first_name' name='first_name' />
                                    {formErrors.first_name && formErrors.first_name.map((e, i) => <span key={i}>{e.message}</span>)}
                                </div>
                            </div>


                            <div className='col-md-6'>
                                <div className='mb-3'>
                                    <label className='form-label' htmlFor='last_name'>Last name</label>
                                    <input type='text' className={`form-control ${(formErrors.last_name ? 'error' : '')}`} id='last_name' name='last_name' />
                                    {formErrors.last_name && formErrors.last_name.map((e, i) => <span key={i}>{e.message}</span>)}
                                </div>
                            </div>

                            {/* ------------------------------------------------------------------------------------------- */}



                            <div className='col-md-6'>
                                <div className='mb-3'>
                                    <InputField name='date_of_birth' labelText='Date of birth' attrs={{ type: 'date' }} errs={formErrors} />
                                </div>
                            </div>


                            <div className='col-md-6'>
                                <div className='mb-3'>
                                    <label className='form-label' htmlFor='gender'>Gender</label>
                                    <select name='gender' id='gender' className='form-select'>
                                        <option value='Male'>Male</option>
                                        <option value='Female'>Female</option>
                                    </select>
                                </div>
                            </div>




                            <div className='col-md-6'>
                                <div className='mb-3'>
                                    <InputField name='state_of_origin' labelText='State of origin' errs={formErrors} />
                                </div>
                            </div>





                            <div className='col-md-6'>
                                <div className='mb-3'>
                                    <InputField name='lga_of_origin' labelText='LGA of origin' errs={formErrors} />
                                </div>
                            </div>




                            <div className='col-md-6'>
                                <div className='mb-3'>
                                    <InputField name='phone' labelText='Phone' errs={formErrors} />
                                </div>
                            </div>




                            <div className='col-md-6'>
                                <div className='mb-3'>
                                    <InputField name='email' labelText='Email' attrs={{ type: 'email' }} errs={formErrors} />
                                </div>
                            </div>




                            <div className='col-md-12'>
                                <div className='mb-3'>
                                    <InputField name='residential_addr' labelText='Residential Address' errs={formErrors} />
                                </div>
                            </div>


                            <div className='col-12 mb-3'>
                                <div className='d-flex align-items-center'>
                                    <hr className='flex-grow-1' />
                                    <p className='m-0 mx-1 text-muted'>Login</p>
                                    <hr className='flex-grow-1' />
                                </div>
                            </div>






                            <div className='col-12'>
                                <div className='mb-3'>
                                    <InputField name='username' labelText='Username' errs={formErrors} />
                                </div>
                            </div>




                            <div className='col-md-6'>
                                <div className='mb-3'>
                                    <InputField name='password' labelText='Password' attrs={{ type: 'password' }} errs={formErrors} />
                                </div>
                            </div>




                            <div className='col-md-6'>
                                <div className='mb-3'>
                                    <InputField name='confirm_password' labelText='Confirm password' attrs={{ type: 'password' }} errs={formErrors} />
                                </div>
                            </div>




                            {/* ------------------------------------------------------------------------------------------- */}














                        </div></>
                }
            </ScrollEndTrigger>


        </CardLayout>
    );
}

export default CreateUser;