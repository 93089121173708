import React, { useContext, useEffect, useRef, useState } from 'react';
import Busy from '../components/Busy';
import ScrollEndTrigger from '../components/ScrollEndTrigger';
import Table from '../components/Table';
import CardLayout from '../layouts/CardLayout';
import Util from '../Util';
import { useNavigate, useParams } from "react-router-dom";
import Button from '../components/Button';
import Paths from '../Paths';
import { ErrorStatusModal, SuccessStatusModal } from '../components/StatusModal';
import ActionHeader from '../components/ActionHeader';


// All the cashbooks by dates
function TotalizerOffsets(props) {

    const
        date = new Date(),
        [state, setState] = useState(
            {
                gettingTotalizerOffsetsDone: false,
                gettingTotalizerOffsets: false,
                gettingTotalizerOffsetsError: false,
                gettingTotalizerOffsetsText: '',

                data: null,

                checkingTotalizerOffsetsDone: false,
                checkingTotalizerOffsets: false,
                checkingTotalizerOffsetsFailed: false,
                checkingTotalizerOffsetsMessage: '',
            }),
        { pumpId = -1 } = useParams(),

        { user: { position: pos } } = useContext(Util.UserContext),

        navigate = useNavigate();



    useEffect(() => {
        Util.request.get(`totalizerhistory/${pumpId}`)
            .then(res => {
                setState(o => ({
                    ...o,
                    gettingTotalizerOffsets: false,
                    gettingTotalizerOffsetsDone: true,
                    gettingTotalizerOffsetsText: res.message,
                    gettingTotalizerOffsetsError: !res.status,

                    data: res.data
                }));
            });
        setState(o => ({ ...o, gettingTotalizerOffsets: true }));
    }, []);

    function handleCheck(d) {
        //checkreset/<int:id>

        Util.request.post(`checkreset/${d.historyId}`)
            .then(res => {
                if (res.status) d.checked = true;

                setState(o => ({
                    ...o,
                    checkingTotalizerOffsets: false,
                    checkingTotalizerOffsetsDone: true,
                    checkingTotalizerOffsetsFailed: !res.status,
                    checkingTotalizerOffsetsMessage: res.message,
                }));
            });

        setState(o => ({ ...o, checkingTotalizerOffsets: true }));
    }


    return (
        <CardLayout
            pageName='Pump resets'
            pageIcon='refresh'
            cardHeader={<ActionHeader actions={[{
                showIf: pos.canOffsetPumpTotalizer,
                text: 'Reset reading',
                icon: 'refresh',
                onClick: () => navigate(
                    Paths.DAILY_SALES.join(Paths.RESET_TOTALIZER.parse(pumpId)).abs.$
                )
            }]} title={state.data ? `Meter resets for: ${state.data.pump.uniqueName}` : "No pump"} />}
        >
            <Busy busy={state.gettingTotalizerOffsets} fixed text={`Retrieving records...`} />

            <Busy busy={state.checkingTotalizerOffsets} fixed text={`Please wait...`} />

            <ErrorStatusModal
                show={state.gettingTotalizerOffsetsDone && state.gettingTotalizerOffsetsError}
                onDone={() => setState(o => ({ ...o, gettingTotalizerOffsetsDone: false }))}
                text={state.gettingTotalizerOffsetsText} />

            <SuccessStatusModal
                show={state.checkingTotalizerOffsetsDone && !state.checkingTotalizerOffsetsFailed}
                onDone={() => setState(o => ({ ...o, checkingTotalizerOffsetsDone: false }))}
                text={state.checkingTotalizerOffsetsMessage} />

            <ErrorStatusModal
                show={state.checkingTotalizerOffsetsDone && state.checkingTotalizerOffsetsFailed}
                onDone={() => setState(o => ({ ...o, checkingTotalizerOffsetsDone: false }))}
                text={state.checkingTotalizerOffsetsMessage} />

            <ScrollEndTrigger maxHeight='430px'>
                <Table
                    tableHead={['Date', 'New reading', 'Image', 'Checked']}
                    data={state.data ? (state.data.records.map(r => {
                        r.image = <a href={r.image} target='_blank' className='btn btn-sm fw-bold text-primary'>
                            <i className='fa fa-image'></i> Reading image
                        </a>;
                        r.checked = r.checked && <i className='fa fa-check'></i>
                        return r
                    }) || []) : []}
                    keys={['date', 'meterReading', 'image', 'checked']}
                    actions={pos.canCheckTotalizerOffsets ?
                        (data => ({ icon: 'check', text: 'Check', hideIf: !!data.checked, on: handleCheck })) : []}
                />
            </ScrollEndTrigger>
        </CardLayout>
    )
}

export default TotalizerOffsets;