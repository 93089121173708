import { useState, useMemo } from 'react';
import { ErrorStatusModal, SuccessStatusModal } from '../components/StatusModal';
import Busy from '../components/Busy';


export default function useQueryState(initialData) {
    const [state, setState] = useState({
        data: initialData,
        busy: false,
        done: false,
        failed: false,
        message: undefined,
        busyMessage: undefined,
    });

    function handleClear() {
        setState(o => ({ ...o, busy: false, done: false, failed: false, message: undefined, busyMessage: undefined }))
    }

    const o = useMemo(() => (({
        state,
        data: state.data,
        set({ data, busy = false, done = true, failed = false, message = '', busyMessage = undefined, } = {}) {
            setState({ data, busy, done, failed, message, busyMessage, })
        },
        fromResponse(res) {
            res.status ? this.success(res.message, res.data) : this.failed(res.message)
        },
        busy(busyMessage) {
            this.set({ busy: true, busyMessage, done: false, failed: false, message: undefined, data: state.data });
        },
        failed(message, data) {
            this.set({ busy: false, failed: true, message, data, done: true, data: state.data, });
        },
        success(message, data) {
            this.set({ busy: false, failed: false, data, done: true, message });
        },
        get doneWithSuccess() {
            return state.done && !state.failed;
        },
        get doneWithError() {
            return state.done && state.failed;
        },
        clear() {
            handleClear();
        },
        ModalError() {
            return <ErrorStatusModal text={state.message} show={state.done && state.failed} onDone={handleClear} />
        },
        ModalSuccess() {
            return <SuccessStatusModal text={state.message} show={state.done && !state.failed} onDone={handleClear} />
        },
        Modal() {
            return <>
                <ErrorStatusModal text={state.message} show={state.done && state.failed} onDone={handleClear} />
                <SuccessStatusModal text={state.message} show={state.done && !state.failed} onDone={handleClear} />
            </>
        },
        Busy() {
            return <Busy busy={state.busy} busyText={state.busyMessage} />
        }
    })), [state]);

    return o;
}


