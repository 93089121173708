import React, { useEffect, useState } from 'react';
import CardLayout from '../layouts/CardLayout';
import Busy from '../components/Busy';
import Util from '../Util';
import Header from '../layouts/Header';
import { useNavigate, useSearchParams } from 'react-router-dom';
import Paths from '../Paths';
import { SuccessStatusModal, ErrorStatusModal } from '../components/StatusModal';
import InputField from '../components/InputField';

function Login(props) {
    const
        navigate = useNavigate(),
        [searchParams, setSearchParams] = useSearchParams(),
        [state, setState] = useState({
            seePassword: false,
            errors: {}, 
            loggingIn: false, 
            loggingInDone: false, 
            loggingInMessage: '', 
            loggingInFailed: false
        });

    useEffect(() => {
        if (Number(searchParams.get('logout')) === 1) {
            setTimeout(() => {
                Util.request.post('logout').then(res => {
                    setState({ loggingIn: false, loggingInDone: true, loggingInMessage: res.message, loggingInFailed: !res.status });
                    if(res.status) Util.clearUserObject();
                });
            }, 700);
            searchParams.delete('logout');
            setSearchParams(searchParams);
            setState({ loggingIn: true, loggingInDone: false, loggingInMessage: '', loggingInFailed: false });
        }
    }, []);

    const handleSubmit = (e) => {
        e.preventDefault();

        Util.request.post('login', new FormData(e.target))
            .then(response => {

                if (response.status) {
                    let data = { ...response.data.user, supplyTypes: response.data.supplyTypes, productTypes: response.data.productTypes };

                    if (response.data.user.position.canManageOutlet) {
                        data = { ...data, ...response.data.outlet }
                    }

                    Util.setUserObject(data);

                    setTimeout(() => {
                        navigate(Paths.DASHBOARD.$);
                    }, 1000);

                }
                setState(o => ({
                    ...o,
                    loggingInDone: true,
                    loggingInMessage: typeof response.message === 'string'? response.message : 'Login failed!',
                    loggingInFailed: ! response.status,
                    errors: typeof response.message === 'string' ? {} : response.message
                }));
            });

        setState(o => ({ ...o, loggingIn: true }));
    };



    return (<main className='d-flex h-100 fg-color bg-color' data-theme='light'>

        <section className='flex-grow-1 d-flex flex-column'>

            <Header outletName='Application name' authorized={false} authenticated={false} />

            <div className='flex-grow-1 container'>

                <CardLayout pageName='Login' pageIcon='lock'>

                    <Busy busy={state.loggingIn} busyText='Please wait...' />

                    <SuccessStatusModal onDone={() => setState(o => ({ ...o, loggingInDone: false }))} text={state.loggingInMessage} show={state.loggingInDone} />

                    <ErrorStatusModal
                        onDone={() => setState(o => ({ ...o, loggingInDone: false, loggingIn: false }))}
                        text={state.loggingInMessage}
                        show={state.loggingInDone && state.loggingInFailed}
                        duration={1500} />

                    <form onSubmit={handleSubmit} method='post'>
                        <div className='mb-4'>
                            <InputField name='username' labelText='Username' labelIcon='user' errs={state.errors} />
                        </div>



                        <div className='mb-4'>

                            <InputField type={state.seePassword ? 'textt' : 'password'} name='password' labelText='Password' labelIcon='key' errs={state.errors} />
                            <div className='text-end mt-1'>
                                <button className='btn btn-sm btn-outline-secondary' type={'button'} onClick={() => setState(o => ({ ...o, seePassword: !state.seePassword }))}>
                                    <i className={`fa fa-eye${state.seePassword ? '-slash' : ''}`}></i>
                                </button>
                            </div>
                        </div>

                        <button className='btn btn-primary'>
                            <i className='fa fa-lock-open me-2'></i>
                            Login
                        </button>
                    </form>
                </CardLayout>

            </div>

        </section>
    </main>);
}

export default Login;