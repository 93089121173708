import React, { useContext, useEffect, useRef, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import Busy from '../components/Busy';
import Button from '../components/Button';
import ScrollEndTrigger from '../components/ScrollEndTrigger';
import { ErrorStatusModal, SuccessStatusModal } from '../components/StatusModal';
import Table from '../components/Table';
import CardLayout from '../layouts/CardLayout';
import Paths from '../Paths';
import Util from '../Util';
import Modal from "../components/Modal";
import { useQueryState } from '../hooks';
import ActionHeader from '../components/ActionHeader';

function SupplyHistory(props) {

    const
        [state, setState] = useState({
            entries: null,

            gettingSupplies: false,
            gettingSuppliesDone: false,
            gettingSuppliesText: '',
            gettingSuppliesError: false,

            openingSupply: false,
            openingSupplyDone: false,
            openingSupplyMessage: false,
            openingSupplyFailed: false,

            closingSupply: false,
            closingSupplyDone: false,
            closingSupplyMessage: '',
            closingSupplyFailed: false,


        }),
        deleter = useQueryState(),
        deleteRef = useRef(0),

        { tankId = 0 } = useParams(),

        { user: { position } } = useContext(Util.UserContext),

        navigate = useNavigate(),

        clearTankCheckRef = useRef({});

    useEffect(() => {
        if (state.entries !== null) return;

        Util.request.get(`supply/${tankId}`)
            .then(res => setState(o => ({
                entries: res.status ? res.data : [],
                gettingSupplies: false,
                gettingSuppliesDone: true,
                gettingSuppliesText: res.message,
                gettingSuppliesError: !res.status
            })));


        setState(o => ({ ...o, gettingSupplies: true }))
    }, [deleteRef.current]);


    function handleOpenSupply(e) {
        e.preventDefault();

        Util.request.post(`supply/create/${tankId}`)
            .then(res => setState(o => ({
                ...o,
                openingSupply: false,
                openingSupplyDone: true,
                openingSupplyFailed: !res.status,
                openingSupplyMessage: res.message,
                entries: res.status ? [res.data, ...state.entries] : state.entries
            })));

        setState(o => ({ ...o, openingSupply: true }));
    }

    function handleDeleteSupply(data) {

        Util.request.post(`supply/delete/${data.id}`)
            .then(res => {
                ++deleteRef.current;
                deleter.fromResponse(res);
            })
        deleter.busy("Deleting supply...")
    }


    function handleCloseSupply(data) {
        const fd = new FormData();
        fd.append('clear_tank', !!clearTankCheckRef.current[data.id]);

        Util.request.post(`supply/close/${tankId}`, fd)
            .then(res => {
                let entries = state.entries, supply;

                if (res.status) {
                    supply = entries.find(supply => supply.id === data.id);
                    if (supply) {
                        supply.is_open = false;
                        supply.closeDate = res.data.closeDate;
                    }
                }

                setState(o => ({
                    ...o,
                    closingSupply: false,
                    closingSupplyDone: true,
                    closingSupplyFailed: !res.status,
                    closingSupplyMessage: res.message,
                    entries: entries
                }));
            });

        setState(o => ({ ...o, closingSupply: true }));
    }

    return (
        <CardLayout
            key={!!state.entries}
            pageName='Supply history'
            pageIcon='clock'
            cardHeader={<ActionHeader actions={[{
                showIf: state.entries && position.canEnterSupplies && state.entries.some(supply => supply.is_open) === false,
                onClick: handleOpenSupply,
                text: 'Open supply for this tank',
                icon: 'plus',
            }]} title={state.entries && state.entries.length ? `Supply history for ${state.entries[0].tank.tankName}` : 'No supply history for specified tank'} />}
        >

            <deleter.Busy />
            <deleter.Modal />


            {/* GETTING SUPPLY */}
            <Busy busy={state.gettingSupplies} fixed={true} busyText='Retrieving supply history...' />
            <Busy busy={state.openingSupply} fixed={true} busyText='Opening supply...' />
            <Busy busy={state.closingSupply} fixed={true} busyText='Closing supply...' />

            <ErrorStatusModal
                onDone={() => setState(o => ({ ...o, gettingSuppliesDone: false }))}
                show={state.gettingSuppliesDone && state.gettingSuppliesError} text={state.gettingSuppliesText}
            />

            <ErrorStatusModal
                onDone={() => setState(o => ({ ...o, closingSupplyDone: false }))}
                show={state.closingSupply && state.closingSupplyFailed} text={state.closingSupplyMessage}
            />

            <ErrorStatusModal
                onDone={() => setState(o => ({ ...o, openingSupplyDone: false }))}
                show={state.openingSupply && state.openingSupplyFailed} text={state.openingSupplyMessage}
            />

            <SuccessStatusModal
                onDone={() => setState(o => ({ ...o, closingSupplyDone: false }))}
                show={state.closingSupply && !state.closingSupplyFailed} text={state.closingSupplyMessage}
            />

            <SuccessStatusModal
                onDone={() => setState(o => ({ ...o, openingSupplyDone: false }))}
                show={state.openingSupply && !state.openingSupplyFailed} text={state.openingSupplyMessage}
            />
            {/* END OF GETTING SUPPLY */}


            {state.entries && state.entries.map(o => <div key={o.id}>

                <Modal
                    id={`supply-modal-${o.id}`}
                    type='warning'
                    title='Close supply?'
                    primary={{ text: 'Close supply' }}
                    on={() => handleCloseSupply(o)}
                >
                    <p>Are you sure you want to close this supply?</p>

                    <small><b>PS:</b> Ensure you have added pump sales to all open supply
                        before closing this one as it is
                        necessary to guarantee accurate supply balance. <br />
                        Go to <i className='text-primary'>Sales &gt;  Tanks  &gt; Input pump sales</i>.</small>

                </Modal>

                <Modal
                    id={`supply-delete-modal-${o.id}`}
                    type='danger'
                    title='Delete supply?'
                    primary={{ text: 'Delete' }}
                    on={() => handleDeleteSupply(o)}
                >
                    <p>Are you sure you want to DELETE this supply?</p>

                    <small><b>PS:</b> This operation will fail if any entries have been made into this supply.</small>

                </Modal>

            </div>)}


            {state.entries && <ScrollEndTrigger maxHeight={'60vh'}>
                <Table
                    data={state.entries.map(o => {
                        o.$excess = o.excess.toFixed(2)
                        return o;
                    })}
                    tableHead={['Date', 'Time', 'Date closed', "Last balance", 'Excess', 'Shortage']}
                    keys={['date', 'time', 'closeDate', 'lastBalance', '$excess', 'shortage']}
                    actions={[
                        { icon: 'eye', text: 'View', on: supply => navigate(Paths.SUPPLY.parse(supply.tank.tankId).join(Paths.SUPPLY_ENTRIES.parse(supply.id)).abs.$) },
                        data => ({ icon: 'trash', text: 'Delete', attrs: { 'data-bs-toggle': 'modal', 'data-bs-target': `#supply-delete-modal-${data.id}` }, hideIf: false }),
                        data => ({ icon: 'times', text: 'Close', attrs: { 'data-bs-toggle': 'modal', 'data-bs-target': `#supply-modal-${data.id}` }, hideIf: !data.is_open, text: 'Close' }),
                    ]}
                />
            </ScrollEndTrigger>}

        </CardLayout>
    );
}

export default SupplyHistory;

// ['Date','Product', "Driver's name", "Vehicle's number", 'Quantity in', 'Quantity out', 'Receiver', 'Balance']