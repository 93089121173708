import React, { useEffect, useState } from 'react';
import CardLayout from '../layouts/CardLayout';
import Util from '../Util';
import Busy from '../components/Busy';
import ScrollEndTrigger from '../components/ScrollEndTrigger';
import { SuccessStatusModal } from '../components/StatusModal';
import InputField from '../components/InputField';


function CreateOutlet(props) {
    const
        [state, setState] = useState({
            creatingOutletFailed: false,
            creatingOutletDone: false,
            creatingOutletText: '',
            creatingOutlet: false,

            managers: null,
            gettingManagers: false,
            gettingManagersText: '',
            gettingManagersFailed: false,
            gettingManagersDone: false

        }),
        [errors, setErrors] = useState({});


    useEffect(() => {
        if (state.managers !== null) return;

        Util.request.get('staff/0/can_manage_outlet')
            .then(res => {
                setState(o => ({

                    ...o,
                    gettingManagers: false,
                    gettingManagersText: res.message,
                    gettingManagersFailed: !res.status,
                    gettingManagersDone: true,
                    managers: res.status ? res.data : []
                }))
            })

        setState(o => ({ ...o, gettingManagers: true }))
    }, [])



    const handleSubmit = (e) => {
        e.preventDefault();

        const
            fd = new FormData(e.target),
            files = e.target.querySelector('[name=outlet_image]').files;

        if (files.length) fd.append('outlet_image', files[0]);

        Util.request.post('createoutlet', fd)
            .then(res => {
                if (res.status) {
                    setState(o => ({ ...o, creatingOutletFailed: false, creatingOutletDone: true, creatingOutletText: res.message, creatingOutlet: false }));
                    setErrors({});
                }
                else {
                    setState(o => ({ ...o, creatingOutletFailed: true, creatingOutletDone: true, creatingOutletText: '', creatingOutlet: false }));
                    setErrors(res.message);
                }
            });

        setState(o => ({ ...o, creatingOutlet: true }));
    };

    const actionButton = <button type='submit' className='btn btn-primary'>
        <i className='fa fa-send me-2'></i>
        <span>Create outlet</span>
    </button>;

    return (
        <CardLayout cardElement='form' cardElementProps={{ method: 'post', onSubmit: handleSubmit }}
            pageName='Create outlet' pageIcon='plus' cardFooter={actionButton}>

            <Busy busy={state.creatingOutlet} busyText='Creating outlet...' />

            <Busy busy={state.gettingManagers} busyText='Retrieving branch managers...' />

            <SuccessStatusModal
                show={state.creatingOutletDone && !state.creatingOutletFailed}
                onDone={() => setState(o => ({ ...o, creatingOutletDone: false }))}
                text={state.creatingOutletText} />

            <ScrollEndTrigger maxHeight='350px'>
                {state.managers && <>

                    <div className='row'>





                        <div className='mb-3 col-12 col-md-6'>
                            <InputField name='outlet_code' labelText='Outlet code' errs={errors} type='text' />
                        </div>

                        <div className='mb-3 col-12 col-md-6'>
                            <InputField name='outlet_image' labelText='Outlet Image' errs={errors} type='file' attrs={{ accept: 'image/*' }} />
                        </div>



                        <div className='mb-3 col-12 col-md-6'>
                            <InputField name='outlet_name' labelText='Outlet name' errs={errors} type='text' />
                        </div>



                        



                        <div className='mb-3 col-12 col-md-6'>
                            <InputField name='location' labelText='Location' errs={errors} />
                        </div>






                    </div>
                </>}
            </ScrollEndTrigger>


        </CardLayout>
    );
}

export default CreateOutlet;