import React, { useContext, useEffect, useState } from 'react';
import { useParams, Link, useNavigate } from "react-router-dom";
import Busy from '../components/Busy';
import Button from '../components/Button';
import InputField from '../components/InputField';
import ScrollEndTrigger from '../components/ScrollEndTrigger';
import { ErrorStatusModal, SuccessStatusModal } from '../components/StatusModal';
import CardLayout from '../layouts/CardLayout';
import Paths from '../Paths';
import Util from '../Util';

function DailySales(props) {
    const
        [state, setState] = useState({
            consignment: null,
            pumps: null,

            gettingData: false,
            gettingDataDone: false,
            gettingDataText: '',
            gettingDataFailed: false,

            creatingConsignment: false,
            creatingConsignmentDone: false,
            creatingConsignmentText: '',
            creatingConsignmentFailed: false,

            closingConsignment: false,
            closingConsignmentDone: false,
            closingConsignmentText: '',
            closingConsignmentError: false,

            submittingReading: false,
            submitReadingError: false,
            submitReadingText: '',
            submitReadingDone: false,

            errs: {},
        }),

        [pumpsValues, setPumpsValues] = useState({}),

        [pumpsConfirmValues, setPumpsConfirmValues] = useState({}),

        { user: { productTypes, username, position: pos }, currentOutlet: outletId } = useContext(Util.UserContext),

        modalDuration = 1700,

        allReadginsValid = () => {
            const keys = Object.keys(pumpsConfirmValues);

            if (Object.keys(pumpsValues).length !== keys.length) return false;

            return keys.every(k => pumpsValues[k] === pumpsConfirmValues[k]);
        }

    function prepPumps(res) {

        let pumps = false;

        if (res.status) {
            res.data = res.data.filter(({ assignedTo: at }) => at && at.username === username);

            pumps = {};
            for (const { id, name } of productTypes)
                pumps[name] = res.data.filter(o => o.tank.tankType.id === id);
        }

        return pumps;
    }




    useEffect(() => {

        Util.request.get(`getpumps/${outletId}`)
            .then(pumpResponse => {
                if (!pumpResponse.status) throw pumpResponse.message;

                const pumpsValues = {};

                for (const o of pumpResponse.data.filter(({ assignedTo: at }) => at && at.username === username))
                    pumpsValues[o.pumpId] = o.lastMeterReading;

                setPumpsValues(pumpsValues);

                setState(o => ({
                    ...o,
                    gettingDataText: 'Success',
                    gettingData: false,
                    gettingDataDone: true,
                    gettingDataFailed: false,
                    pumps: prepPumps(pumpResponse)
                }));


            }).catch(message => setState(o => ({
                ...o,
                gettingDataText: message,
                consignment: false,
                gettingData: false,
                gettingDataDone: true,
                gettingDataFailed: true
            })));

        setState(o => ({ ...o, gettingData: true }));

    }, []);

    function triggerSubmit(e) {
        e.preventDefault();
        e.currentTarget.previousElementSibling.click();
        e.currentTarget.form.dispatchEvent(new SubmitEvent('submit', { bubbles: true, cancelable: true }))
    };


    function handleSubmit(e) {
        e.preventDefault();

        Util.request.post('submitreading', new FormData(e.target))
            .then(res => {

                setState(o => ({
                    ...o,
                    submittingReading: false,
                    submitReadingError: !res.status,
                    submitReadingText: typeof res.message === 'string' ? res.message : 'An error occured!',
                    submitReadingDone: true,
                    errs: typeof res.message === 'string' ? {} : res.message,
                    pumps: prepPumps(res) || state.pumps
                }));
            })

        setState(o => ({ ...o, submittingReading: true }));
    }


    return (
        <CardLayout pageName='Daily sales'
            pageIcon='calculator'
            cardFooter={<Button text='Confirm' icon='check' outline sm attrs={{ ['data-bs-toggle']: "modal", ['data-bs-target']: "#confirm-reading-modal" }} />}
        >
            <div className="modal fade" id="confirm-reading-modal" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex="-1" aria-labelledby="confirmReadingModal" aria-hidden="true">
                <form onSubmit={handleSubmit} className="modal-dialog modal-dialog-centered modal-dialog-scrollable modal-xl">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h1 className="modal-title fs-6 text-primary">Confirm meter values</h1>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div className="modal-body">
                            {state.pumps && Object.entries(state.pumps).map(([type, pumps], i) => {
                                if (pumps.length === 0) return null;
                                return (
                                    <div key={`${type}-${i}`} className="mb-5">
                                        <h5 className='text-primary' style={{ opacity: .5 }}>{type}</h5>

                                        {pumps.map((o, i) => <div key={i}>
                                            <InputField type='number' name={o.pumpId} errs={
                                                pumpsConfirmValues[o.pumpId] &&
                                                pumpsConfirmValues[o.pumpId] !== pumpsValues[o.pumpId] &&
                                                { [o.pumpId]: [{ message: <span><i className='fa fa-warning'></i> Incorrect</span> }] }
                                            }
                                                labelText={o.uniqueName}
                                                attrs={{
                                                    step: .01,
                                                    onInput: e => setPumpsConfirmValues(_o => ({ ..._o, [o.pumpId]: Number(e.target.value) }))
                                                }}
                                            />
                                        </div>)}
                                        <hr className='mt-5' />
                                    </div>
                                )
                            })}
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-outline-secondary btn-sm" data-bs-dismiss="modal">
                                <i className='fa fa-times me-2'></i>
                                Close
                            </button>
                            {allReadginsValid() && <button type="button" onClick={triggerSubmit} className="btn btn-primary btn-sm">
                                <i className='fa fa-save me-2'></i>
                                Save
                            </button>}
                        </div>
                    </div>
                </form>
            </div>






            {/* GETTING CONSIGNMENTS */}
            <Busy busy={state.gettingData} busyText='Retrieving data...' />

            <ErrorStatusModal
                onDone={() => setState(o => ({ ...o, gettingDataDone: false }))}
                show={state.gettingDataDone && state.gettingDataFailed} text={state.gettingDataText}
            />
            {/* END OF GETTING CONSIGNMENTS */}













            {/* METER READING SUBMISSION */}
            <Busy busy={state.submittingReading} busyText='Submitting reading...' />

            <SuccessStatusModal
                onDone={() => setState(o => ({ ...o, submitReadingDone: false }))}
                show={state.submitReadingDone && !state.submitReadingError} text={state.submitReadingText}
                duration={modalDuration}
            />

            <ErrorStatusModal
                onDone={() => setState(o => ({ ...o, submitReadingDone: false }))}
                show={state.submitReadingDone && state.submitReadingError} text={state.submitReadingText}
            />
            {/* END OF METER READING SUBMISSION */}



            {(function () {
                if (state.pumps) {
                    const pumpEntires = Object.entries(state.pumps);
                    if (!pumpEntires.reduce((len, [, p]) => len + p.length, 0)) return <center className='text-muted'>
                        {
                            pos.canBeAssignedPumps ?
                                'You have no pumps assigned to you.' :
                                'No pumps created for this outlet.'
                        }
                    </center>
                    return (<ScrollEndTrigger maxHeight={'54vh'}>
                        {pumpEntires.map(([type, pumps], i) => {
                            if (pumps.length === 0) return null;
                            return (
                                <div key={`${type}-${i}`} className="mb-5">
                                    <h4 className='text-primary'>{type}</h4>
                                    <table className='table table-border' style={{ textAlign: 'left' }}>
                                        <thead>
                                            <tr>
                                                <th></th>
                                                <th>Pump name</th>
                                                <th>Last reading</th>
                                                <th>Date</th>
                                                <th>Connected tank</th>
                                                <th style={{ textAlign: 'end' }}>New reading</th>
                                            </tr>
                                        </thead>
                                        <tbody>

                                            {pumps.map((o, i) => <tr key={i}>
                                                <th>{i + 1}</th>
                                                <td>{o.uniqueName}</td>
                                                <td>{o.lastMeterReading}</td>
                                                <td>{!!o.dateOfReading && `${o.dateOfReading}, ${o.timeOfReading}`}</td>
                                                <td>{o.tank.tankName}</td>
                                                <td style={{ textAlign: 'end' }}>
                                                    <input type='number' step='.01'
                                                        min={o.lastMeterReading}
                                                        value={pumpsValues[o.pumpId]}
                                                        onInput={e => setPumpsValues(_o => ({ ..._o, [o.pumpId]: Number(e.target.value) }))}
                                                        name={o.pumpId}
                                                        className={`form-control d-inline-block text-end ${(state.errs.pumpId && state.errs.pumpId === o.pumpId ? 'error' : '')}`}
                                                        style={{ maxWidth: '200px' }} />
                                                    {state.errs.pumpId === o.pumpId && state.errs.error.meter_reading.map((e, i) => <span key={i}>{e.message}</span>)}
                                                </td>
                                            </tr>)}
                                        </tbody>
                                    </table>
                                    <hr className='mt-5' />
                                </div>
                            )
                        })}
                    </ScrollEndTrigger>)
                }
            })()}

        </CardLayout>
    );
}

export default DailySales;