import React, { useContext, useEffect, useRef, useState } from 'react';
import { useParams, useNavigate } from "react-router-dom";
import Busy from '../components/Busy';
import Button from '../components/Button';
import ScrollEndTrigger from '../components/ScrollEndTrigger';
import { ErrorStatusModal, SuccessStatusModal } from '../components/StatusModal';
import Table from '../components/Table';
import CardLayout from '../layouts/CardLayout';
import Paths from '../Paths';
import Util from '../Util';
import ActionHeader from '../components/ActionHeader';

function Consignments(props) {
    const

        [state, setState] = useState({
            consignments: [],
            gettingConsignments: false,
            gettingConsignmentsDone: false,
            gettingConsignmentsError: false,
            gettingConsignmentsText: '',




            openingConsignments: false,
            openingConsignmentsDone: false,
            openingConsignmentsFailed: false,
            openingConsignmentsText: '',

        }),

        { currentOutlet: outletId = 0, user: { position: pos } } = useContext(Util.UserContext),

        onModalDone = () => setState(o => ({ ...o, gettingConsignmentsDone: false })),

        navigate = useNavigate();

    useEffect(() => {
        if (state.consignments.length > 0) return;

        Util.request.get(`consign/${outletId}`)
            .then(res => {
                setState({
                    consignments: res.data,
                    gettingConsignments: false,
                    gettingConsignmentsError: !res.status,
                    gettingConsignmentsText: res.message,
                    gettingConsignmentsDone: true
                });
            });

        setState(o => ({ ...o, gettingConsignments: true }));
    }, []);

    function handleOpenConsignment(e) {
        e.preventDefault();

        Util.request.post(`createconsign`)
            .then(res => {
                setState(o => ({
                    ...o,
                    openingConsignments: false,
                    openingConsignmentsDone: true,
                    openingConsignmentsFailed: !res.status,
                    openingConsignmentsText: res.message,

                    consignments: res.status ? ([res.data, ...state.consignments]) : state.consignments
                }));
            });

        setState(o => ({ ...o, openingConsignments: true }))
    }

    return (
        <CardLayout
            pageName='Consignments' pageIcon='box-open'
            cardHeader={<ActionHeader actions={[{
                showIf: pos.canCreateConsignments && state.consignments.filter(c => c.isOpen).length === 0,
                text: 'Open consignment',
                icon: 'box-open',
                onClick: handleOpenConsignment,
            }]} />
            }
        >


            <Busy busy={state.gettingConsignments} busyText='Retrieving sales records...' />

            <Busy busy={state.openingConsignments} busyText='Opening consignment...' />


            <ErrorStatusModal
                onDone={onModalDone}
                show={state.gettingConsignmentsDone && state.gettingConsignmentsError}
                text={state.gettingConsignmentsText}
            />


            <SuccessStatusModal
                onDone={() => setState(o => ({ ...o, openingConsignmentsDone: false }))}
                show={state.openingConsignmentsDone && !state.openingConsignmentsFailed}
                text={state.openingConsignmentsText}
            />


            <ErrorStatusModal
                onDone={() => setState(o => ({ ...o, openingConsignmentsDone: false }))}
                show={state.openingConsignmentsDone && state.openingConsignmentsFailed}
                text={state.openingConsignmentsText}
            />

            <ScrollEndTrigger maxHeight={'65vh'}>
                <Table
                    data={state.consignments}
                    keys={['date', 'time', 'closeDate']}
                    tableHead={['Date opened', 'Open time', 'Date closed']}
                    actions={{ icon: 'eye', text: 'View', on: data => navigate(Paths.DAILY_SALES.join(Paths.METER_READINGS).parse(data.consignId).abs.$) }}
                />
            </ScrollEndTrigger>
        </CardLayout>
    );
}


export default Consignments;







export function ConsignmentReadings(props) {


    const [state, setState] = useState({
        gettingReading: false,
        gettingReadingDone: false,
        gettingReadingError: false,
        gettingReadingText: '',
    }),

        cache = useRef({}),

        { consignmentId } = useParams(),
        modalDuration = 1700;

    useEffect(() => {
        if (cache.current[consignmentId] !== undefined) return;

        Util.request.get()
    })


    return <CardLayout pageName='Consignments' pageIcon='box-open'>


        <Busy busy={state.gettingConsignments} busyText='Retrieving sales records...' />

        <SuccessStatusModal
            // onDone={onModalDone}
            show={state.gettingConsignmentsDone && !state.gettingConsignmentsError}
            text={state.gettingConsignmentsText}
            duration={modalDuration}
        />

        <ErrorStatusModal
            // onDone={onModalDone}
            show={state.gettingConsignmentsDone && state.gettingConsignmentsError}
            text={state.gettingConsignmentsText}
        />

        <ScrollEndTrigger maxHeight={'65vh'}>
            <Table
                data={state.consignments}
                keys={['date', 'time', 'closeDate', 'totalReading', 'consignmentDiff']}
                tableHead={['Date opened', 'Open time', 'Date closed', 'Total Reading', 'Difference']}
            />
        </ScrollEndTrigger>
    </CardLayout>

}