import React, { useContext, useEffect, useState } from 'react';
import CardLayout from '../layouts/CardLayout';
import Util from '../Util';
import Busy from '../components/Busy';
import ScrollEndTrigger from '../components/ScrollEndTrigger';
import { ErrorStatusModal, SuccessStatusModal } from '../components/StatusModal';
import InputField from '../components/InputField';
import Button from '../components/Button';
import { useNavigate } from 'react-router-dom';
import Paths from '../Paths';
import SelectInput from '../components/SelectInput';
import ActionHeader from '../components/ActionHeader';


function TankTransfer(props) {
    const
        [state, setState] = useState({
            makingTransferFailed: false,
            makingTransferDone: false,
            makingTransferText: '',
            makingTransfer: false,

            gettingTanks: false,
            gettingTanksDone: false,
            gettingTanksText: '',
            gettingTanksFailed: false,

            selectedFromTankId: -1,

            errs: {},

            tanks: []
        }),
        { currentOutlet } = useContext(Util.UserContext),
        navigate = useNavigate(),

        handleSubmit = (e) => {
            e.preventDefault();

            Util.request.post('tanktransfer', new FormData(e.target))
                .then(res => {
                    setState(o => ({
                        ...o,
                        makingTransfer: false,
                        makingTransferDone: true,
                        makingTransferFailed: !res.status,
                        makingTransferText: Util.resolveResponseMessage(res),
                        errs: Util.resolveFormError(res),
                        tanks: res.status ? res.data : state.tanks
                    }))
                });

            setState(o => ({ ...o, makingTransfer: true }))
        };


    useEffect(() => {
        Util.request.get(`gettanks/${currentOutlet}`)
            .then(res => {

                setState(o => ({
                    ...o,
                    gettingTanks: false,
                    tanks: res.status ? res.data : [],
                    gettingTanksDone: true,
                    gettingTanksText: res.message,
                    gettingTanksFailed: !res.status
                }));
            })

        setState(o => ({ ...o, gettingTanks: true }))
    }, []);


    const actionButton = <Button text='Make transfer' icon='arrow-right' />;

    return (
        <CardLayout cardElement='form' cardElementProps={{ method: 'post', onSubmit: handleSubmit }}
            pageName='Tank transfer' pageIcon='arrow-right' cardFooter={actionButton}
            cardHeader={<ActionHeader actions={[{
                text: 'History',
                icon: 'clock',
                onClick: () => navigate(Paths.DAILY_SALES.join(Paths.TANK_TRANSFER_HISTORY).abs.$),
            }]} />}>



            <Busy busy={state.makingTransfer} busyText='Making transfer...' />



            <Busy busy={state.gettingTanks} busyText='Retrieving tanks...' />



            <SuccessStatusModal
                show={state.makingTransferDone && !state.makingTransferFailed}
                onDone={() => setState(o => ({ ...o, makingTransferDone: false }))}
                text={state.makingTransferText} />



            <ErrorStatusModal
                show={state.makingTransferDone && state.makingTransferFailed}
                onDone={() => setState(o => ({ ...o, makingTransferDone: false }))}
                text={state.makingTransferText} />



            <ErrorStatusModal
                show={state.gettingTanksDone && state.gettingTanksFailed}
                onDone={() => setState(o => ({ ...o, gettingTanksDone: false }))}
                text={state.gettingTanksText} />




            <ScrollEndTrigger maxHeight='390px'>
                {
                    state.tanks && <div className='row'>
                        <div className='col-md-6 mb-4'>
                            <SelectInput
                                vaule={state.selectedFromTankId}
                                onChange={e => setState(o => ({ ...o, selectedFromTankId: +e.target.value }))}
                                data={state.tanks}
                                defaultOption='Select source tank'
                                name='tank_from'
                                labelText='From'
                                keys={['tankId', t => `${t.tankName} (${t.quantityLeft}L left) - ${t.category}`]}
                                errs={state.errs}
                            />
                        </div>

                        <div className='col-md-6 mb-4'>
                            <SelectInput
                                data={state.tanks.filter(t => t.tankId !== state.selectedFromTankId)}
                                defaultOption='Select destination tank'
                                name='tank_to'
                                labelText='To'
                                keys={['tankId', t => `${t.tankName} (${t.quantityLeft}L left) - ${t.category}`]}
                                errs={state.errs}
                            />
                        </div>

                        <div className='col-12'>
                            <InputField name='quantity' labelText='Quantity' attrs={{ type: 'number', min: 0, defaultValue: 0, step: '.001' }} />
                        </div>
                    </div>
                }
            </ScrollEndTrigger>


        </CardLayout>
    );
}

export default TankTransfer;