import React, { useContext, useEffect, useState } from 'react';
import CardLayout from '../layouts/CardLayout';
import Util from '../Util';
import Busy from '../components/Busy';
import { ErrorStatusModal, SuccessStatusModal } from '../components/StatusModal';
import Button from '../components/Button';
import InputField from '../components/InputField';


function UploadTeller(props) {
    const
        [state, setState] = useState({

            uploadingTeller: false,
            uploadingTellerDone: false,
            uploadingTellerError: false,
            uploadingTellerText: '',
            errors: {}

        });



    const handleSubmit = (e) => {
        e.preventDefault();

        const imageFile = e.target.querySelector('[type=file]').files[0];

        let fd = new FormData(e.target);

        if (imageFile) fd.append('image', imageFile);

        Util.request.post('submitteller', fd)
            .then(res => {
                setState(o => ({
                    ...o,
                    uploadingTeller: false,
                    uploadingTellerDone: true,
                    uploadingTellerError: !res.status,
                    uploadingTellerText: typeof res.message === 'string' ? res.message : '',
                    errors: typeof res.message === 'string' ? {} : res.message
                }))
            })

        setState(o => ({ ...o, uploadingTeller: true }))

    };

    const actionButton = <Button icon='upload' text='Upload' />;

    return (
        <CardLayout cardElement='form' cardElementProps={{ onSubmit: handleSubmit }}
            pageName='upload teller' pageIcon='upload' cardFooter={actionButton}>

            <Busy busy={state.uploadingTeller} busyText='Please wait...' />

            <ErrorStatusModal
                show={state.uploadingTellerDone && state.uploadingTellerError}
                onDone={() => setState(o => ({ ...o, uploadingTellerDone: false }))}
                text={state.uploadingTellerText} />

            <SuccessStatusModal
                show={state.uploadingTellerDone && !state.uploadingTellerError}
                onDone={() => setState(o => ({ ...o, uploadingTellerDone: false }))}
                text={state.uploadingTellerText} />


            <div className='mb-3'>
                <InputField
                    errs={state.errors} name='amount'
                    labelText='Amount' labelIcon='money'
                    attrs={{ type: 'number', min: 0, step: .2 }}
                />
            </div>



            <div className='mb-3'>
                <InputField errs={state.errors} name='Image' labelText='Teller' labelIcon='camera' attrs={{ type: 'file', accept: 'image/*' }} />
            </div>



            <div className='mb-3'>
                <label className='form-label text-muted'>Comment</label>
                <textarea className='form-control' name='comment'></textarea>
            </div>


        </CardLayout>
    );
}

export default UploadTeller;