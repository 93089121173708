import React, { useContext, useEffect, useState, useMemo } from 'react';
import PageHeader from '../layouts/PageHeader';
import Util from '../Util';
import Busy from '../components/Busy';
import Table from '../components/Table';
import { Chart as ChartJS, ArcElement, Filler, Tooltip, Legend, CategoryScale, LinearScale, PointElement, LineElement, RadialLinearScale } from "chart.js";
import { Doughnut, Line, PolarArea } from "react-chartjs-2";
import { useRef } from 'react';

function Dashboard(props) {

    const
        { user: { position: pos, fullnames }, currentOutlet: outletId } = useContext(Util.UserContext),
        [state, setState] = useState({
            gettingData: false,
            gettingDataDone: false,
            gettingDataMessage: '',
            gettingDataFailed: false,

            data: null
        }),
        { current: percent } = useRef({ $: 0 }),
        { current: hsl } = useRef({ $: 0 });

    ChartJS.register(ArcElement, Tooltip, Legend, Filler, CategoryScale, LinearScale, PointElement, LineElement, RadialLinearScale);

    useEffect(() => {
        if (state.data !== null || outletId === -1) return

        Util.request.get(`dashboard/${outletId}`)
            .then(res => setState(o => ({
                ...o,
                gettingData: false,
                gettingDataDone: true,
                gettingDataFailed: !res.status,
                gettingDataMessage: res.message,

                data: res.status ? res.data : false
            })));


        setState(o => ({ ...o, gettingData: true }))

    }, [state.data]);




    return (
        <>
            <Busy busyText='Retrieving data...' busy={state.gettingData} />

            <div className='overflow-auto'>
                <PageHeader pageName='dashboard' icon='dashboard' />

                <div className='row mt-4'>

                    {/* THE USER INFOR */}
                    <div className='col-12 col-md-8 col-lg-8'>
                        <div className='card'>
                            <div className='card-body'>
                                <p className='text-muted fs-1 m-0' style={{ fontWeight: 100 }}>Welcome,</p>
                                <h5 className='text-primary m-0'>{fullnames}</h5>
                                <small className='text-muted small'>{pos.name}</small>
                            </div>
                        </div>
                    </div>
                </div>

                {
                    !!state.data && pos.canViewOutletStats && <div className='mt-5 row'>
                        <div className='col-12 col-lg-8'>
                            <div className='card'>
                                <h6 className='card-header text-primary'>This Year</h6>
                                <div className='card-body'>
                                    <Line
                                        data={{
                                            labels: state.data.stats.monthly.label,
                                            datasets: [
                                                {
                                                    label: "Monthly sales",
                                                    backgroundColor: "rgb(156, 65, 0)",
                                                    borderColor: "rgba(187, 78, 0, .7)",
                                                    data: state.data.stats.monthly.data,
                                                },
                                            ],
                                        }}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className='col-12 col-lg-4'>
                            <div className='card'>
                                <h6 className='card-header text-primary'>This Week</h6>
                                <div className='card-body'>
                                    <Doughnut
                                        data={{
                                            labels: state.data.stats.daily.label,
                                            datasets: [
                                                {
                                                    label: "Weekly sales",
                                                    backgroundColor: [
                                                        "#007D9C",
                                                        "#00BA82",
                                                        "#D123B3",
                                                        "#F7E018",
                                                        "#7D2A00",
                                                        "#FE452A",
                                                        "#3CA125"
                                                    ],
                                                    borderWidth: 10,
                                                    borderRadius: 40,
                                                    cutout: 90,
                                                    data: state.data.stats.daily.data,
                                                    hoverBorderWidth: 1,
                                                    hoverOffset: 20
                                                },
                                            ]
                                        }}
                                    />
                                </div>
                            </div>
                        </div>

                        <div className='col-12 mt-3'>
                            <div className='card'>
                                <h6 className='card-header text-primary'>Last 10 Years</h6>
                                <div className='card-body'>
                                    <Line
                                        data={{
                                            labels: state.data.stats.yearly.label,
                                            datasets: [
                                                {
                                                    fill: true,
                                                    label: "Yearly sales",
                                                    backgroundColor: "#46A75880",
                                                    borderColor: "#46A758",
                                                    data: state.data.stats.yearly.data,
                                                },
                                            ]
                                        }}
                                        options={{
                                            aspectRatio: 3 | 1
                                        }}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                }

                <div className='mt-5 row'>

                    <PageHeader pageName='Tanks' icon='oil-can' />

                    <br />
                    <br />


                    {/* THE TANKS */}
                    {state.data &&
                        state.data.tanks.map((tank, i) => <div className='col-12 col-md-4 col-lg-2 mb-4'>
                            {!!(percent.$ = (100 * (tank.quantityLeft / tank.capacity)).toFixed(2))}
                            <div className='box'>
                                <h6 className="text-primary">{tank.tankName}</h6>
                                <div className="percent">
                                    <svg>
                                        <circle cx='70' cy='70' r='70' style={{ stroke: `hsla(${(tank.quantityLeft / tank.capacity) * 100}, 100%, 40%, .1)` }}></circle>
                                        <circle cx='70' cy='70' r='70'
                                            style={{
                                                strokeDashoffset: `calc(440 - (440 * ${percent.$}) / 100)`,
                                                stroke: `hsl(${(tank.quantityLeft / tank.capacity) * 100}, 75%, 40%)`
                                            }}></circle>
                                    </svg>
                                    <div className="number">
                                        <h2>{percent.$}<span>%</span></h2>
                                    </div>
                                </div>
                                <h5 className="text-primary">{Util.format(tank.quantityLeft)}L</h5>
                                <div className="text-muted">{Util.format(tank.capacity)}</div>
                            </div>
                        </div>)
                    }

                </div>

                <div className='mt-5 row'>

                    <PageHeader pageName='pumps' icon='gas-pump' />

                    <br />
                    <br />

                    {/* THE PUMPS */}
                    {state.data &&
                        state.data.pumps.map((pump, i) => <div key={i} className='col-12 col-md-4 mb-4'>
                            <div className='card'>
                                <div className='card-body'>
                                    <p className='fw-bold text-muted m-0'>{pump.uniqueName}</p>
                                    <h5 className='text-primary m-0'>{pump.lastMeterReading}</h5>
                                    <small className='text-muted small'>{pump.dateOfReading}, {pump.timeOfReading}</small>
                                </div>
                            </div>
                        </div>)

                    }

                </div>



                <div className='mt-5 row'>

                    <PageHeader pageName='Staff' icon='users' />

                    <br />
                    <br />


                    {/* THE TANKS */}
                    {state.data &&

                        <div className='col-12'>
                            <div className='card'>
                                <div className='card-body'>
                                    <Table
                                        data={state.data.staff.map(({ staff }) => staff)}
                                        tableHead={['username', 'Fullnames', 'Position']}
                                        keys={['username', 'fullnames', 'position.name']}
                                    />
                                </div>
                            </div>
                        </div>

                    }

                </div>
            </div>
        </>
    );
}

export default Dashboard;