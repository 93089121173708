import React, { useContext, useEffect, useRef, useState } from 'react';
import Busy from '../components/Busy';
import ScrollEndTrigger from '../components/ScrollEndTrigger';
import Table from '../components/Table';
import CardLayout from '../layouts/CardLayout';
import Util from '../Util';
import { useNavigate, useParams } from "react-router-dom";
import Button from '../components/Button';
import Paths from '../Paths';
import { ErrorStatusModal, SuccessStatusModal } from '../components/StatusModal';


const Field = props => <div className='col-12 col-md-6 mb-4'>
    <label className='form-label fw-bold text-primary m-0'>{props.label}</label>
    <h6 className='m-0 text-muted'>{props.text}</h6>
</div>;


// All the cashbooks by dates
function Profile(props) {

    const { user } = useContext(Util.UserContext);


    return (
        <CardLayout pageName='Profile' pageIcon='user' >
            <ScrollEndTrigger maxHeight='430px'>
                <div className='row'>
                    <Field label='Username' text={user.username} />

                    <Field label='Position' text={user.position.name} />

                    <Field label='Lastname' text={user.lastname} />

                    <Field label='Firstname' text={user.firstname} />

                    <Field label='Email' text={user.email} />

                    <Field label='Phone' text={user.phone} />

                    <Field label='Gender' text={user.gender} />

                    <Field label='Date of birth' text={user.dateOfBirth} />

                    <Field label='State' text={user.stateOfOrigin} />

                    <Field label='Lga' text={user.lgaOfOrigin} />

                    <Field label='Residential Address' text={user.residentialAddr} />
                </div>

     

               


                
            </ScrollEndTrigger>
        </CardLayout>
    )
}

export default Profile;