import React, { useEffect, useRef, useState } from 'react';

function StatusModal({ onDone, show, duration, children }) {

    useEffect(() => {
        show && setTimeout(() => typeof onDone === 'function' && onDone(), (Number(duration) || 1800))
    });

    return (
        !!show && <div className='component-modal'>
            <div className='component-modal-container status-modal py-5'>
                {children}
            </div>
        </div>
    );
}



function SuccessStatusModal({ onDone, show, duration, text }) {

    const [display, setDisplay] = useState('none');
    useEffect(() => {
        if (show) {
            setTimeout(() => setDisplay(''), duration || 10);
        }
    });

    return (
        <StatusModal duration={duration} onDone={onDone} show={show}>
            <div className="success-wrapper">
                <div>
                    <div></div>
                    <div></div>
                </div>
                <span></span>
            </div>
            <p className='m-0 mt-3' style={{ fontSize: '1.3rem' }}>{text}</p>
        </StatusModal>
    );
}



function ErrorStatusModal({ onDone, show, duration, text }) {
    return (
        <StatusModal onDone={onDone} show={show} duration={Number(duration) || 3000}>
            <svg version="1.1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 130.2 130.2">
                <circle className="path circle" fill="none" stroke="#D06079" strokeWidth="6" strokeMiterlimit="10" cx="65.1" cy="65.1" r="62.1" />
                <line className="path line" fill="none" stroke="#D06079" strokeWidth="6" strokeLinecap="round" strokeMiterlimit="10" x1="34.4" y1="37.9" x2="95.8" y2="92.3" />
                <line className="path line" fill="none" stroke="#D06079" strokeWidth="6" strokeLinecap="round" strokeMiterlimit="10" x1="95.8" y1="38" x2="34.4" y2="92.2" />
            </svg>
            <p className='m-0 mt-3 error' style={{ fontSize: '1.29rem' }}>{text}</p>
        </StatusModal>
    );
}






export { SuccessStatusModal, ErrorStatusModal };