import React from 'react';

function SelectInput({ defaultOptionValue, name, id, errs, data, keys, labelIcon, labelText, value, onChange, defaultOption, }) {
    if (!name) return null;

    defaultOptionValue = defaultOptionValue === undefined ? -1 : defaultOptionValue;

    name = name.toString();
    id = id || name.replace(/\s+/, '_').toLowerCase();
    errs = errs && typeof errs === 'object' ? errs : {};
    data = Array.isArray(data) ? data : [];
    keys = Array.isArray(keys) ? keys : [];
    let getValue = keys[0], getText = keys[1];

    if (typeof getValue !== 'function') getValue = o => o[String(keys[0])];
    if (typeof getText !== 'function') getText = o => o[String(keys[1])];

    return (
        <div>
            <label className='form-label text-muted' htmlFor={id}>
                {!!labelIcon && <i className={`fa fa-${labelIcon} me-2`}></i>}
                {labelText}
            </label>

            <select className={`form-select ${(errs[name] ? 'error' : '')}`}
                id={id} name={name}
                {...(onChange && { onChange: onChange })}
                {...(value && { value: value })}
                {...({ defaultValue: defaultOptionValue })}
            >
                {
                    !!defaultOption &&
                    <option value={defaultOptionValue} disabled>{defaultOption.toString()}</option>
                }
                {data.map((d, i) => <option key={i} value={getValue(d)}>{getText(d)}</option>)}
            </select>

            {errs[name] && errs[name].map((e, i) => <span key={i}>{e.message}</span>)}
        </div>
    );
}

export default SelectInput;