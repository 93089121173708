import React, { useContext, useEffect, useState } from 'react';
import CardLayout from '../layouts/CardLayout';
import Util from '../Util';
import Busy from '../components/Busy';
import { ErrorStatusModal, SuccessStatusModal } from '../components/StatusModal';
import Button from '../components/Button';
import InputField from '../components/InputField';
import ScrollEndTrigger from '../components/ScrollEndTrigger';
import { useParams } from 'react-router-dom';


function CashRemitView(props) {
    const
        [state, setState] = useState({

            gettingRemit: false,
            gettingRemitDone: false,
            gettingRemitError: false,
            gettingRemitText: '',

            remit: null,


            confirmingRemit: false,
            confirmingRemitDone: false,
            confirmingRemitError: false,
            confirmingRemitText: '',


            concludingRemit: false,
            concludingRemitDone: false,
            concludingRemitError: false,
            concludingRemitText: '',


            uploadingPOP: false,
            uploadingPOPDone: false,
            uploadingPOPError: false,
            uploadingPOPText: '',


        }),
        { remitId } = useParams(),
        { user } = useContext(Util.UserContext);



    useEffect(() => {
        if (state.gettingRemit || state.remit !== null) return;

        Util.request.get(`remit/${remitId}`)
            .then(res => {
                setState(o => ({
                    ...o,
                    gettingRemit: false,
                    gettingRemitDone: true,
                    gettingRemitError: !res.status,
                    gettingRemitText: res.message,
                    remit: res.status ? res.data : false
                }));
            })
        setState(o => ({ ...o, gettingRemit: true }));
    }, []);

    function handleConfirmRemit(e) {

        e.preventDefault();

        Util.request.post(`remit/${state.remit.remitId}/confirm`, new FormData(e.target))
            .then(res => setState(o => ({
                ...o,
                confirmingRemit: false,
                confirmingRemitDone: true,
                confirmingRemitError: !res.status,
                confirmingRemitText: res.message,
                remit: res.status ? res.data : state.remit
            })))

        setState(o => ({ ...o, confirmingRemit: true }));
    }

    function handleConcludeRemit(e) {
        e.preventDefault();
        Util.request.post(`remit/${state.remit.remitId}/conclude`, new FormData(e.target))
            .then(res => setState(o => ({
                ...o,
                concludingRemit: false,
                concludingRemitDone: true,
                concludingRemitError: !res.status,
                concludingRemitText: typeof res.message === 'string' ? res.message : 'An unexpected error occurred.',
                remit: res.status ? res.data : state.remit
            })))

        setState(o => ({ ...o, concludingRemit: true }));
    }

    function handlePOPSubmit(e) {
        e.preventDefault();

        const imageFile = e.target.querySelector('[type=file]').files[0];

        if (!imageFile) setState(o => ({
            ...o,
            uploadingPOPDone: true,
            uploadingPOPError: true,
            uploadingPOPText: 'Specify an image to upload'
        }));
        else {
            let fd = new FormData(e.target);

            fd.append('pop', imageFile);

            Util.request.post(`remit/${remitId}/pop`, fd)
                .then(res => setState(o => ({
                    ...o,
                    uploadingPOP: false,
                    uploadingPOPDone: true,
                    uploadingPOPError: !res.status,
                    uploadingPOPText: res.message,
                    remit: res.status ? res.data : state.remit
                })))

            setState(o => ({ ...o, uploadingPOP: true }));
        }

    }

    return (
        <CardLayout pageName='remit' pageIcon='money'>

            <Busy busy={state.gettingRemit} busyText='Retrieving remit...' />
            <Busy busy={state.confirmingRemit} busyText='Confirming remit...' />
            <Busy busy={state.concludingRemit} busyText='Concluding remit...' />
            <Busy busy={state.uploadingPOP} busyText='Uploading POP...' />


            <SuccessStatusModal
                show={state.confirmingRemitDone && !state.confirmingRemitError}
                onDone={() => setState(o => ({ ...o, confirmingRemitDone: false }))}
                text={state.confirmingRemitText} />

            <SuccessStatusModal
                show={state.concludingRemitDone && !state.concludingRemitError}
                onDone={() => setState(o => ({ ...o, concludingRemitDone: false }))}
                text={state.concludingRemitText} />

            <SuccessStatusModal
                show={state.uploadingPOPDone && !state.uploadingPOPError}
                onDone={() => setState(o => ({ ...o, uploadingPOPDone: false }))}
                text={state.uploadingPOPText} />




            <ErrorStatusModal
                show={state.gettingRemitDone && state.gettingRemitError}
                onDone={() => setState(o => ({ ...o, gettingRemitDone: false }))}
                text={state.gettingRemitText} />

            <ErrorStatusModal
                show={state.confirmingRemitDone && state.confirmingRemitError}
                onDone={() => setState(o => ({ ...o, gettingRemitDone: false }))}
                text={state.gettingRemitText} />

            <ErrorStatusModal
                show={state.uploadingPOPDone && state.uploadingPOPError}
                onDone={() => setState(o => ({ ...o, uploadingPOPDone: false }))}
                text={state.uploadingPOPText} />

            <ErrorStatusModal
                show={state.concludingRemitDone && state.concludingRemitError}
                onDone={() => setState(o => ({ ...o, concludingRemitDone: false }))}
                text={state.concludingRemitDone} />

            <ScrollEndTrigger maxHeight='440px'>

                {
                    state.remit && <>
                        <div className='row m-0 p-0'>

                            <div className='col-12 col-md-6 p-0 mb-3'>
                                <label className='form-label m-0 text-muted'>Date of remit</label>
                                <h6>{state.remit.date}</h6>
                            </div>



                            <div className='col-12 col-md-6 p-0 mb-3'>
                                <label className='form-label m-0 text-muted'>Time of remit</label>
                                <h6>{state.remit.time}</h6>
                            </div>



                            <div className='col-12 col-md-6 p-0 mb-3'>
                                <label className='form-label m-0 text-muted'>Remit amount</label>
                                <h6>{state.remit.amount}</h6>
                            </div>



                            <div className='col-12 col-md-6 p-0 mb-3'>
                                <label className='form-label m-0 text-muted'>Amount confirmed</label>
                                {
                                    state.remit.amountConfirmed ?
                                        <h6>{state.remit.amountConfirmed}</h6> :
                                        <i className='text-muted d-block'>Nill</i>
                                }

                            </div>




                            <div className='col-12 col-md-6 p-0 mb-3'>
                                <label className='form-label m-0 text-muted'>Remit by</label>
                                <h6>{state.remit.remitBy.fullnames}</h6>
                            </div>



                            <div className='col-12 col-md-6 p-0 mb-3'>
                                <label className='form-label m-0 text-muted'>Remit status</label>
                                <h6>{state.remit.status}</h6>
                            </div>







                            <div className='col-12 col-md-6 p-0 mb-3'>
                                <label className='form-label m-0 text-muted'>Proof of payment</label>
                                {
                                    state.remit.pop ?
                                        <h6><a href={state.remit.pop} target='_blank' className='text-decoration-none'><i className='fa fa-image'></i> View image here</a></h6>
                                        : <i className='text-muted d-block'>Nill</i>
                                }
                            </div>
                        </div>

                        {
                            state.remit && state.remit.comments.length ?  <>
                                <div className='col-12 p-0 mb-3'>
                                    <div className='d-flex align-items-center'>
                                        <p className='text-muted small p-0 m-0 me-3'>Comments</p>
                                        <hr className='m-0 p-0 flex-grow-1' />
                                    </div>
                                    <ul>
                                        {
                                            state.remit.comments.map((comm, k) => <li key={k} className='mb-3'>
                                                <div className='d-flex'>
                                                    <p className='small fw-bold m-0 text-primary me-3'>{comm.user.position.name}</p>
                                                    <p className='text-muted small m-0'><small>{comm.datetime}</small></p>
                                                </div>
                                                <p>{comm.comment}</p>
                                            </li>)
                                        }
                                    </ul>
                                </div></> :
                                null
                        }


                        {
                            user.position.canConfirmMondeyRemits &&
                            state.remit.amountConfirmed === null &&

                            <>
                                <div className='d-flex align-items-center mt-4'>
                                    <h6 className='text-primary me-2'>Confirm this remit</h6>
                                    <hr className='flex-grow-1 m-0' />
                                </div>
                                <form onSubmit={handleConfirmRemit}>
                                    <div className='mb-3'>
                                        <InputField name='amount' labelText='Confirm amount' attrs={{ type: 'number', min: 0, defaultValue: 0 }} />
                                    </div>

                                    <div className='mb-3'>
                                        <textarea name='comment' className='form-control' rows={10} placeholder='Comment...'></textarea>
                                    </div>

                                    <Button sm text='Confirm' icon='check' />
                                </form>
                            </>
                        }


                        {
                            user.position.canConfirmMondeyRemits &&
                            state.remit.amountConfirmed !== null &&
                            state.remit.pop === null &&

                            <>
                                <div className='d-flex align-items-center mt-4'>
                                    <h6 className='text-primary me-2'>Upload proof of payment</h6>
                                    <hr className='flex-grow-1 m-0' />
                                </div>
                                <form onSubmit={handlePOPSubmit}>

                                    <label htmlFor="formFileSm" className="form-label">Select image</label>
                                    <input className="form-control form-control-sm mb-3" id="formFileSm" accept='image/*' type="file" />

                                    <Button sm text='Upload' icon='upload' />
                                </form>
                            </>
                        }



                        {
                            user.position.canConcludeRemit &&
                            state.remit.pop !== null && state.remit.status === 'pending' &&

                            <>
                                <div className='d-flex align-items-center mt-4'>
                                    <h6 className='text-primary me-2'>Conclude this remit</h6>
                                    <hr className='flex-grow-1 m-0' />
                                </div>
                                <form onSubmit={handleConcludeRemit}>
                                    <div className='my-3'>
                                        <select name='status' className='form-select'>
                                            <option value={'approved'}>Approve</option>
                                            <option value={'declined'}>Reject</option>
                                        </select>
                                    </div>

                                    <div className='mb-3'>
                                        <textarea name='comment' className='form-control' rows={10} placeholder='Comment...'></textarea>
                                    </div>

                                    <Button sm text='Confirm' icon='check' />
                                </form>
                            </>
                        }

                    </>
                }

            </ScrollEndTrigger>
        </CardLayout>
    );
}


export default CashRemitView;