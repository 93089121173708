import React, { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Busy from '../components/Busy';
import Button from '../components/Button';
import ScrollEndTrigger from '../components/ScrollEndTrigger';
import Table from '../components/Table';
import CardLayout from '../layouts/CardLayout';
import Paths from '../Paths';
import Util from '../Util';
import ActionHeader from '../components/ActionHeader';


function Customers(props) {
    const
        [state, setState] = useState({
            customers: [],
            gettingCustomers: false,
            gettingCustomersDone: false,
            gettingCustomersMessage: false,
            gettingCustomersFailed: false
        }),

        { currentOutlet, user: { position } } = useContext(Util.UserContext),

        navigate = useNavigate();


    useEffect(() => {

        Util.request.get(`customer/list/${currentOutlet}`)
            .then(res => setState(o => ({
                customers: res.status ? res.data : state.customers,
                gettingCustomers: false,
                gettingCustomersDone: true,
                gettingCustomersMessage: res.message,
                gettingCustomersFailed: !res.status
            })));

        setState(o => ({ ...o, gettingCustomers: true }))
    }, []);


    return (
        <CardLayout
            pageName="Prepaid customers"
            pageIcon="money-bill"
            cardHeader={<ActionHeader title='Prepaid' actions={[{
                onClick: () => navigate(Paths.PREPAID.join(Paths.NEW_CUSTOMER).abs.$),
                text: 'New prepaid',
                icon: 'plus',
                showIf: position.canCreateCustomer,
            }]} />}>


            <Busy busy={state.gettingCustomers} busyText='Retrieving customers...' />

            <ScrollEndTrigger maxHeight='380px'>
                <Table
                    data={state.customers.map(o => {
                        o.allowCreditPurchase && (o.check = <i className='fa fa-check fw-bold text-primary'></i>);
                        o.takePriceSnapshot && (o.check2 = <i className='fa fa-check fw-bold text-primary'></i>);
                        return o;
                    })}
                    keys={['date', 'name', 'phone', 'check', 'check2']}
                    tableHead={['Date created', 'Names', 'Phones', 'Allow credit', 'Price snapshots']}
                    actions={[
                        o => ({ icon: 'pencil', text: 'Edit', hideIf: !position.canCreateCustomer, on: () => navigate(Paths.PREPAID.join(Paths.NEW_CUSTOMER.params({ _id: o.customerId })).abs.$) }),
                        o => ({ icon: 'clock', text: 'Purchases', on: () => navigate(Paths.PREPAID.join(Paths.PURCHASES.parse(o.customerId)).abs.$) }),
                        o => ({ icon: 'list', text: 'Subscriptions', on: () => navigate(Paths.PREPAID.join(Paths.SUBSCRIPTIONS.parse(o.customerId)).abs.$) })
                    ]}
                />
            </ScrollEndTrigger>
        </CardLayout>
    );
}


export default Customers;