import React, { useEffect, useRef } from 'react'
import Util from '../Util';
import InputField from './InputField';
import Button from './Button';

export default function DatetimeFields({ date, time, onDateChange = () => 0, onTimeChange = ()=>0 }) {

    return (
        <div className='row'>
            <div className='col-12 col-md-4 d-flex align-items-end'>
                <div className='flex-grow-1 me-2'>
                    <InputField name='date' attrs={{ value: date, onChange: e => onDateChange(e.target.value) }} labelText='Pick date' type='date' />
                </div>
                <div className="ms-2"></div>
                {/* <div className='flex-grow-1 me-2'>
                    <InputField name='time' attrs={{ value: time, onChange: e => onTimeChange(e.target.value) }} labelText='Time picker' type='time' />
                </div> */}
            </div>
        </div>
    )
}
