import React, { useContext, useEffect, useRef, useState } from 'react';
import CardLayout from '../layouts/CardLayout';
import Util from '../Util';
import Busy from '../components/Busy';
import ScrollEndTrigger from '../components/ScrollEndTrigger';
import { ErrorStatusModal, SuccessStatusModal } from '../components/StatusModal';
import Table from '../components/Table';
import { useNavigate } from 'react-router-dom';
import Paths from '../Paths';
import Modal from '../components/Modal';
import DatePicker from '../components/DatePicker';
import ActionHeader from '../components/ActionHeader';


function Slips(props) {
    const
        [state, setState] = useState({

            gettingSlips: false,
            gettingSlipsDone: false,
            gettingSlipsError: false,
            gettingSlipsText: '',

            deletingSlip: false,
            deletingSlipDone: false,
            deletingSlipError: false,
            deletingSlipText: '',

            slips: [],

            selectedDate: Util.today(true)
        }),

        [selected, setSelected] = useState({}),

        { currentOutlet, user: { position: pos } } = useContext(Util.UserContext),

        navigate = useNavigate(),

        { current: slipAction } = useRef({ $: 0 }),

        mouseListener = e => {
            const slipRow = e.target.closest('.slip-row');

            if (slipRow) {
                e.preventDefault();
                e.stopPropagation();
            }
        },

        { current: lastSelectedRowIndex } = useRef({ $: undefined });




    useEffect(() => {
        document.addEventListener('click', mouseListener, true);
        return () => document.removeEventListener('click', mouseListener, true);
    }, []);


    useEffect(() => {
        Util.request.get(`slips/${currentOutlet}/${state.selectedDate}`)
            .then(res => {
                if (res.status && pos.canDeleteSlips) {
                    res.data.forEach((o, i) => o.action = <input className='form-check-input' type='checkbox'
                        onChange={e => setSelected(r => ({ ...r, [o.id]: e.target.checked ? o.amount : false }))} />)
                }

                setState(o => ({
                    ...o,
                    gettingSlips: false,
                    gettingSlipsDone: true,
                    gettingSlipsError: !res.status,
                    gettingSlipsText: res.message,

                    slips: res.status ? res.data : state.slips
                }));
            })
        setState(o => ({ ...o, gettingSlips: true }));
    }, [state.selectedDate, slipAction.$]);




    function handleDeleteSlip() {

        const fd = new FormData, keys = Object.keys(selected).filter(k => selected[k] !== false);

        keys.forEach(k => fd.append('slips', k));

        Util.request.post(`deleteslip`, fd)
            .then(res => {
                setState(o => ({
                    ...o,
                    deletingSlip: false,
                    deletingSlipDone: true,
                    deletingSlipError: !res.status,
                    deletingSlipText: res.message,

                    slips: res.status ? state.slips.filter(s => !!!keys.find(k => s.id === Number(k))) : state.slips
                }));
                res.status && setSelected({});
                setTimeout(() => {
                    document.querySelectorAll('[type=checkbox]').forEach(o => o.checked = false)
                }, 0);
            });

        setState(o => ({ ...o, deletingSlip: true }));
    }


    function getSelectionText() {
        const
            s = Object.values(selected).filter(v => v !== false),
            l = s.length;

        return `Delete ${l} item${l === 1 ? '' : 's'}(N${Util.format(s.reduce((a, v) => a + v, 0))})`;
    }

    return (
        <CardLayout
            pageName='POS Slips' pageIcon='receipt'
            cardFooter={Object.keys(selected).some(k => selected[k]) && <div className='text-end'>
                <button
                    className='btn btn-sm btn-outline-danger border-0 fw-bold'
                    data-bs-toggle='modal' data-bs-target='#slip-delete'>
                    <i className="fa fa-trash"></i>
                    {getSelectionText()}
                </button>
            </div>}
            cardHeader={<ActionHeader actions={[{
                showIf: pos.canEnterSlips,
                onClick: () => navigate(Paths.DAILY_SALES.join(Paths.ADD_SLIP).abs.$),
                text: 'Enter new slip',
                icon: 'plus',
            }]} />}>

            <Busy busy={state.gettingSlips} busyText='Retrieving slips...' />

            <Busy busy={state.deletingSlip} busyText='Deleting slip...' />

            <ErrorStatusModal
                show={state.gettingSlipsDone && state.gettingSlipsError}
                onDone={() => setState(o => ({ ...o, gettingSlipsDone: false }))}
                text={state.gettingSlipsText} />


            <ErrorStatusModal
                show={state.deletingSlipDone && state.deletingSlipError}
                onDone={() => setState(o => ({ ...o, deletingSlipDone: false }))}
                text={state.deletingSlipText} />

            <SuccessStatusModal
                show={state.deletingSlipDone && !state.deletingSlipError}
                onDone={() => setState(o => ({ ...o, deletingSlipDone: false }))}
                text={state.deletingSlipText} />


            <ScrollEndTrigger maxHeight='350px'>


                <DatePicker date={state.selectedDate} onDateChange={date => setState(o => ({ ...o, selectedDate: date }))} />

                {pos.canDeleteSlips &&
                    <Modal
                        type='danger'
                        id={'slip-delete'}
                        primary={{ icon: 'trash', text: 'Delete' }}
                        title={`Delete slips(${Object.keys(selected).filter(k => selected[k]).length})`}
                        on={handleDeleteSlip}
                    >
                        <p>Are you sure you want to delete selected slips?</p>
                    </Modal>}

                <Table
                    noDataText={`No slip for date ${state.selectedDate}`}
                    data={state.slips.map(o => {
                        const t = { ...o }
                        t.amount = `N${Util.format(t.amount)}`;
                        return t;
                    })}
                    tableHead={['Date', 'Time', 'User', 'Amount', '']}
                    tableFoot={['Total: N' + (Util.format(state.slips.reduce((s, o) => s + o.amount, 0)))]}
                    padRight
                    keys={['date', 'time', 'attendant', 'amount', 'action']}
                />
            </ScrollEndTrigger>
        </CardLayout>
    );
}

export default Slips;