import React, { useMemo } from 'react';
import Button from './Button';


/**
 * 
 * @param {{actions: Array<{onClick: VoidFunction, icon: string, text: string, showIf: boolean, attrs}>, title: string, centerTitle: boolean}} 
 * @returns 
 */
export default function ActionHeader({ title, actions = [], centerTitle }) {
    actions = useMemo(() => actions.filter(a => !!a.showIf || a.showIf === undefined), []);

    if (actions.length === 0 && !title) return;

    return (
        <div className='d-flex justify-content-between align-items-center'>
            {!!centerTitle && <i></i>}
            
            <h6 className="m-0 text-primary">{title}</h6>

            {
                !!actions.length && <>
                    <div className="d-flex align-items-center d-none d-lg-block">
                        {actions.map((a, i) => <span key={i}>
                            <span className='p-1'> </span>
                            <Button type={'button'} sm outline key={i} onClick={a.onClick} text={a.text} icon={a.icon} attrs={a.attrs} className={''} />
                        </span>)}
                    </div>

                    <div className="btn-group dropleft d-lg-none">
                        <button type="button" className="btn position-relative" style={{ borderRadius: '50%', background: 'transparent', fontSize: '1rem', letterSpacing: '0px', width: '1.6rem', height: '1.6rem', color: '#505050', border: 0, outline: 0 }} data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                            <span className='position-absolute' style={{ left: '50%', top: '50%', transform: 'translate(-50%, -50%) rotate(90deg)', transformOrigin: 'center' }}>&#x2022;&#x2022;&#x2022;</span>
                        </button>
                        <div className="dropdown-menu">
                            {actions.map((o, i) => <button type='button' onClick={o.onClick} key={`action-${i}`} {...(o.attrs || {})} className='dropdown-item'>
                                <i className={`fa fa-${o.icon}`}></i>
                                <span className='ms-2'>{o.text}</span>
                            </button>)}
                        </div>
                    </div>
                </>
            }
        </div>
    )
}
