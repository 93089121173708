import React, { useContext, useEffect, useState } from 'react';
import { useParams } from "react-router-dom";
import Busy from '../components/Busy';
import Button from '../components/Button';
import InputField from '../components/InputField';
import ScrollEndTrigger from '../components/ScrollEndTrigger';
import { ErrorStatusModal, SuccessStatusModal } from '../components/StatusModal';
import CardLayout from '../layouts/CardLayout';
import Util from '../Util';

function Supply(props) {
    const
        [state, setState] = useState({
            supply: null,
            gettingSupply: false,
            gettingSupplyDone: false,
            supplyText: 'Retrieving open supply...',
            gettingSupplyError: false,

            creatingSupply: false,
            creatingSupplyDone: false,
            creatingSupplyText: '',
            creatingSupplyError: false,

            closingSupply: false,
            closingSupplyDone: false,
            closingSupplyText: '',
            closingSupplyError: false,

            submittingSupply: false,
            submittingSupplyError: false,
            submittingSupplyText: '',
            submittingSupplyDone: false,

            errs: {}
        }),

        { user: { outletId, position, supplyTypes } } = useContext(Util.UserContext),

        [type, setType] = useState('New supply'),

        modalDuration = 1700,

        { tankId = 0 } = useParams();



    useEffect(() => {
        Util.request.get(`supply/open/${tankId}`)
            .then(res => {
                setState(o => ({
                    ...o,
                    gettingSupply: false,
                    gettingSupplyDone: true,
                    gettingSupplyError: !res.status,
                    supplyText: res.message,
                    supply: res.status ? res.data : false
                }));
            });

        setState(o => ({ ...o, gettingSupply: true }));

    }, []);


    function handleSubmit(e) {
        e.preventDefault();

        const
            fd = new FormData(e.target),
            defaults = {
                drivers_name: '',
                vehicle_number: '',
                receiver: '',
                quantity_in: 0,
                quantity_out: 0,
            }

        for (const k of Object.keys(defaults))
            fd.get(k) || fd.set(k, defaults[k]);

        Util.request.post(`supply/entries/create/${tankId}`, fd)
            .then(res => {
                setState(o => ({
                    ...o,
                    submittingSupply: false,
                    submittingSupplyError: !res.status,
                    submittingSupplyText: typeof res.message === 'string' ? res.message : 'Operation failed',
                    submittingSupplyDone: true,
                    errs: typeof res.message === 'object' ? res.message : {},

                    supply: res.status ? res.data : state.supply
                }));



            });

        setState(o => ({ ...o, submittingSupply: true }))
    }

    function handleOpenSupply(e) {
        Util.request.post(`supply/create/${tankId}`)
            .then(res => setState(o => ({
                ...o,
                creatingSupply: false,
                creatingSupplyDone: true,
                creatingSupplyText: res.message,
                creatingSupplyError: !res.status,
                supply: res.status ? res.data : false
            })))


        setState(o => ({ ...o, creatingSupply: true }));
    }

    function handleCloseSupply(e) {
        Util.request.post(`supply/close/${tankId}`)
            .then(res => setState(o => ({
                ...o,
                closingSupply: false,
                closingSupplyDone: true,
                closingSupplyError: !res.status,
                closingSupplyText: res.message,
                supply: false
            })))



        setState(o => ({ ...o, closingSupply: true }));
    }

    const closeConsignmentButton = <button type='button' className='btn btn-sm btn-outline-secondary' onClick={handleCloseSupply}>
        <i className='fa fa-times me-2'></i>
        Close this supply
    </button>;


    return (
        <CardLayout pageName='enter supply'
            pageIcon='keyboard'
            cardElement='form'
            cardElementProps={{ method: 'post', onSubmit: handleSubmit }}
            cardHeader={state.supply ? (<div className='d-flex justify-content-between align-items-center'>
                {<h6 className='text-primary m-0'>Supply created on {state.supply.date}</h6>}
                {closeConsignmentButton}
            </div>) : <center>You have no open supply</center>}
            cardFooter={state.supply && <Button icon='save' text='Save supply' />}
        >







            {/* GETTING SUPPLY */}
            <Busy busy={state.gettingSupply} fixed={true} busyText='Checking for open supply...' />


            <ErrorStatusModal
                onDone={() => setState(o => ({ ...o, gettingSupplyDone: false }))}
                show={state.gettingSupplyDone && state.gettingSupplyError} text={state.supplyText}
            />
            {/* END OF GETTING SUPPLY */}



            {/* CLOSING SUPPLY */}
            <Busy busy={state.closingSupply} busyText='Closing supply...' />

            <SuccessStatusModal
                onDone={() => setState(o => ({ ...o, closingSupplyDone: false, supply: false, pumps: false }))}
                show={state.closingSupplyDone && !state.closingSupplyError} text={state.closingSupplyText}
                duration={modalDuration}
            />

            <ErrorStatusModal
                onDone={() => setState(o => ({ ...o, closingSupplyDone: false }))}
                show={state.closingSupplyDone && state.closingSupplyError} text={state.closingSupplyText}
            />
            {/* END OF CLOSING SUPPLY */}






            {/* CREATING SUPPLY */}
            <Busy busy={state.creatingSupply} busyText='Openning a supply...' />

            <SuccessStatusModal
                onDone={() => setState(o => ({ ...o, creatingSupplyDone: false }))}
                show={state.creatingSupplyDone && !state.creatingSupplyError} text={state.creatingSupplyText}
                duration={modalDuration}
            />

            <ErrorStatusModal
                onDone={() => setState(o => ({ ...o, creatingSupplyDone: false }))}
                show={state.creatingSupplyDone && state.creatingSupplyError} text={state.creatingSupplyText}
            />
            {/* END OF CREATING SUPPLY */}







            {/* SUPPLY SUBMISSION */}
            <Busy busy={state.submittingSupply} busyText='Saving supply...' />

            <SuccessStatusModal
                onDone={() => setState(o => ({ ...o, submittingSupplyDone: false }))}
                show={state.submittingSupplyDone && !state.submittingSupplyError} text={state.submittingSupplyText}
                duration={modalDuration}
            />

            <ErrorStatusModal
                onDone={() => setState(o => ({ ...o, submittingSupplyDone: false }))}
                show={state.submittingSupplyDone && state.submittingSupplyError} text={state.submittingSupplyText}
            />
            {/* END OF SUPPLY SUBMISSION */}



            {(function () {
                if (state.supply === false) {
                    return <center>
                        {
                            position.canEnterSupplies ?
                                <button type='button' className='btn btn-primary btn-md' onClick={handleOpenSupply}>
                                    <i className='fa fa-plus me-2'></i>
                                    Open supply
                                </button> :

                                <span className='text-muted'>You don't have the privilege to create supply</span>

                        }
                    </center>
                }
                else if (state.supply) {
                    return (<ScrollEndTrigger maxHeight={'54vh'}>


                        <input type={'hidden'} name='supply' value={state.supply.id}></input>

                        <div className='row'>


                            <div className='col-12 mb-3'>
                                <label className='form-label text-muted' htmlFor='t'>Supply types</label>
                                <select className='form-select' name='supply_type' value={type} onChange={e => setType(e.target.value)}>
                                    {supplyTypes.map((t, i) => <option key={i} value={t}>{t}</option>)}
                                </select>
                            </div>



                            <div className='col-md-6 mb-3'>
                                <InputField labelText="Tank" value={state.supply.tank.tankName} name='tank' attrs={{ disabled: true }} />
                            </div>






                            {type !== 'Return to tank' && <>
                                <div className='col-md-6 mb-3'>
                                    <InputField errs={state.errs} labelText="Driver's name" name="drivers_name" />
                                </div>

                                <div className='col-md-6 mb-3'>
                                    <InputField errs={state.errs} labelText="Vehicle number" name="vehicle_number" />
                                </div>


                                {type !== 'New supply' && <div className='col-md-6 mb-3'>
                                    <InputField errs={state.errs} labelText="Receiver" name="receiver" />
                                </div>}
                            </>}


                            <div className='col-md-6 mb-3'>
                                <InputField errs={state.errs} labelText="Quantity in"
                                    type='number' value='0'
                                    attrs={{ min: 0, step: '.001', max: (state.supply.tank.capacity - state.supply.tank.quantityLeft) }}
                                    name="quantity_in" />
                            </div>


                            {(!['Return to tank', 'New supply'].includes(type)) && <div className='col-md-6 mb-3'>
                                <InputField errs={state.errs} labelText="Quantity out"
                                    type='number' value='0'
                                    attrs={{ min: 0, step: '.001', max: state.supply.tank.quantityLeft }}
                                    name="quantity_out" />
                            </div>}





                        </div>

                    </ScrollEndTrigger>)
                }
            })()}

        </CardLayout>
    );
}

export default Supply;