import React from 'react';


/**
 * 
 * @param {{
 * id: string,
 * on: ()=>void,
 * children,
 * type: "success" | "danger" | "warning",
 * primary: {icon: string, text: string},
 * secondary: {icon: string, text: string},
 * }} 
 */
function Modal({ on, children, primary, secondary, type, title, id }) {

    const o = {
        type: {
            success: { color: 'primary', icon: 'check-circle' },
            danger: { color: 'danger', icon: 'times-circle' },
            warning: { color: 'warning', icon: 'warning' }
        },
        primary: { icon: 'check', text: 'OK' },
        secondary: { text: 'Cancel', icon: 'times' },
    },

        onAction = e => {
            e.preventDefault();
            e.currentTarget.previousSibling.click();
            typeof on === 'function' && on();
        };


    o.type = o.type[type] || o.type.success;
    o.primary = { ...o.primary, ...(primary || {}) }
    o.secondary = { ...o.secondary, ...(secondary || {}) }

    //<i className={`fa fa-${o.primary.icon} me-2`}></i>
    return (
        <div className="modal fade" id={id} data-bs-backdrop="static" data-bs-keyboard="false" tabIndex="-1" aria-hidden="true">
            <div className="modal-dialog">
                <div className="modal-content">
                    <div className="modal-header">
                        <h4 className="modal-title fs-6 ">{title}</h4>
                        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div className="modal-body  text-center">
                        <h1 className={`fs-1 text-${o.type.color}`}>
                            <i className={`fa fa-${o.type.icon}`}></i>
                        </h1>
                        <div>
                            {children}
                        </div>
                    </div>
                    <div className="modal-footer">
                        <button type="button" className="btn btn-outline-secondary btn-sm border-0" data-bs-dismiss="modal">
                            <i className={`fa fa-${o.secondary.icon} me-2`}></i>
                            {o.secondary.text}
                        </button>
                        <button type="button" onClick={onAction} className={`fw-bold btn btn-${o.type.color} btn-sm outline-0 border-0`}>
                            <i className={`fa fa-${o.primary.icon} me-2`}></i>
                            {o.primary.text}
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Modal;