import React, { useState, useEffect, useRef } from 'react';
import Busy from '../components/Busy';
import Button from '../components/Button';
import InputField from '../components/InputField';
import CardLayout from '../layouts/CardLayout';
import Util from '../Util';
import { ErrorStatusModal, SuccessStatusModal } from '../components/StatusModal';
import SelectInput from '../components/SelectInput';
import { useParams } from 'react-router-dom';



function TransferFunds(props) {
    const
        [state, setState] = useState({
            gettingData: false,
            gettingDataDone: false,
            gettingDataMessage: '',
            gettingDataFailed: false,

            makingPurchase: false,
            makingPurchaseDone: false,
            makingPurchaseMessage: '',
            makingPurchaseFailed: false,

            data: { subscriptions: [], customer: {} },

            errs: {}
        }),
        [maxTransfer, _setMaxTransfer] = useState(0),
        [currentFrom, setCurrentFrom] = useState({ subscriptionId: -10 }),
        { customerId = 0 } = useParams(),
        dep = useRef(0);


    function setMaxTransfer({ target }) {
        const selected = state.data.subscriptions
            .find(sub => sub.subscriptionId === Number(target.value));

        _setMaxTransfer(selected.balance || 0);
        setCurrentFrom(selected || { subscriptionId: -10 });
    }


    useEffect(() => {

        Util.request.get(`bundle/makepurchase/${customerId}`)
            .then(res => {
                setState(o => ({
                    ...o,
                    gettingData: false,
                    gettingDataDone: true,
                    gettingDataFailed: !res.status,
                    gettingDataMessage: Util.resolveResponseMessage(res),
                    data: res.status ? res.data : state.data,
                    errs: Util.resolveFormError(res)
                }));
            });

        setState(o => ({ ...o, gettingData: true }));
    }, [dep.current])


    function handleMakePurchase(e) {

        e.preventDefault();

        const fd = new FormData(e.target);
        fd.append('customer', customerId);

        Util.request.post(`subs/transfer`, fd)
            .then(res => {
                res.status && ++dep.current;
                setState(o => ({
                    ...o,
                    makingPurchase: false,
                    makingPurchaseDone: true,
                    makingPurchaseMessage: Util.resolveResponseMessage(res),
                    makingPurchaseFailed: !res.status,

                    errs: Util.resolveFormError(res)
                }))
            });

        setState(o => ({ ...o, makingPurchase: true }));
    }

    return (
        <CardLayout
            cardElement='form'
            cardElementProps={{ onSubmit: handleMakePurchase }}
            pageName="transfer subscription funds"
            pageIcon="exchange"
            cardFooter={<Button text='Transfer' icon='exchange' />}>

            <Busy busy={state.makingPurchase} busyText='Making transfer...' />

            <Busy busy={state.gettingData} />

            <SuccessStatusModal
                show={state.makingPurchaseDone && !state.makingPurchaseFailed}
                onDone={() => setState(o => ({ ...o, makingPurchaseDone: false }))}
                text={state.makingPurchaseMessage} />
            {
                typeof state.makingPurchaseMessage === 'string' &&
                <ErrorStatusModal
                    show={state.makingPurchaseDone && state.makingPurchaseFailed}
                    onDone={() => setState(o => ({ ...o, makingPurchaseDone: false }))}
                    text={state.makingPurchaseMessage} />}


            <div className='row'>

                <div className='col-12  mb-4'>
                    <InputField name='customer' attrs={{ readOnly: true, disabled: true }} labelText='Prepaid customer' value={state.data.customer.name} labelIcon='user' />
                </div>

                <div className='col-12 col-lg-6 mb-4'>
                    <SelectInput labelText='From' name='from' errs={state.errs}
                        defaultOption='Select source subscription'
                        onChange={setMaxTransfer}
                        data={state.data.subscriptions} keys={['subscriptionId', 'name']} />
                </div>

                <div className='col-12 col-lg-6 mb-4'>
                    <SelectInput labelText='To' name='to' errs={state.errs}
                        defaultOption='Select destination subscription'
                        data={state.data.subscriptions.filter(o => o.subscriptionId !== currentFrom.subscriptionId)}
                        keys={['subscriptionId', 'name']} />
                </div>

                <div className='col-12  mb-4'>
                    <InputField name='amount' errs={state.errs} labelText='Amount'
                        type='number' attrs={{ min: .01, max: maxTransfer, defaultValue: 0, step: .01 }}
                        labelIcon='money-bill' />
                </div>

            </div>

        </CardLayout>
    );
}



export default TransferFunds;