import { useRef } from "react";

export default function NumberInput(props) {
    const
        { onNumberInput, ...rest } = props,
        { current: lastNumber } = useRef({ $: 0 }),
        on = e => {
            const n = Number(e.target.value);
            if (n) lastNumber.$ = n;
            onNumberInput(e, lastNumber.$);
        }

    return <input {...rest} onInput={on} />
};

NumberInput.defaultProps = { onNumberInput(e, n) { } };