import React, { useContext, useState } from 'react';
import CardLayout from '../layouts/CardLayout';
import Util from '../Util';
import Busy from '../components/Busy';
import { ErrorStatusModal, SuccessStatusModal } from '../components/StatusModal';
import Button from '../components/Button';
import InputField from '../components/InputField';
import DatetimeFields from '../components/DatetimeFields';
import ActionHeader from '../components/ActionHeader';
import { useQueryState } from '../hooks';


function OverdueSlip(props) {
    const
        state = useQueryState(),
        [date, setDate] = useState(Util.today(true)),
        { currentOutlet = 0 } = useContext(Util.UserContext);




    const handleSubmit = (e) => {
        e.preventDefault();


        let fd = new FormData(e.target);
        fd.append(Util.overdueKey, true);

        Util.request.post(`overdue/${currentOutlet}/slip`, fd)
            .then(res => {
                state.fromResponse(res);
            })

        state.busy()

    };

    const actionButton = <Button icon='upload' text='Upload' />;

    return (
        <CardLayout
            cardElement='form'
            cardElementProps={{ onSubmit: handleSubmit }}

            pageName='upload slip' pageIcon='upload'
            cardFooter={actionButton}
            cardHeader={<ActionHeader title='OVERDUE SLIPS UPLOAD' />}
        >

            <state.Busy />

            <state.ModalSuccess />
            <state.ModalError />


            <div className="mb-3">
                <DatetimeFields date={date} onDateChange={setDate} />
            </div>



            <div className='mb-3'>
                <InputField errs={state.errors} name='amount' labelText='Amount' labelIcon='money' attrs={{ type: 'number', min: 0 }} />
            </div>

        </CardLayout>
    );
}

export default OverdueSlip;