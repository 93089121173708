import React, { useContext, useEffect, useRef, useState } from 'react';
import CardLayout from '../layouts/CardLayout';
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import InputField from '../components/InputField';
import { useQueryState } from '../hooks';
import Util from '../Util';
import SelectInput from '../components/SelectInput';

function PumpModify(props) {

    const

        bundle = useQueryState({}),
        post = useQueryState(undefined),
        { user: { outletId } } = useContext(Util.UserContext),
        { pumpId } = useParams();


    useEffect(() => {

        Util.request.get(`bundle/modify_pump/${outletId}/${pumpId}`)
            .then(({ data }) => bundle.success(null, data))

        bundle.busy();
    }, [])


    const handleSubmit = e => {
        e.preventDefault();

        Util.request.post(`modify_pump/${pumpId}`, new FormData(e.target))
            .then(res => {
                post.fromResponse(res);
                if (res.status) bundle.success('', { ...bundle.state.data, pump: res.data })
            });

        post.busy();
    };

    return (
        <CardLayout
            pageName='Edit pump' pageIcon='edit'
        >

            <bundle.Busy />

            <post.Busy />
            <post.Modal />

            {
                bundle.doneWithSuccess && <>
                    <form method='post' onSubmit={handleSubmit}>

                        <div className='row'>


                            <div className='mb-3 col-12'>
                                <InputField name='#' labelText='Last reading' attrs={{ disabled: true }} value={bundle.state.data.pump.lastMeterReading} />
                            </div>


                            <div className='mb-3 col-12'>
                                <InputField name='unique_name' labelText='Pump name' value={bundle.state.data.pump.uniqueName} />
                            </div>

                            <div className='mb-3 col-12'>
                                <SelectInput labelText={'Tank'} data={bundle.state.data.tanks} defaultOptionValue={bundle.state.data.pump.tank} name={'tank'} keys={['tankId', 'tankName']} />
                            </div>
                        </div>


                        <button className='btn btn-primary'>
                            <i className='fa fa-edit me-2'></i>
                            Edit pump
                        </button>
                    </form>
                </>
            }
        </CardLayout>
    );
}

export default PumpModify;