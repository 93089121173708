import React, { useEffect, useState } from 'react';
import Button from './Button';
import Hint from './Hint';


const range = (start, stop) => Array(Math.abs(stop - start)).fill(1);


function Table({ data, actions = [], tableHead, tableFoot, keys, uniqueKey, noDataText, padRight }) {

    data = data || [];

    let
        row,
        optimumItemsPerPage = 50,
        getAction,
        [pageState, setPageState] = useState({
            currentItemsPerPage: optimumItemsPerPage,
            offset: 0
        });

    if (
        actions && 'object|function'.includes(typeof actions) ||
        Array.isArray(actions)
    ) {
        if (!Array.isArray(actions)) actions = [actions];
        else actions = actions;

        getAction = function (data, i) {
            return actions.map((o, j) => {
                if (typeof o === 'function') o = o(data, pageState.offset + i);

                if (!o || o.hideIf === true) return null;

                return <Hint hint={o.text}>
                    <button data-bs-toggle="tooltip" data-bs-placement="top"
                        // data-bs-custom-className="custom-tooltip"
                        data-bs-title={o.text} onClick={() => typeof o.on === 'function' && o.on(data)} key={`action-${j}`} {...(o.attrs || {})} className={`btn btn-sm btn-outline-secondary me-1 ${o.cls || ''}`}>
                        {o.icon && <i className={`fa fa-${o.icon}`}></i>}
                    </button>
                </Hint>
            })
        }
    } else getAction = () => [];

    return (
        <div className='d-flex flex-column overflow-hidden'>
            <div className='row'>
                <div className='col-12 col-md-3 d-md-flex align-items-center mb-2'>
                    <label className='form-label text-muted m-0 me-2' style={{ whiteSpace: 'nowrap' }}>Per page</label>
                    <select className='form-select form-select-sm' onChange={(e) => setPageState(o => ({ offset: 0, currentItemsPerPage: Number(e.target.value) }))}>
                        {range(0, 10).map(($, i) => <option key={i}>{optimumItemsPerPage * i + optimumItemsPerPage}</option>)}
                    </select>
                </div>
            </div>
            <div className='' style={{ overflow: 'auto' }}>
                <table className='table table-striped fg-color'>
                    <thead className='text-primary'>
                        {Array.isArray(tableHead) && <tr><th></th>{tableHead.map((v, i) => <th key={`table-head-${i}`}>{v}</th>)}{actions.length > 0 && <th>Actions</th>}</tr>}
                    </thead>
                    <tbody>
                        {data.length ? data.slice(pageState.offset, pageState.currentItemsPerPage + pageState.offset).map((item, i) => {

                            row = [<th key={`item-${i}`}>{i + 1 + pageState.offset}</th>];

                            if (item && typeof item === 'object') {

                                keys = Array.isArray(keys) ? keys : Object.keys(item);

                                row.push(...keys.map((key, keyIndex) => {
                                    let _item = item, keys = key.split(/\s*\.\s*/);
                                    if (keys.length === 1) _item = item[key];
                                    else while (_item[keys[0]]) _item = _item[keys.shift()];

                                    // if the first property of the object is undefined, _key becomes the
                                    // original object and can't be rendered, so it is set to null;
                                    if (typeof _item === 'object' && !React.isValidElement(_item)) _item = null;

                                    return <td key={uniqueKey ? item[uniqueKey] : `item-${i + keyIndex + 1}`}>{_item}</td>
                                }));

                                row.push(<td key='action'>
                                    <span className='d-none d-lg-inline-block'>{getAction(item, i)}</span>
                                    <div className="btn-group dropleft d-lg-none">
                                        <button type="button" className="btn position-relative" style={{ borderRadius: '50%', background: 'transparent', fontSize: '1rem', letterSpacing: '0px', width: '1.6rem', height: '1.6rem', color: '#505050', border: 0, outline: 0 }} data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                            <span className='position-absolute' style={{ left: '50%', top: '50%', transform: 'translate(-50%, -50%) rotate(90deg)', transformOrigin: 'center' }}>&#x2022;&#x2022;&#x2022;</span>
                                        </button>
                                        <div className="dropdown-menu">
                                            {actions.map((o, j) => {
                                                if (typeof o === 'function') o = o(item, pageState.offset + i);

                                                if (!o || o.hideIf === true) return null;

                                                return <button type='button' onClick={() => typeof o.on === 'function' && o.on(item)} key={`action-${j}`} {...(o.attrs || {})} className='dropdown-item'>
                                                    {o.icon && <i className={`fa fa-${o.icon}`}></i>}
                                                    {o.text && <span className='ms-2'>{o.text}</span>}
                                                </button>
                                            })}
                                        </div>
                                    </div>


                                </td>)

                                return <tr key={uniqueKey ? item[uniqueKey] : `row-${i}`}>{row}</tr>;
                            }
                        }) : <tr><td colSpan={9999999} className='text-center text-muted'>{noDataText && noDataText.toString() || 'Table is empty'}</td></tr>}
                    </tbody>
                    {Array.isArray(tableFoot) && <tfoot><tr>
                        <th></th>
                        {(function () {
                            if (tableHead.length) {
                                padRight = !!padRight;
                                let
                                    thead = tableHead,
                                    tfoot = tableFoot.slice(0, thead.length),
                                    arrayTH = tfoot.map((tf, i) => <th key={i}>{tf}</th>),
                                    thPad;


                                if (tfoot.length < thead.length) {
                                    thPad = <th key={'array-th-pad'} colSpan={thead.length - tfoot.length}></th>;
                                    padRight ? arrayTH.unshift(thPad) : arrayTH.push(thPad)
                                }

                                return arrayTH;
                            }
                            return false;
                        })()}
                    </tr></tfoot>}
                </table>
            </div>
            <div className='d-lg-flex align-items-center justify-content-lg-between'>
                <p className='small text-muted'>
                    Showing {pageState.offset + 1} - {Math.min(data.length, pageState.offset + pageState.currentItemsPerPage)} of {data.length}
                </p>

                <div className='rounded border position-relative p-1 d-flex align-items-center table-page-buttons'>
                    <Button icon='arrow-left' onClick={() => pageState.offset >= pageState.currentItemsPerPage && setPageState(o => ({ ...o, offset: pageState.offset - pageState.currentItemsPerPage }))} />
                    <small className='mx-3 small text-muted'>Page {((pageState.offset / Math.min(pageState.currentItemsPerPage, data.length)) | 0) + 1}</small>
                    <Button icon='arrow-right' onClick={() => pageState.offset < data.length - pageState.currentItemsPerPage && setPageState(o => ({ ...o, offset: pageState.offset + pageState.currentItemsPerPage }))} />
                </div>
            </div>
        </div>
    );
}

export default Table;