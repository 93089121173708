
import React, { useContext, useEffect, useState } from 'react';
import CardLayout from '../layouts/CardLayout';
import Util from '../Util';
import Busy from '../components/Busy';
import { ErrorStatusModal, SuccessStatusModal } from '../components/StatusModal';
import Button from '../components/Button';
import InputField from '../components/InputField';
import DatetimeFields from '../components/DatetimeFields';
import SelectInput from '../components/SelectInput';
import ActionHeader from '../components/ActionHeader';


export default function OverdueCashbook(props) {
    const
        [state, setState] = useState({

            uploadingTeller: false,
            uploadingTellerDone: false,
            uploadingTellerError: false,
            uploadingTellerText: '',
            errors: {}
        }),
        [date, setDate] = useState(Util.today(true)),
        { currentOutlet = 0 } = useContext(Util.UserContext);




    const handleSubmit = (e) => {
        e.preventDefault();


        let fd = new FormData(e.target);
        fd.append(Util.overdueKey, true);

        Util.request.post(`overdue/${currentOutlet}/cashbook`, fd)
            .then(res => {
                setState(o => ({
                    ...o,
                    uploadingTeller: false,
                    uploadingTellerDone: true,
                    uploadingTellerError: !res.status,
                    uploadingTellerText: typeof res.message === 'string' ? res.message : '',
                    errors: typeof res.message === 'string' ? {} : res.message
                }))
            })

        setState(o => ({ ...o, uploadingTeller: true }))

    };

    const actionButton = <Button icon='upload' text='Upload' />;

    return (
        <CardLayout
            cardElement='form'
            cardElementProps={{ onSubmit: handleSubmit }}

            pageName='upload CASHBOOK' pageIcon='upload'
            cardFooter={actionButton}
            cardHeader={<ActionHeader title='OVERDUE CASHBOOK UPLOAD' />}
        >

            <Busy busy={state.uploadingTeller} busyText='Please wait...' />

            <ErrorStatusModal
                show={state.uploadingTellerDone && state.uploadingTellerError}
                onDone={() => setState(o => ({ ...o, uploadingTellerDone: false }))}
                text={state.uploadingTellerText} />

            <SuccessStatusModal
                show={state.uploadingTellerDone && !state.uploadingTellerError}
                onDone={() => setState(o => ({ ...o, uploadingTellerDone: false }))}
                text={state.uploadingTellerText} />


            <div className="mb-3">
                <DatetimeFields date={date} onDateChange={setDate} />
            </div>

            <div className="mb-3">
                <SelectInput
                    labelText='Entry type'
                    errs={state.errors}
                    name='type'
                    defaultOption='Select type'
                    data={Util.CASHBOOK_TYPES.map(v => [v.name])} keys={[0, 0]} />
            </div>

            <div className="row">
                <div className='mb-3 col-md-6'>
                    <InputField errs={state.errors} name='subhead' labelText='Subhead' labelIcon='pen' />
                </div>


                <div className='mb-3 col-md-6'>
                    <InputField errs={state.errors} name='amount' type='number' attrs={{ min: .01, step: .01 }} labelText='Amount' labelIcon='money-bill' />
                </div>
            </div>


        </CardLayout>
    );
}