import React from 'react';

function Button({ outline, secondary, sm, onClick, type, attrs, icon, text,  }) {

    return (
        <button
            className={`btn btn-${!!outline ? 'outline-' : ''}${!!secondary ? 'secondary' : 'primary'} ${!!sm ? 'btn-sm' : ''}`}
            onClick={e => onClick(e)}
            type={type} 
            {...attrs}>
            <i className={`fa fa-${icon}`}></i>
            {text && <span className='ms-2'>{text}</span>}
        </button>
    );
}

Button.defaultProps = {
    attrs: {},
    type: 'submit',
    onClick: e => 0,
    icon: false,
    text: false,
}

export default Button;