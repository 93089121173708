import React, { useContext, useEffect, useState } from 'react';
import CardLayout from '../layouts/CardLayout';
import Util from '../Util';
import Busy from '../components/Busy';
import ScrollEndTrigger from '../components/ScrollEndTrigger';
import { ErrorStatusModal, SuccessStatusModal } from '../components/StatusModal';
import InputField from '../components/InputField';
import { useNavigate, useParams } from 'react-router-dom';
import Modal from '../components/Modal';
import ActionHeader from '../components/ActionHeader';


function ViewStaff(props) {
    const
        [state, setState] = useState({
            modifyingUserFailed: false,
            modifyingUserDone: false,
            modifyingUserText: '',
            modifyingUser: false,

            gettingUserData: false,
            gettingUserDataDone: false,
            gettingUserDataFailed: false,
            gettingUserDataText: '',

            dismissingUser: false,
            dismissingUserDone: false,
            dismissingUserFailed: false,
            dismissingUserText: '',


            user: null,
            positions: null
        }),
        [formErrors, setFormErrors] = useState({}),
        { user: { position, username: loggedInUsername } } = useContext(Util.UserContext),
        { username } = useParams(),
        navigate = useNavigate(),
        staffCanBeDismissed = position.canDismissUsers && loggedInUsername !== username;


    useEffect(() => {

        if (state.positions !== null || state.gettingUserData) return;

        Util.request.get('pos')
            .then(res => {
                if (!res.status) throw res.message;

                res.data = res.data.filter(pos => pos.level < position.level)

                return res;

            }).then(async posResponse => {
                await Util.request.get(`users/get/${username}`)
                    .then(userResponse => {

                        if (!userResponse.status) throw userResponse.message;

                        setState(o => ({
                            ...o,
                            gettingUserData: false,
                            gettingUserDataDone: true,
                            gettingUserDataFailed: false,
                            gettingUserDataText: 'Successful',

                            positions: posResponse.data,
                            user: userResponse.data
                        }))
                    }).catch(msg => setState(o => ({
                        ...o,
                        gettingUserData: false,
                        gettingUserDataDone: true,
                        gettingUserDataFailed: true,
                        gettingUserDataText: msg
                    })))
            });

        setState(o => ({ ...o, gettingUserData: true }))
    }, []);


    const handleModifyUser = (e) => {
        e.preventDefault();

        Util.request.post(`user/edit/${username}`, new FormData(e.target))
            .then(res => {
                if (res.status) {
                    setState(o => ({ ...o, modifyingUserFailed: false, modifyingUserDone: true, modifyingUserText: res.message, modifyingUser: false }));
                    setFormErrors({});
                }
                else {
                    setState(o => ({
                        ...o,
                        modifyingUserFailed: true,
                        modifyingUserDone: true,
                        modifyingUserText: typeof res.message === 'string' ? res.message : 'Failed', modifyingUser: false
                    }));
                    if (typeof res.message !== 'string')
                        setFormErrors(res.message);
                }
            });

        setState(o => ({ ...o, modifyingUser: true }));
    };


    const handleDismissUser = () => {

        Util.request.post(`users/dismiss/${username}`)
            .then(res => {
                setState(o => ({
                    ...o,
                    dismissingUser: false,
                    dismissingUserDone: true,
                    dismissingUserFailed: !res.status,
                    dismissingUserText: res.message,
                }));

                if (res.status)
                    setTimeout(() => {
                        navigate(-1);
                    }, 1030);
            });

        setState(o => ({ ...o, dismissingUser: true }));
    };

    const actionButton = <button type='submit' className='btn btn-primary'>
        <i className='fa fa-edit me-2'></i>
        <span>Modify staff</span>
    </button>;

    return (
        <CardLayout cardElement='form' cardElementProps={{ method: 'post', onSubmit: handleModifyUser }}
            pageName='Modify staff' pageIcon='edit' cardFooter={position.canCreateUsers && actionButton}
            cardHeader={<ActionHeader actions={[{
                showIf: staffCanBeDismissed,
                text: 'Dismiss user',
                icon: 'user-minus',
                attrs: { 'data-bs-toggle': 'modal', 'data-bs-target': '#dismiss-user' }
            }]} />}
        >

            {
                staffCanBeDismissed &&
                <Modal on={handleDismissUser} id='dismiss-user' primary={{ text: 'Dismiss' }} type='danger' title='Dismiss user'>
                    Are you sure you want to dismiss this user?<br />This action can not be undone.<br />
                    Proceed?
                </Modal>
            }



            <Busy busy={state.modifyingUser} busyText='Creating user...' />

            <Busy busy={state.dismissingUser} busyText='Dismissing user...' />

            <Busy busy={state.gettingUserData} busyText='Retrieving user data...' />

            <SuccessStatusModal
                show={state.modifyingUserDone && !state.modifyingUserFailed}
                onDone={() => setState(o => ({ ...o, modifyingUserDone: false }))}
                text={state.modifyingUserText} />




            <ErrorStatusModal
                show={state.gettingUserDataDone && state.gettingUserDataFailed}
                onDone={() => setState(o => ({ ...o, gettingUserDataDone: false }))}
                text={state.gettingUserDataText} />




            <SuccessStatusModal
                show={state.dismissingUserDone && !state.dismissingUserFailed}
                onDone={() => setState(o => ({ ...o, dismissingUserDone: false }))}
                text={state.dismissingUserText} duration={1000} />



            <ErrorStatusModal
                show={state.dismissingUserDone && state.dismissingUserFailed}
                onDone={() => setState(o => ({ ...o, dismissingUserDone: false }))}
                text={state.dismissingUserText} />




            <ScrollEndTrigger maxHeight='390px'>
                {
                    Array.isArray(state.positions) && <>
                        <div className='col-12'>
                            <div className='mb-3'>
                                <label className='form-label' htmlFor='position'>Position({state.user.position.name})</label>
                                <select className='form-select' name='position' defaultValue={state.user.position.id}>
                                    {state.positions.map(pos => <option key={pos.id} value={pos.id}>{pos.name}</option>)}
                                </select>
                            </div>
                        </div>

                        <div className='row'>


                            <div className='col-md-6'>
                                <div className='mb-3'>
                                    <InputField name='first_name' labelText='First name' errs={formErrors} value={state.user.firstname} />
                                </div>
                            </div>


                            <div className='col-md-6'>
                                <div className='mb-3'>
                                    <InputField name='last_name' labelText='Last name' errs={formErrors} value={state.user.lastname} />
                                </div>
                            </div>

                            {/* ------------------------------------------------------------------------------------------- */}



                            <div className='col-md-6'>
                                <div className='mb-3'>
                                    <InputField name='date_of_birth' value={state.user.dateOfBirth} labelText='Date of birth' attrs={{ type: 'date' }} errs={formErrors} />
                                </div>
                            </div>


                            <div className='col-md-6'>
                                <div className='mb-3'>
                                    <label className='form-label' htmlFor='gender'>Gender</label>
                                    <select name='gender' id='gender' defaultValue={state.user.gender} className='form-select'>
                                        <option value='Male'>Male</option>
                                        <option value='Female'>Female</option>
                                    </select>
                                </div>
                            </div>




                            <div className='col-md-6'>
                                <div className='mb-3'>
                                    <InputField value={state.user.stateOfOrigin} name='state_of_origin' labelText='State of origin' errs={formErrors} />
                                </div>
                            </div>





                            <div className='col-md-6'>
                                <div className='mb-3'>
                                    <InputField value={state.user.lgaOfOrigin} name='lga_of_origin' labelText='LGA of origin' errs={formErrors} />
                                </div>
                            </div>




                            <div className='col-md-6'>
                                <div className='mb-3'>
                                    <InputField value={state.user.phone} name='phone' labelText='Phone' errs={formErrors} />
                                </div>
                            </div>




                            <div className='col-md-6'>
                                <div className='mb-3'>
                                    <InputField value={state.user.email} name='email' labelText='Email' attrs={{ type: 'email' }} errs={formErrors} />
                                </div>
                            </div>











                            <div className='col-md-12'>
                                <div className='mb-3'>
                                    <InputField value={state.user.residentialAddr} name='residential_addr' labelText='Residential Address' errs={formErrors} />
                                </div>
                            </div>





                            <div className='col-12 mb-3'>
                                <div className='d-flex align-items-center'>
                                    <hr className='flex-grow-1' />
                                    <p className='m-0 mx-1 text-muted'>Login</p>
                                    <hr className='flex-grow-1' />
                                </div>
                            </div>






                            <div className='col-12'>
                                <div className='mb-3'>
                                    <InputField value={state.user.username} name='username' attrs={{ autoComplete: 'off', readOnly: true }} labelText='Username' errs={formErrors} />
                                </div>
                            </div>



                            {/* 
                            <div className='col-md-6'>
                                <div className='mb-3'>
                                    <InputField name='password' labelText='Password' attrs={{ type: 'password', autoComplete: 'new-password' }} errs={formErrors} />
                                </div>
                            </div>




                            <div className='col-md-6'>
                                <div className='mb-3'>
                                    <InputField name='confirm_password' labelText='Confirm password' attrs={{ type: 'password' }} errs={formErrors} />
                                </div>
                            </div> */}




                            {/* ------------------------------------------------------------------------------------------- */}














                        </div></>
                }
            </ScrollEndTrigger>


        </CardLayout>
    );
}


export default ViewStaff;