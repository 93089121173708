import React, { useContext, useEffect, useLayoutEffect, useState } from 'react';
import { useParams, useNavigate, useSearchParams } from 'react-router-dom';
import CardLayout from '../layouts/CardLayout';
import Util from '../Util';
import Busy from '../components/Busy';
import ScrollEndTrigger from '../components/ScrollEndTrigger';
import { ErrorStatusModal, SuccessStatusModal } from '../components/StatusModal';
import Paths from '../Paths';
import Button from '../components/Button';
import InputField from '../components/InputField';
import SelectInput from '../components/SelectInput';

function Outlet(props) {
    const
        [state, setState] = useState({
            gettingData: false,
            gettingDataText: '',
            gettingDataError: false,
            gettingDataDone: false,

            edittingOutlet: false,
            edittingOutletError: false,
            edittingOutletDone: false,
            edittingOutletText: '',
            outlet: null,

            managers: null,
            errs: {}
        }),

        [param] = useSearchParams(),

        context = useContext(Util.UserContext),

        navigate = useNavigate();

    let modalDuration = 3000, isOwnOutlet = false;


    useLayoutEffect(() => {
        if (!param.get('id')) navigate(-1);
    })


    useEffect(() => {
        Util.request.get(`bundle/modify_outlet/${param.get('id')}`)
            .then(res => {
                setState(o => ({
                    ...o,
                    gettingData: false,
                    gettingDataDone: true,
                    gettingDataError: !res.status,
                    gettingDataText: res.message,
                    outlet: res.data.outlet,
                    managers: res.data.managers
                }))
            });
        setState(o => ({ ...o, gettingData: true }));
    }, []);



    const handleSubmit = (e) => {
        e.preventDefault();

        Util.request.post(`outletmodify/${param.get('id')}`, new FormData(e.target))
            .then(res => {

                setState(o => ({
                    ...o,
                    edittingOutletError: !res.status,
                    edittingOutletDone: true,
                    edittingOutletText: Util.resolveResponseMessage(res),
                    edittingOutlet: false,
                    errs: Util.resolveFormError(res)
                }))

            });

        setState(o => ({ ...o, edittingOutlet: true }));
    };

    return (
        <CardLayout cardElement='form' cardElementProps={{ method: 'post', onSubmit: handleSubmit }}
            pageName='Outlet' pageIcon='house'
            cardFooter={<Button icon='edit' text='Modify outlet' />}>

            <Busy busy={state.gettingData} />

            <Busy busy={state.edittingOutlet} busyText='Modifying outlet...' />


            <SuccessStatusModal
                onDone={() => setState(o => ({ ...o, edittingOutletDone: false }))}
                duration={modalDuration}
                show={state.edittingOutletDone && !state.edittingOutletError}
                text={state.edittingOutletText} />


            <ErrorStatusModal
                onDone={() => setState(o => ({ ...o, edittingOutletDone: false }))}
                duration={modalDuration}
                show={state.edittingOutletDone && state.edittingOutletError}
                text={state.edittingOutletText} />


            <ErrorStatusModal
                onDone={() => setState(o => ({ ...o, gettingDataDone: false }))}
                duration={modalDuration}
                show={state.gettingDataDone && state.gettingDataError}
                text={state.gettingDataText} />



            <ScrollEndTrigger maxHeight='60vh'>

                {state.outlet && <>

                    <div className='mb-3'>

                        <SelectInput
                            keys={['username', 'fullnames']}
                            name='manager'
                            data={state.managers}
                            errs={state.errs}
                            labelText='Manager'
                            defaultOption='Select manager for this outlet.'
                            defaultValue={state.outlet.manager ? state.outlet.manager.username : undefined} />
                    </div>


                    <div className='mb-3'>
                        <InputField name='outlet_name' attrs={{ defaultValue: state.outlet.outletName }} labelText='Outlet name' errs={state.errs} />
                    </div>



                    <div className='mb-3'>
                        <InputField name='location' attrs={{ defaultValue: state.outlet.location }} labelText='Location' errs={state.errs} />
                    </div>



                </>}
            </ScrollEndTrigger>




        </CardLayout>
    );
}

export default Outlet;