import React, { useState } from 'react';
import { NavLink } from 'react-router-dom';

function NavPopup(props) {
    const [hover, setHover] = useState(false)
    return (
        <button
            className='px-4 px-md-3 position-relative d-flex align-items-center justify-content-between nav-toggle p-3 flex-column w-100 mb-1 text-white'
            onMouseEnter={() => setHover(true)}
            onMouseLeave={() => setHover(false)}
        >

            <i className={`fa fa-${props.icon || ''}`}></i>

            <span className='d-none d-md-inline'>{props.text}</span>

            <i className='fa fa-play'></i>

            {hover && <ul className='list-unstyled nav-links'>{
                props.paths.map((o, i) => <li key={`nav-${i}`}>{
                    (o.visible || (typeof o.visible === 'boolean'?o.visible:true))   && 
                    <NavLink className='d-block text-decoration-none text-white p-3 py-2 text-start hover-primary-light' to={o.path}>
                        {o.icon && <i className={`fa fa-${o.icon} me-2`}></i>}
                        <span>{o.text}</span>
                    </NavLink>
                }</li>)
            }</ul>}
        </button>
    );
}

export default NavPopup;