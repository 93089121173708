import React, { useContext, useEffect, useState } from 'react';
import Busy from '../components/Busy';
import Button from '../components/Button';
import ScrollEndTrigger from '../components/ScrollEndTrigger';
import { ErrorStatusModal, SuccessStatusModal } from '../components/StatusModal';
import Table from '../components/Table';
import CardLayout from '../layouts/CardLayout';
import Util from '../Util';

function Stock(props) {

    const
        [state, setState] = useState({
            savingStock: false,
            savingStockError: false,
            savingStockDone: false,
            savingStockText: '',

            tanks: null,
            gettingTanks: false,
            gettingTanksDone: false,
            gettingTanksText: '',
            gettingTanksError: false
        }),

        { user: { outletId } } = useContext(Util.UserContext),

        modalDuration = 1700,

        onModalDone = () => setState(o => ({ ...o, savingStockDone: false })),

        outletProductsKeys = Util.outletProductsKeys,

        handleSubmit = e => {
            e.preventDefault();

            Util.request.post('addstock', new FormData(e.target))
                .then(res => {
                    setState(o => ({
                        ...o,
                        savingStock: false,
                        savingStockError: !res.status,
                        savingStockDone: true,
                        savingStockText: typeof res.message === 'string'? res.message : 'Operation failed',
                    }))
                });

            setState(o => ({ ...o, savingStock: true }));
        };


    useEffect(() => {
        if (state.tanks !== null) return;

        Util.request.get(`gettanks/${outletId}`)
            .then(res => {
                setState(o => ({
                    ...o,
                    tanks: res.status ? res.data : false,
                    gettingTanks: false,
                    gettingTanksDone: true,
                    gettingTanksText: res.message,
                    gettingTanksError: !res.status
                }))

            })


        setState(o => ({ ...o, gettingTanks: true }));
    }, []);


    return (
        <CardLayout cardFooter={<Button icon="save" text='Save stock entry' />}
            cardElement='form' cardElementProps={{ method: 'post', onSubmit: handleSubmit }} pageName='TAKE STOCK' pageIcon='calculator'>


            <Busy busy={state.savingStock} busyText='Saving stock...' />

            <Busy busy={state.gettingTanks} busyText='Retreiving tanks...' />

            <SuccessStatusModal
                onDone={onModalDone}
                show={state.savingStockDone && !state.savingStockError}
                text={state.savingStockText}
                duration={modalDuration}
            />

            <ErrorStatusModal
                onDone={onModalDone}
                show={state.savingStockDone && state.savingStockError}
                text={state.savingStockText}
            />

            <ErrorStatusModal
                onDone={() => setState(o => ({ ...o, gettingTanksDone: false }))}
                show={state.gettingTanksDone && state.gettingTanksError}
                text={state.gettingTanksText}
            />

            <ScrollEndTrigger maxHeight={'60vh'}>
                {state.tanks && state.tanks.length && <table className='table table-border'>
                    <thead>
                        <tr>
                            <th></th>
                            <th>Tank name</th>
                            <th>Quantity left</th>
                            <th>Tank capacity</th>
                            <th>Tank type</th>
                            <th className='text-end'>Amount</th>
                        </tr>
                    </thead>
                    <tbody>
                        {state.tanks.sort((t1, t2) => t1.tankType.name > t2.tankType.name ? -1 : 0).map((t, i) => <tr key={`row-${i}`}>
                            <th>{i + 1}</th>
                            <td>{t.tankName}</td>
                            <td>{t.quantityLeft}</td>
                            <td>{t.capacity}</td>
                            <td>{t.tankType.name}</td>
                            <th className='text-end'><input
                                className='form-control text-end d-inline-block' type='number'
                                min={0} defaultValue={0} name={t.tankId}
                                style={{ maxWidth: '150px' }}
                            /></th>
                        </tr>)}
                    </tbody>
                </table>}
            </ScrollEndTrigger>

        </CardLayout>
    );
}

export default Stock;