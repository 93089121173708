import React, { useContext, useEffect, useRef, useState } from 'react';
import Busy from '../components/Busy';
import ScrollEndTrigger from '../components/ScrollEndTrigger';
import { ErrorStatusModal, SuccessStatusModal } from '../components/StatusModal';
import Table from '../components/Table';
import CardLayout from '../layouts/CardLayout';
import Util from '../Util';
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import Paths from '../Paths';
import Button from '../components/Button';
import InputField from '../components/InputField';

function ProductTypesView(props) {

    const
        [state, setState] = useState({

            modifying: false,
            modifyingDone: false,
            modifyingError: false,
            modifyingText: '',
            errs: {},

            productType: {}
        }),

        context = useContext(Util.UserContext),
        { id: id } = useParams(),
        navigate = useNavigate();


    useEffect(() => {
        if (id === undefined) return navigate(-1);

        let pt = context.user.productTypes.filter(t => t.id === Number(id));

        if (pt.length === 0) return navigate(-1);

        setState(o => ({ ...o, productType: pt[0] }));

    }, []);



    const handleSubmit = e => {
        e.preventDefault();

        Util.request.post(`ptypes/${id}/edit`, new FormData(e.target))
            .then(res => {
                if (res.status) {
                    context.setProductTypes(res.data);
                }

                setState(o => ({
                    ...o,
                    modifying: false,
                    modifyingError: !res.status,
                    modifyingDone: true,
                    modifyingText: typeof res.message === 'string' ? res.message : '',
                    errs: typeof res.message === 'string' ? {} : res.message
                }))
            });



        setState(o => ({ ...o, modifying: true }));
    };

    return (
        <CardLayout
            pageName='Modify product type' pageIcon='edit'
            cardFooter={<Button text='Modify' icon='edit' />}
            cardElement='form'
            cardElementProps={{ onSubmit: handleSubmit }}
        >

            <Busy busy={state.modifying} busyText='Modifying product type...' />

            <SuccessStatusModal
                onDone={() => setState(o => ({ ...o, modifyingDone: false }))}
                show={state.modifyingDone && !state.modifyingError} text={state.modifyingText} />





            <div className='mb-4'>
                <InputField name='name' errs={state.errs} labelText='Type name' value={state.productType.name} />
            </div>

            <div className='mb-4'>
                <InputField
                    name='price_per_litre' errs={state.errs}
                    labelText='Price per litre'
                    attrs={{
                        type: 'number', min: 0,
                        defaultValue:  state.productType.pricePerLitre, step: .01
                    }}
                />
            </div>


        </CardLayout>
    );
}


export default ProductTypesView;