import React, { useContext, useEffect, useState } from 'react';
import CardLayout from '../layouts/CardLayout';
import Util from '../Util';
import Busy from '../components/Busy';
import ScrollEndTrigger from '../components/ScrollEndTrigger';
import { ErrorStatusModal, SuccessStatusModal } from '../components/StatusModal';
import InputField from '../components/InputField';


function CreateStaff(props) {
    const
        [state, setState] = useState({
            creatingStaffFailed: false,
            creatingStaffDone: false,
            creatingStaffText: '',
            creatingStaff: false,

            gettingPositions: false,
            gettingPositionsDone: false,
            gettingPositionsText: '',
            gettingPositionsError: false,
            positions: null
        }),
        [formErrors, setFormErrors] = useState({}),
        { user: { position: { level } } } = useContext(Util.UserContext);


    useEffect(() => {
        if (state.positions !== null) return;

        Util.request.get('pos')
            .then(res => {
                let data = false;
                if (res.status) data = Util.filterPositions(level, res.data); 
                setState(o => ({
                    ...o,
                    gettingPositions: false,
                    positions: data,
                    gettingPositionsDone: true,
                    gettingPositionsText: res.message,
                    gettingPositionsError: !res.status
                }))
            })

        setState(o => ({ ...o, gettingPositions: true }))
    }, []);


    const handleCreateStaff = (e) => {
        e.preventDefault();

        Util.request.post('staff/create', new FormData(e.target))
            .then(res => {
                if (res.status) {
                    setState(o => ({ ...o, creatingStaffFailed: false, creatingStaffDone: true, creatingStaffText: res.message, creatingStaff: false }));
                    setFormErrors({});
                }
                else {
                    setState(o => ({
                        ...o,
                        creatingStaffFailed: true,
                        creatingStaffDone: true,
                        creatingStaffText: typeof res.message === 'string' ? res.message : 'Failed', creatingStaff: false
                    }));
                    if (typeof res.message !== 'string')
                        setFormErrors(res.message);
                }
            });

        setState(o => ({ ...o, creatingStaff: true }));
    };

    const actionButton = <button type='submit' className='btn btn-primary'>
        <i className='fa fa-send me-2'></i>
        <span>Create user</span>
    </button>;

    return (
        <CardLayout cardElement='form' cardElementProps={{ method: 'post', onSubmit: handleCreateStaff }}
            pageName='Create staff' pageIcon='user-plus' cardFooter={actionButton}>



            <Busy busy={state.creatingStaff} busyText='Creating user...' />



            <Busy busy={state.gettingPositions} busyText='Please wait...' />



            <SuccessStatusModal
                show={state.creatingStaffDone && !state.creatingStaffFailed}
                onDone={() => setState(o => ({ ...o, creatingStaffDone: false }))}
                text={state.creatingStaffText} />





            <ErrorStatusModal
                show={state.gettingPositionsDone && state.gettingPositionsError}
                onDone={() => setState(o => ({ ...o, gettingPositionsDone: false }))}
                text={state.gettingPositionsText} />




            <ScrollEndTrigger maxHeight='390px'>
                {
                    Array.isArray(state.positions) && <>
                        <div className='col-12'>
                            <div className='mb-3'>
                                <label className='form-label' htmlFor='position'>Position</label>
                                <select className='form-select' name='position'>
                                    {state.positions.map(pos => <option key={pos.id} value={pos.id}>{pos.name}</option>)}
                                </select>
                            </div>
                        </div>

                        <div className='row'>


                            <div className='col-md-6'>
                                <div className='mb-3'>
                                    <InputField name='first_name' labelText='First name' errs={formErrors} />
                                </div>
                            </div>


                            <div className='col-md-6'>
                                <div className='mb-3'>
                                    <InputField name='last_name' labelText='Last name' errs={formErrors} />
                                </div>
                            </div>

                            {/* ------------------------------------------------------------------------------------------- */}



                            <div className='col-md-6'>
                                <div className='mb-3'>
                                    <InputField name='date_of_birth' labelText='Date of birth' attrs={{ type: 'date' }} errs={formErrors} />
                                </div>
                            </div>


                            <div className='col-md-6'>
                                <div className='mb-3'>
                                    <label className='form-label' htmlFor='gender'>Gender</label>
                                    <select name='gender' id='gender' className='form-select'>
                                        <option value='Male'>Male</option>
                                        <option value='Female'>Female</option>
                                    </select>
                                </div>
                            </div>




                            <div className='col-md-6'>
                                <div className='mb-3'>
                                    <InputField name='state_of_origin' labelText='State of origin' errs={formErrors} />
                                </div>
                            </div>





                            <div className='col-md-6'>
                                <div className='mb-3'>
                                    <InputField name='lga_of_origin' labelText='LGA of origin' errs={formErrors} />
                                </div>
                            </div>




                            <div className='col-md-6'>
                                <div className='mb-3'>
                                    <InputField name='phone' labelText='Phone' errs={formErrors} />
                                </div>
                            </div>




                            <div className='col-md-6'>
                                <div className='mb-3'>
                                    <InputField name='email' labelText='Email' attrs={{ type: 'email' }} errs={formErrors} />
                                </div>
                            </div>




                            <div className='col-md-12'>
                                <div className='mb-3'>
                                    <InputField name='residential_addr' labelText='Residential Address' errs={formErrors} />
                                </div>
                            </div>











                            <div className='col-12 mb-3'>
                                <div className='d-flex align-items-center'>
                                    <hr className='flex-grow-1' />
                                    <p className='m-0 mx-1 text-muted'>Login</p>
                                    <hr className='flex-grow-1' />
                                </div>
                            </div>






                            <div className='col-12'>
                                <div className='mb-3'>
                                    <InputField name='username' labelText='Username' attrs={{ autoComplete: 'new-password' }} errs={formErrors} />
                                </div>
                            </div>




                            <div className='col-md-6'>
                                <div className='mb-3'>
                                    <InputField name='password' labelText='Password' attrs={{ type: 'password', autoComplete: 'new-password' }} errs={formErrors} />
                                </div>
                            </div>




                            <div className='col-md-6'>
                                <div className='mb-3'>
                                    <InputField name='confirm_password' labelText='Confirm password' attrs={{ type: 'password' }} errs={formErrors} />
                                </div>
                            </div>




                            {/* ------------------------------------------------------------------------------------------- */}














                        </div></>
                }
            </ScrollEndTrigger>


        </CardLayout>
    );
}

export default CreateStaff;