import React, { useState } from 'react';
import Busy from '../components/Busy';
import ScrollEndTrigger from '../components/ScrollEndTrigger';
import CardLayout from '../layouts/CardLayout';
import Util from '../Util';
import Button from '../components/Button';
import { ErrorStatusModal, SuccessStatusModal } from '../components/StatusModal'
import InputField from '../components/InputField';
import Modal from '../components/Modal';
import ActionHeader from '../components/ActionHeader';


// All the cashbooks by dates
function ChangePassword(props) {

    const
        date = new Date(),
        [state, setState] = useState(
            {
                changingPasswordDone: false,
                changingPassword: false,
                changingPasswordError: false,
                changingPasswordText: '',
                reset: false,

                errs: {}
            });


    function handleChangePassword(e) {
        e.preventDefault();

        Util.request.post(`changepassword`, new FormData(e.target))
            .then(res => setState(o => ({
                changingPassword: false,
                changingPasswordDone: true,
                changingPasswordError: !res.status,
                changingPasswordText: Util.resolveResponseMessage(res),
                errs: Util.resolveFormError(res)
            })))

        setState(o => ({ ...o, changingPassword: true }));
    }



    function handlePasswordReset() {

        Util.request.post('resetpassword')
            .then(res => setState(o => ({
                changingPassword: false,
                changingPasswordDone: true,
                changingPasswordError: !res.status,
                changingPasswordText: Util.resolveResponseMessage(res),
                errs: Util.resolveFormError(res),
                reset: false
            })));

        setState(o => ({ ...o, changingPassword: true, reset: true }))
    }


    return (
        <CardLayout
            pageName='Change password'
            pageIcon='lock'
            cardFooter={<Button icon='lock' text='Change password' />}
            cardHeader={<ActionHeader actions={[{
                icon: 'refresh',
                attrs: { 'data-bs-toggle': 'modal', 'data-bs-target': '#confirm-password-reset' },
                text:'Reset password',
            }]} />}
            cardElement='form'
            cardElementProps={{ onSubmit: handleChangePassword }}
        >
            <Busy busy={state.changingPassword && !state.reset} fixed text={`Changing password...`} />

            <Busy busy={state.changingPassword && state.reset} text='Resetting password...' />

            <SuccessStatusModal
                show={state.changingPasswordDone && !state.changingPasswordError}
                onDone={() => setState(o => ({ ...o, changingPasswordDone: false }))}
                text={state.changingPasswordText} />



            <ErrorStatusModal
                show={state.changingPasswordDone && state.changingPasswordError}
                onDone={() => setState(o => ({ ...o, changingPasswordDone: false }))}
                text={state.changingPasswordText} />

            <Modal id='confirm-password-reset' on={handlePasswordReset} type='warning' title='Password reset'>
                If you proceed with this action, your password will be reset and
                sent to the email address specified for this account.<br />
                Proceed?
            </Modal>


            <ScrollEndTrigger maxHeight='430px'>
                <div className='row'>
                    <div className='col-12 mb-3'>
                        <InputField type='password' errs={state.errs} name='current_password' labelText='Current password' />
                    </div>
                    <div className='col-12 col-lg-6 mb-3'>
                        <InputField type='password' errs={state.errs} name='new_password' labelText='New password' />
                    </div>
                    <div className='col-12 col-lg-6 mb-3'>
                        <InputField type='password' errs={state.errs} name='confirm_password' labelText='Confirm password' />
                    </div>
                </div>
            </ScrollEndTrigger>
        </CardLayout>
    )
}

export default ChangePassword;