import React from 'react';

function InputField({ name, id, errs, labelIcon, labelText, type, attrs, value, }) {
    if (!name) return null;

    name = name.toString();

    id = id || name.replace(/\s+/, '_').toLowerCase();

    errs = errs && typeof errs === 'object' ? errs : {};

    return (
        <>
            <label className='form-label text-muted' htmlFor={id}>
                {!!labelIcon && <i className={`fa fa-${labelIcon} me-2`}></i>}
                {labelText}
            </label>

            <input
                onFocus={e => e.target.select()}
                {...(type && { type: type })}
                className={`form-control ${(errs[name] ? 'error' : '')}`}
                id={id} name={name}
                {...(value ? { defaultValue: value } : {})}
                {...(attrs && typeof attrs === 'object' ? attrs : {})}
            />


            {errs[name] && errs[name].map((e, i) => <span key={i}>{e.message}</span>)}
        </>
    );
}

export default InputField;