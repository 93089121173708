import React, { useContext, useEffect, useState } from 'react';
import CardLayout from '../layouts/CardLayout';
import Util from '../Util';
import ScrollEndTrigger from '../components/ScrollEndTrigger';
import Modal from '../components/Modal';
import InputField from '../components/InputField';
import Button from '../components/Button';
import Table2 from '../components/Table2';
import { useQueryState } from '../hooks';

//date -> color;
const
    dateColorMap = {},
    rand = () => (Math.random() * 255) | 0;


function TellersVerify(props) {
    const

        tellers = useQueryState([]),
        [start, setStart] = useState(Util.today(true)),
        [stop, setStop] = useState(Util.today(true)),
        [checked, setChecked] = useState({}),

        { currentOutlet, user: { position: pos } } = useContext(Util.UserContext);

    function getTellers() {
        Util.request.get(`gettellers/${currentOutlet}/${start}/${stop}`)
            .then(res => {
                const checked = {};
                if (res.status) {
                    for (const { tellerId, status } of res.data) {
                        checked[tellerId] = status || false;
                    }
                    setChecked(checked);
                }
                tellers.fromResponse(res);

            });

        tellers.busy();
    }

    useEffect(getTellers, []);


    function handleSubmit() {
        const fd = new FormData;

        Object.entries(checked).forEach(([k, v]) => {
            fd.set(k, v);
        });

        Util.request.post("concludeteller", fd)
            .then(res => {
                getTellers();
            })

        tellers.busy()
    }


    return (
        <CardLayout
            pageName='VERIFY TELLERS' pageIcon='receipt'
            cardFooter={<>
                <Button text='Submit' icon={'check'} onClick={handleSubmit} />
            </>}
        >


            <tellers.Busy />
            <tellers.ModalError />

            <ScrollEndTrigger maxHeight='350px'>
                <div className='mb-5'>
                    <div className="d-flex">
                        <div className='me-3'>
                            <InputField attrs={{
                                value: start,
                                onInput: e => setStart(e.target.value),
                                style: { maxWidth: '8.2rem' }
                            }}
                                type='date' name='startDate' labelText='Start date'

                                labelIcon='calendar' />
                        </div>

                        <div className=''>
                            <InputField attrs={{
                                value: stop,
                                onInput: e => setStop(e.target.value),
                                style: { maxWidth: '8.2rem' }
                            }}
                                type='date' name='stopDate' labelText='Stop date'
                                labelIcon='calendar' />
                        </div>
                    </div>

                    <div className="mt-3">
                        <Button onClick={getTellers} text={'Get tellers'} icon={'receipt'} />
                    </div>

                </div>

                <Table2 data={tellers.data} columns={[
                    {
                        name: 'Date',
                        selector: data => data.date,
                        attrs: data => {
                            let color = dateColorMap[data.date];
                            if (!color) {
                                color = dateColorMap[data.date] = `rgb(${rand()} ${rand()} ${rand()} / .4)`;
                            }
                            return ({
                                style: {
                                    backgroundColor: color
                                },
                            })
                        }
                    },
                    {
                        name: 'Time',
                        selector: data => data.time
                    },
                    {
                        name: 'Comment',
                        selector: data => data.comment
                    },
                    {
                        name: 'Amount',
                        selector: data => 'N' + Util.format(data.amount),
                        footer: arr => 'N' + Util.format(arr.reduce((acc, o) => acc + o.amount, 0))
                    },
                    {
                        name: <Checker checked={Object.values(checked).every(v => v)} onChecked={value => {
                            const o = {};
                            for (const k in checked) o[k] = value;
                            setChecked(o);
                        }} />,

                        selector: data => (
                            <Checker
                                checked={checked[data.tellerId]}
                                onChecked={value => {
                                    checked[data.tellerId] = value;
                                    setChecked({ ...checked });
                                }} />
                        ),
                        footer: () => `${Object.values(checked).filter(v => v).length}/${tellers.data.length}`
                    },


                ]} />


            </ScrollEndTrigger>
        </CardLayout>
    );
}

export default TellersVerify;




/**
 * 
 * @param {{disabled: boolean, checked: boolean, onChecked: (value: boolean) => void}} 
 * @returns 
 */
const Checker = ({ checked, onChecked, disabled }) => {
    return (
        <label

            onClick={() => onChecked(!checked)}
            style={{
                borderRadius: '5px',
                cursor: 'pointer',
                backgroundColor: '#bbbbbba0',
                display: 'inline-block',
                width: '4rem',
                height: '2rem',
                opacity: disabled ? .3 : 1,
                pointerEvents: disabled ? 'none' : 'auto',
            }}>
            <span className={`${checked ? 'bg-success' : 'bg-danger'} text-white`} style={{
                pointerEvents: 'none',
                borderRadius: 'inherit',
                transition: 'background-color 200ms ease, transform 200ms ease',
                position: 'relative',
                width: 'calc(50% - 0.3rem)',
                height: 'calc(100% - 0.6rem)',
                margin: '.3rem',
                boxSizing: 'border-box',
                display: 'inline-flex',
                justifyContent: 'center',
                alignItems: 'center',
                transformOrigin: 'left',
                transform: 'translateX(' + (!!checked * 100) + '%)'
            }}>
                <i className={`fa fa-${checked ? 'check' : 'times'}`}></i>
            </span>
        </label>
    )
}

Checker.defaultProps = {
    checked: false,
    onChecked(value) { }
}