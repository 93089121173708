import React, { useContext, useEffect, useState } from 'react';
import CardLayout from '../layouts/CardLayout';
import Util from '../Util';
import Busy from '../components/Busy';
import ScrollEndTrigger from '../components/ScrollEndTrigger';
import { ErrorStatusModal, SuccessStatusModal } from '../components/StatusModal';
import Table from '../components/Table';
import { useNavigate } from 'react-router-dom';
import Modal from '../components/Modal';
import InputField from '../components/InputField';
import Button from '../components/Button';
import DatePicker from '../components/DatePicker';


function CashRemitHistory(props) {
    const
        d = new Date(),
        [state, setState] = useState({

            gettingRemit: false,
            gettingRemitDone: false,
            gettingRemitError: false,
            gettingRemitText: '',

            confirmingRemit: false,
            confirmingRemitDone: false,
            confirmingRemitError: false,
            confirmingRemitText: '',

            deletingRemit: false,
            deletingRemitDone: false,
            deletingRemitError: false,
            deletingRemitText: '',

            remits: [],

            selectedDate: Util.today(true)
        }),

        { currentOutlet, user: { position: pos } } = useContext(Util.UserContext),

        navigate = useNavigate();

    useEffect(() => {
        Util.request.get(`remithistory/${currentOutlet}/${state.selectedDate}`)
            .then(res => {
                setState(o => ({
                    ...o,
                    gettingRemit: false,
                    gettingRemitDone: true,
                    gettingRemitError: !res.status,
                    gettingRemitText: res.message,

                    remits: res.status ? res.data : state.remits
                }))
            })
        setState(o => ({ ...o, gettingRemit: true }));
    }, [state.selectedDate]);


    function handleChangeDate(e) {
        e.preventDefault();

        let date = new FormData(e.target).get('date');

        if (!date) return;

        setState(o => ({ ...o, selectedDate: date }))

    }

    function handleRemitConfirm(remit, status) {
        let fd = new FormData, remits = state.remits;
        fd.set('status', +Boolean(status));

        Util.request.post(`remit/confirm/${remit.remitId}`, fd)
            .then(res => {
                if (res.status) {
                    remit.status = Boolean(status);
                    remit.statusText = res.data.statusText;
                }

                setState(o => ({
                    ...o,
                    confirmingRemit: false,
                    confirmingRemitDone: true,
                    confirmingRemitError: !res.status,
                    confirmingRemitText: res.message,
                }))
            });

        setState(o => ({ ...o, confirmingRemit: true }))
    }

    function handleDeleteRemit({ remitId: rid }) {

        Util.request.post(`remit/${rid}/delete`)
            .then(res => setState(o => ({
                ...o,
                deletingRemit: false,
                deletingRemitDone: true,
                deletingRemitError: !res.status,
                deletingRemitText: res.message,
                remits: res.status ? state.remits.filter(({ remitId }) => remitId !== rid) : state.remits
            })))

        setState(o => ({ ...o, deletingRemit: true }))
    }

    return (
        <CardLayout
            pageName='attendants sales history' pageIcon='money'>

            <Busy busy={state.gettingRemit} busyText='Retrieving sales...' />

            <Busy busy={state.confirmingRemit} busyText='Confirming sales...' />

            <ErrorStatusModal
                show={state.gettingRemitDone && state.gettingRemitError}
                onDone={() => setState(o => ({ ...o, gettingRemitDone: false }))}
                text={state.gettingRemitText} />

            <SuccessStatusModal
                show={state.confirmingRemitDone && !state.confirmingRemitError}
                onDone={() => setState(o => ({ ...o, confirmingRemitDone: false }))}
                text={state.confirmingRemitText} />


            <ErrorStatusModal
                show={state.confirmingRemitDone && state.confirmingRemitError}
                onDone={() => setState(o => ({ ...o, confirmingRemitDone: false }))}
                text={state.confirmingRemitText} />

            <SuccessStatusModal
                show={state.deletingRemitDone && !state.deletingRemitError}
                onDone={() => setState(o => ({ ...o, deletingRemitDone: false }))}
                text={state.deletingRemitText} />


            <ErrorStatusModal
                show={state.deletingRemitDone && state.deletingRemitError}
                onDone={() => setState(o => ({ ...o, deletingRemitDone: false }))}
                text={state.deletingRemitText} />

            {state.remits.map(remit => <Modal
                key={`remit-${remit.remitId}`}
                title='Delete remit?'
                id={`remit-${remit.remitId}`}
                type='danger'
                primary={{ icon: 'trash', text: 'Delete' }}
                on={() => handleDeleteRemit(remit)}
            >
                <p>Delete remit by <b>{remit.remitBy}</b> (N{Util.format(remit.amount)})</p>
            </Modal>)}


            <ScrollEndTrigger maxHeight='350px'>


                <DatePicker date={state.selectedDate} onDateChange={date=>setState(o=>({...o, selectedDate: date}))}/>

                <Table
                    noDataText={`No sales for date ${state.selectedDate}`}
                    data={state.remits.map(_o => {
                        const o = { ..._o };
                        o.amount = `N${Util.format(o.amount)}`;
                        return o;
                    })}
                    tableHead={['Date', 'Time', 'Remit by', 'Amount', 'Status   ']}
                    keys={['date', 'time', 'remitBy.fullNames', 'amount', 'statusText']}
                    actions={[
                        remit => ({ icon: 'times', text: 'Reject', on: remit => handleRemitConfirm(remit, 0), hideIf: (!pos.canConfirmMondeyRemits || remit.status !== null) }),
                        remit => ({ icon: 'check', text: 'Approve', on: remit => handleRemitConfirm(remit, 1), hideIf: (!pos.canConfirmMondeyRemits || remit.status !== null) }),
                        remit => ({ icon: 'trash', text: 'Delete', attrs: { 'data-bs-target': `#remit-${remit.remitId}`, 'data-bs-toggle': 'modal' }, hideIf: !pos.canDeleteRemit })
                    ]}
                    tableFoot={['', '', '', 'Total: N' + Util.format(state.remits.reduce((a, { amount }) => a + amount, 0))]}
                />
            </ScrollEndTrigger>

        </CardLayout>
    );
}

export default CashRemitHistory;