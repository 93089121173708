import React, { useContext, useEffect, useState } from 'react';
import CardLayout from '../../layouts/CardLayout';
import Util from '../../Util';
import ScrollEndTrigger from '../../components/ScrollEndTrigger';
import InputField from '../../components/InputField';
import Paths from '../../Paths';
import { useSearchParams } from 'react-router-dom';
import style from './style.module.css';

const todayPadded = () => {
    const
        d = new Date(),
        month = d.getMonth() + 1,
        day = d.getDate();

    return `${d.getFullYear()}-${String(month).padStart(2, 0)}-${String(day).padStart(2, 0)}`;
};




export default function Auditing(props) {
    let
        [start, setStart] = useState(todayPadded()),
        [stop, setStop] = useState(todayPadded()),
        { currentOutlet: id } = useContext(Util.UserContext);


    return (
        <CardLayout
            pageName='Pump reading' pageIcon='calculator'
        >

            <ScrollEndTrigger
                stretch
                maxHeight={'400px'}
            >

                <div className='mb-5'>
                    <div className="d-flex">
                        <div className=''>
                            <InputField attrs={{
                                value: start,
                                onInput: e => setStart(e.target.value)
                            }}
                                type='date' name='startDate' labelText='Start date'

                                labelIcon='calendar' />
                        </div>

                        <div className=''>
                            <InputField attrs={{
                                value: stop,
                                onInput: e => setStop(e.target.value)
                            }}
                                type='date' name='stopDate' labelText='Stop date'
                                labelIcon='calendar' />
                        </div>
                    </div>

                    <div className='col-12 mt-3'>
                        <a href={Paths.AUDITING.params({ start, stop, id }).abs.$}
                            target='_blank' className='btn btn-primary'>
                            <i className="fa fa-download me-2"></i>
                            Generate autiding
                        </a>
                    </div>
                </div>
            </ScrollEndTrigger>

        </CardLayout>
    );
}





export function AuditingView(props) {

    const [p] = useSearchParams({
        start: Util.today(true),
        stop: Util.today(true),
        id: 0
    }),
        start = p.get('start'),
        stop = p.get('stop'),
        id = p.get('id'),

        [{ data, ...audit }, setAudit] = useState({
            data: null,
            gettingData: false,
            gettingDataDone: false,
            gettingDataMessage: '',
            gettingDataStatus: false
        });

    useEffect(() => {
        let style = document.createElement('style');

        style.innerText = `
        body, main, #root, html {
            overflow: auto!important;
            height: auto!important;
        } 
        `;

        document.head.appendChild(style);

        return () => { style.remove() }
    }, []);

    useEffect(() => {
        Util.request.get(`bundle/audit/${id}`, { start, stop, id })
            .then(res => {
                if (res.status) {
                    setAudit(o => ({
                        ...o,
                        data: res.data,
                        gettingData: false
                    }))
                }
            });

        setAudit(o => ({ ...o, gettingData: true }));
    }, [start, stop])



    return (
        audit.gettingData ? <div style={{ position: 'fixed', width: '100%', height: '100%', fontWeight: 'bold', fontSize: '2rem', color: '#777', left: '0', top: 0, zIndex: 50, background: '#e0e0e0', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
            <p>Calculating report...</p>
        </div> :


            <main data-theme='light' className={`bg-white ${style.main}`} >
                <div className={`text-center bg-primary text-white pt-3 text-uppercase px-2 ${style.bar}`}>
                    <h2>ronald mnyim auditing report</h2>
                    <h6 className='m-0 p-0'>{!!data && data.outlet.outletName}</h6>
                </div>
                <div className={style.baz}>
                    <div className="text-center bg-primary text-white py-3 text-uppercase px-2">
                        <p className="m-0" sty>{start} <span className='mx-3'>-</span> {stop}</p>
                    </div>
                </div>

                <div className="px-3">
                    <div className="text-center no-print">
                        <button className="" onClick={e => window.print()}>
                            Print this report
                        </button>
                        <br /><br />
                    </div>

                    {
                        data && (function () {
                            let
                                totalApprox = 0,
                                tellerCashbookDiffSum = 0,

                                totalAudit =
                                    data.audit.reduce((function () {
                                        const $ = (f, acc, o) => acc[f] = (acc[f] || 0) + o[f];
                                        return (acc, o) => {
                                            $('pump_sales', acc, o);
                                            $('pos', acc, o);
                                            $('rtt', acc, o);
                                            $('expenses', acc, o);
                                            $('credits', acc, o);
                                            $('prepaid', acc, o);
                                            $('postpaid', acc, o);
                                            $('to_bank', acc, o);
                                            $('left_over', acc, o);
                                            $('tellers', acc, o);
                                            $('confirmed_tellers', acc, o);
                                            acc.cashbook = (acc?.cashbook || 0) + (o?.cashbook?.sales || 0) + (o?.cashbook?.rollover || 0)
                                            return acc;
                                        };
                                    })(), {});



                            return <>

                                <h4 className="text-primary">Pump sales</h4>
                                <table className="table table-striped table-bordered table-sm">
                                    <thead className='bg-primary text-white'>
                                        <tr>
                                            <th>Product</th>
                                            <th>Litres</th>
                                            <th>Amount</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {Object.entries(data.summary).map(([type, o], i) => <tr key={i}>
                                            <th>{o.type}</th>
                                            <td>{Util.format(o.total_litres)}L</td>
                                            <td>N{Util.format(o.total_sales)}</td>
                                        </tr>)}
                                    </tbody>
                                    <tfoot>
                                        <tr>
                                            <th></th>
                                            <th>{Util.format(Object.values(data.summary).reduce((acc, o) => o.total_litres + acc, 0))}L</th>
                                            <th>Total: N{Util.format(Object.values(data.summary).reduce((acc, o) => o.total_sales + acc, 0))}</th>
                                        </tr>
                                    </tfoot>
                                </table>
                                <br />



                                <div className="avoid-break p-3" style={{ backgroundColor: '#f4f4f4' }}>
                                    <h1>Analysis</h1>
                                    <br />

                                    <table className='m-0 table table-sm table-bordered table-striped text-center'>
                                        <thead>
                                            <tr>
                                                <th>Date</th>
                                                <th>POS</th>
                                                <th>RTT</th>
                                                <th>Exp.</th>
                                                <th>Crd.</th>
                                                <th>Prepaid</th>
                                                <th>Postpaid</th>
                                                <th>Bank</th>
                                                <th>Left over</th>
                                                <th>Rollover</th>
                                                <th>Aprox.</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                data.audit.map((o, i, a) => <tr key={i}>

                                                    <td><b>{o.day}, {o.date}</b></td>
                                                    <td>N{Util.format(o.pos)}</td>
                                                    <td>N{Util.format(o.rtt)}</td>
                                                    <td>N{Util.format(o.expenses)}</td>
                                                    <td>N{Util.format(o.credits)}</td>
                                                    <td>N{Util.format(o.prepaid)}</td>
                                                    <td>N{Util.format(o.postpaid)}</td>
                                                    <td>N{Util.format(o.to_bank)}</td>
                                                    <td>N{Util.format(o.left_over)}</td>
                                                    {/* DC354590 */}
                                                    {(() => {
                                                        if (i < 1) return (<>
                                                            <td>N{Util.format(o.cashbook.rollover)}</td>
                                                            <td></td>
                                                        </>);

                                                        let
                                                            rollover = o.cashbook.rollover,
                                                            row = i - 1,
                                                            prevLeftOver = 0,
                                                            hasError = false,
                                                            diff = 0,
                                                            color;

                                                        // if (isWeekDay && !rollover) rollover = 0;

                                                        if (typeof rollover === 'number') {
                                                            while (row > 0 && !a[row].cashbook.rollover) --row;

                                                            for (let j = row; j < i; j++) prevLeftOver += a[j].left_over;

                                                            diff = prevLeftOver - rollover;
                                                            hasError = Math.abs(diff) > 50;
                                                            totalApprox += Math.abs(diff);

                                                            if (hasError) color = diff > 0 ? '#DC3545' : '#257eed';
                                                        }

                                                        return (<>
                                                            <td style={{ backgroundColor: hasError ? '#DC354550' : 'transparent' }}>N{Util.format(rollover || 0)}</td>
                                                            <td style={{ color }}>N{Util.format(Math.abs(diff))}</td>
                                                        </>)
                                                    })()}


                                                </tr>)
                                            }
                                        </tbody>
                                        <tfoot>
                                            <tr>
                                                <th rowSpan={2}>Total:</th>
                                                <th>N{Util.format(totalAudit.pos)}</th>
                                                <th>N{Util.format(totalAudit.rtt)}</th>
                                                <th>N{Util.format(totalAudit.expenses)}</th>
                                                <th>N{Util.format(totalAudit.credits)}</th>
                                                <th>N{Util.format(totalAudit.prepaid)}</th>
                                                <th>N{Util.format(totalAudit.postpaid)}</th>
                                                <th>N{Util.format(totalAudit.to_bank)}</th>
                                                <th>N{Util.format(totalAudit.left_over)}</th>
                                                <th></th>
                                                <th>N{Util.format(totalApprox)}</th>

                                            </tr>

                                        </tfoot>
                                    </table>
                                    <br />
                                    <hr />
                                    <br />
                                    <table className='m-0 table table-sm table-bordered table-striped text-center'>
                                        <thead>
                                            <tr>
                                                <th rowSpan={2}>Date</th>
                                                <th rowSpan={2}>Pump</th>
                                                <th colSpan={3}>Disparities</th>
                                                <th rowSpan={2}>Confirmed</th>
                                            </tr>
                                            <tr>
                                                <th>Cashbook</th>
                                                <th>Diff.</th>
                                                <th>Tellers</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                data.audit.map((o, i, a) => {
                                                    const
                                                        cashbook = o.cashbook.sales + (o.cashbook.rollover || 0) + o.cashbook.others,
                                                        tellerCashbookDiff = Math.abs(cashbook - o.tellers),
                                                        notEqual = tellerCashbookDiff > 5;

                                                    tellerCashbookDiffSum += tellerCashbookDiff;

                                                    return <tr key={i}>
                                                        <td><b>{o.date}</b></td>
                                                        <td>N{Util.format(o.pump_sales)}</td>
                                                        <td style={{ backgroundColor: notEqual ? '#DC354550' : 'transparent' }}>
                                                            {cashbook > o.tellers && <i className='fa fa-arrow-up me-2 text-danger'></i>}
                                                            N{Util.format(cashbook)}
                                                        </td>
                                                        <td style={{ backgroundColor: notEqual ? '#DC354550' : 'transparent' }}>N{Util.format(tellerCashbookDiff)}</td>
                                                        <td style={{ backgroundColor: notEqual ? '#DC354550' : 'transparent' }}>
                                                            {cashbook < o.tellers && <i className='fa fa-arrow-up me-2 text-danger'></i>}
                                                            N{Util.format(o.tellers)}
                                                        </td>

                                                        <td style={{ backgroundColor: o.tellers !== o.confirmed_tellers ? '#FFCF5780' : 'transparent' }}>N{Util.format(o.confirmed_tellers)}</td>
                                                    </tr>
                                                })
                                            }
                                        </tbody>
                                        <tfoot>
                                            <tr>
                                                <th rowSpan={2}>Total:</th>
                                                <th rowSpan={2}>N{Util.format(totalAudit.pump_sales)}</th>
                                                <th rowSpan={2}>N{Util.format(totalAudit.cashbook)}</th>
                                                <th rowSpan={2}>N{Util.format(tellerCashbookDiffSum)}</th>
                                                <th>N{Util.format(totalAudit.tellers)}</th>
                                                <th>N{Util.format(totalAudit.confirmed_tellers)}</th>
                                            </tr>
                                            <tr>

                                                <td colSpan={2} className='p-0'>
                                                    N{Util.format(totalAudit.tellers - totalAudit.confirmed_tellers)}
                                                </td>
                                            </tr>

                                        </tfoot>
                                    </table>
                                    <br />
                                    <br />
                                    <hr />
                                    <br />
                                    <table className='m-0 table table-sm table-bordered table-striped text-center'>
                                        {(() => {

                                            let
                                                pumpSales = totalAudit.pump_sales,
                                                confirmedTellers = totalAudit.confirmed_tellers,
                                                initialRollover = data.audit[0].cashbook.rollover || 0,
                                                lastCashLeftover = 0,
                                                creditsPaid = data.credits_paid.reduce((acc, o) => acc + o.amount, 0),
                                                salesAmount = confirmedTellers - initialRollover - creditsPaid,
                                                deductibles = totalAudit.pos + totalAudit.rtt +
                                                    totalAudit.expenses + totalAudit.prepaid +
                                                    totalAudit.postpaid + totalAudit.credits,
                                                balance = pumpSales - salesAmount - deductibles;

                                            for (let i = data.audit.length - 1; true; --i) {
                                                lastCashLeftover += (data.audit[i].left_over || 0);
                                                if (data.audit[i].cashbook.rollover) break;
                                            }

                                            return (
                                                <tbody>
                                                    <tr>
                                                        <th>Pump sales</th>
                                                        <td>N{Util.format(pumpSales)}</td>
                                                    </tr>
                                                    <tr>
                                                        <th>Confirmed Tellers</th>
                                                        <td>N{Util.format(confirmedTellers)}</td>
                                                    </tr>
                                                    <tr>
                                                        <th>Initial Rollover</th>
                                                        <td>N{Util.format(initialRollover)}</td>
                                                    </tr>
                                                    <tr>
                                                        <th>Last cash leftover</th>
                                                        <td>N{Util.format(lastCashLeftover)}</td>
                                                    </tr>
                                                    <tr>
                                                        <th>Confirmed Tellers - Initial rollover - Crd. paid (Sales amount)</th>
                                                        <td>N{Util.format(salesAmount)}</td>
                                                    </tr>
                                                    <tr>
                                                        <th>POS	+ RTT + Exp. + Crd. + Prepaid + Postpaid (Deductibles)</th>
                                                        <td>N{Util.format(deductibles)}</td>
                                                    </tr>
                                                    <tr>
                                                        <th>Pump sales - Sales amount - Deductibles (Balance)</th>
                                                        <td>N{Util.format(balance)}</td>
                                                    </tr>

                                                    <tr>
                                                        <th>Balance - Last left over + Disparities + Approx.</th>
                                                        <td>N{Util.format((balance - lastCashLeftover) + tellerCashbookDiffSum + totalApprox)}</td>
                                                    </tr>
                                                </tbody>
                                            )
                                        })()}
                                    </table>
                                    <br />
                                </div>
                            </>
                        })()}
                </div>
            </main >
    );
}