import React, { useMemo } from 'react';



/**
 * 
 * @param {{
 * data:Array<object>,
 * columns: Array<{
 * name: string, 
 * attrs: (data: object, row: number, col: number, array: Array<object>) => React.DetailedHTMLProps<React.TdHTMLAttributes<HTMLTableDataCellElement>, HTMLTableDataCellElement>,
 * selector:(data: object, row: number, col:number, array: Array) => void,
 * footer:(array: Array) => void,
 * }>
 * }} 
 */
const Table2 = ({ data, columns, emptyTableText = 'Empty', loading, loader }) => {


    const
        hasFooter = useMemo(() => columns.some(o => typeof o.footer === 'function'), [columns.length]);

    return (
        <div className="overflow-auto">
            <div className='' style={{ overflow: 'auto' }}>
                <table className='table table-striped table-bordered fg-color'>
                    <thead className='text-primary' style={{ position: 'sticky', top: 0 }}>
                        <tr>{columns.map((o, i) => <th key={i}>{o.name}</th>)}</tr>
                    </thead>
                    <tbody>
                        {
                            data.length ?
                                data.map((o, row) => <tr key={row}>{columns.map((obj, col) => {
                                    const attrs = typeof obj.attrs === 'function' ? obj.attrs(o, row, col, data) : {};
                                    return (
                                        <td key={col} {...attrs}>{obj.selector(o, row, col, data)}</td>
                                    )
                                })}</tr>) :

                                <tr><td colSpan={columns.length} className='text-center' style={{ opacity: .7 }}>{emptyTableText}</td></tr>
                        }
                    </tbody>
                    {
                        hasFooter && !!data.length && (
                            <tfoot>
                                <tr>
                                    {
                                        columns.map((obj, col) => {
                                            return (
                                                <th key={col}>{obj.footer && obj.footer(data, col)}</th>
                                            )
                                        })
                                    }
                                </tr>
                            </tfoot>
                        )
                    }
                </table>
            </div>
        </div>
    )
}

Table2.serialColumn = { name: '', selector: ($, row) => <b>{row}</b> };

export default Table2;