import React from 'react';
import Util from '../Util';


function onScrollEnd(callback, gap) {
    if(typeof callback !== 'function') callback = ()=>0;

    let previousScrollPoint = 0;

    return function (e) {
        const 
            currentScrollPoint = e.target.scrollTop + e.target.clientHeight,
            distanceFromBottom = Math.abs(currentScrollPoint - e.target.scrollHeight),
            triggerZone = e.target.scrollHeight - gap;

        if (
            e.target.scrollHeight <= e.target.clientHeight ||
            previousScrollPoint > triggerZone
        ) {
            previousScrollPoint = currentScrollPoint;
            return;
        }

        if ( distanceFromBottom <= gap) {
            previousScrollPoint = currentScrollPoint;
            Promise.resolve().then(()=>callback());
        }
    }
}





function ScrollEndTrigger(props) {


    return props.children;
}

export default ScrollEndTrigger;
