
import React, { useContext, useEffect, useState } from 'react';
import CardLayout from '../layouts/CardLayout';
import Util from '../Util';
import Busy from '../components/Busy';
import { ErrorStatusModal, SuccessStatusModal } from '../components/StatusModal';
import Button from '../components/Button';
import InputField from '../components/InputField';
import DatetimeFields from '../components/DatetimeFields';
import SelectInput from '../components/SelectInput';
import ActionHeader from '../components/ActionHeader';


export default function OverdueCredits(props) {
    const
        [state, setState] = useState({

            uploadingTeller: false,
            uploadingTellerDone: false,
            uploadingTellerError: false,
            uploadingTellerText: '',
            errors: {}
        }),
        [date, setDate] = useState(Util.today(true)),
        [subhead, setSubhead] = useState(''),
        [amount, setAmount] = useState(0),
        { currentOutlet = 0 } = useContext(Util.UserContext);




    const handleSubmit = (e) => {
        e.preventDefault();


        let fd = new FormData(e.target);

        Util.request.post(`overdue/${currentOutlet}/credit`, fd)
            .then(res => {
                setState(o => ({
                    ...o,
                    uploadingTeller: false,
                    uploadingTellerDone: true,
                    uploadingTellerError: !res.status,
                    uploadingTellerText: typeof res.message === 'string' ? res.message : '',
                    errors: typeof res.message === 'string' ? {} : res.message
                }));
                if(res.status) {
                    setAmount(0);
                    setSubhead('');
                }
            })

        setState(o => ({ ...o, uploadingTeller: true }))

    };

    const actionButton = <Button icon='upload' text='Upload' />;

    return (
        <CardLayout
            cardElement='form'
            cardElementProps={{ onSubmit: handleSubmit }}

            pageName='upload credit' pageIcon='upload'
            cardFooter={actionButton}
            cardHeader={<ActionHeader title='OVERDUE CREDIT UPLOAD'/>}
        >

            <Busy busy={state.uploadingTeller} busyText='Please wait...' />

            <ErrorStatusModal
                show={state.uploadingTellerDone && state.uploadingTellerError}
                onDone={() => setState(o => ({ ...o, uploadingTellerDone: false }))}
                text={state.uploadingTellerText} />

            <SuccessStatusModal
                show={state.uploadingTellerDone && !state.uploadingTellerError}
                onDone={() => setState(o => ({ ...o, uploadingTellerDone: false }))}
                text={state.uploadingTellerText} />


            <div className="mb-3">
                <DatetimeFields date={date}  onDateChange={setDate}  />
            </div>

            <div className="row">
                <div className='mb-3 col-md-6'>
                    <InputField errs={state.errors} name='subhead' attrs={{ value: subhead, onChange: e=>setSubhead(e.target.value)}} labelText='Subhead' labelIcon='pen' />
                </div>


                <div className='mb-3 col-md-6'>
                    <InputField errs={state.errors} name='amount' type='number' attrs={{ value: amount, onChange: e=>setAmount(e.target.value), min: .01, step:.01 }} labelText='Amount' labelIcon='money-bill' />
                </div>
            </div>


        </CardLayout>
    );
}