import React, { useContext, useEffect, useRef, useState } from 'react';
import Busy from '../components/Busy';
import ScrollEndTrigger from '../components/ScrollEndTrigger';
import Table from '../components/Table';
import CardLayout from '../layouts/CardLayout';
import Util from '../Util';
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import Button from '../components/Button';
import Paths from '../Paths';
import Modal from '../components/Modal'
import { ErrorStatusModal, SuccessStatusModal } from '../components/StatusModal';
import ActionHeader from '../components/ActionHeader';

function Tanks(props) {
    const
        [state, setState] = useState({
            records: [],
            gettingTanks: false,

            addingPumpSales: false,
            addingPumpSalesDone: false,
            addingPumpSalesFailed: false,
            addingPumpSalesMessage: ''
        }),
        { user: { position }, currentOutlet: outletId } = useContext(Util.UserContext),
        navigate = useNavigate();

    let offset = useRef(0);

    useEffect(() => {

        Util.request.get(`gettanks/${outletId}`)
            .then(res => {
                if (res.status) {
                    let sum = 0;
                    for (const o of res.data) {
                        o.total = o.value + sum;
                        sum += o.value;
                    }
                }
                setState({
                    records: res.status ? res.data : [],
                    gettingTanks: false,
                })
            });
        setState(o => ({ ...o, gettingTanks: true }));
    }, []);


    function handleAddPumpSales() {
        Util.request.post(`supply/add_pump_sales`)
            .then(res => setState(o => ({
                ...o,
                addingPumpSales: false,
                addingPumpSalesDone: true,
                addingPumpSalesFailed: !res.status,
                addingPumpSalesMessage: res.message
            })));

        setState(o => ({ ...o, addingPumpSales: true }));
    }



    return (
        <CardLayout
            pageName='Tanks'
            pageIcon='oil-can'
            cardHeader={<ActionHeader actions={[{
                showIf: position.canEnterSupplies,
                text: 'Input pump sales',
                attrs: { 'data-bs-target': '#pump-sale-confirm', 'data-bs-toggle': 'modal' },
                icon: 'arrow-down',

            }, {
                showIf: position.canMakeTankTransfers,
                text: 'Tank transfer',
                icon: 'arrow-right',
                onClick: $ => navigate(Paths.DAILY_SALES.join(Paths.TANK_TRANSFER).abs.$)
            }, {
                showIf: position.canCreateTanks,
                text: 'Create tank',
                icon: 'plus',
                onClick: $ => navigate(Paths.DAILY_SALES.join(Paths.CREATE_TANK).abs.$)
            }]} />}>
            <ScrollEndTrigger maxHeight='430px'>

                <Busy text="Retreiving tanks..." busy={state.gettingTanks} />

                <Modal
                    title='Input pump sales?'
                    primary={{ icon: 'check', text: 'Continue' }}
                    id='pump-sale-confirm'
                    on={handleAddPumpSales}
                >
                    <p>This will add pump sales to open supplies for all tanks. <br /> Continue?</p>
                </Modal>

                <Busy busy={state.addingPumpSales} />

                <SuccessStatusModal
                    onDone={() => setState(o => ({ ...o, addingPumpSalesDone: false }))}
                    show={state.addingPumpSales && !state.addingPumpSalesFailed} text={'Successful'}
                />

                <ErrorStatusModal
                    onDone={() => setState(o => ({ ...o, addingPumpSalesDone: false }))}
                    show={state.addingPumpSales && state.addingPumpSalesFailed} text={state.addingPumpSalesMessage}
                />

                <Table
                    tableHead={['Tank name', 'Tank type', 'Category', 'Quantity left', 'Capacity(Litre)', 'Price per litre']}
                    keys={['tankName', 'tankType.name', 'category', 'quantityLeft', 'capacity', 'tankType.pricePerLitre']}
                    data={state.records}
                    actions={[
                        { icon: 'eye', text: 'View', on: tank => navigate(Paths.DAILY_SALES.join(Paths.TANK_VIEW.parse(tank.tankId)).abs.$) },
                        { icon: 'chart-line', text: 'Stocks', on: tank => navigate(Paths.SUPPLY.parse(tank.tankId).join(Paths.SUPPLY_HISTORY).abs.$) },
                    ]}
                />
            </ScrollEndTrigger>
        </CardLayout>
    )
}

export default Tanks;
