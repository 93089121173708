import React, { useState, useEffect, useRef } from 'react';
import Busy from '../components/Busy';
import Button from '../components/Button';
import InputField from '../components/InputField';
import CardLayout from '../layouts/CardLayout';
import Util from '../Util';
import { ErrorStatusModal, SuccessStatusModal } from '../components/StatusModal';
import { useParams } from 'react-router-dom';



function PostpaidMakePayment(props) {
    const
        [state, setState] = useState({
            gettingData: false,
            gettingDataDone: false,
            gettingDataMessage: '',
            gettingDataFailed: false,

            makingPayment: false,
            makingPaymentDone: false,
            makingPaymentMessage: '',
            makingPaymentFailed: false,

            data: {},

            errs: {}
        }),
        { customerId = 0 } = useParams(),
        [amount, setAmount] = useState(0);

    useEffect(() => {

        Util.request.get(`ppcustomer/get/${customerId}`)
            .then(res => {
                setState(o => ({
                    ...o,
                    gettingData: false,
                    gettingDataDone: true,
                    gettingDataFailed: !res.status,
                    gettingDataMessage: Util.resolveResponseMessage(res),
                    data: res.status ? res.data : state.data,
                    errs: Util.resolveFormError(res)
                }));
            });

        setState(o => ({ ...o, gettingData: true }));
    }, []);

    function handleMakePayment(e) {

        e.preventDefault();

        const fd = new FormData(e.target);
        fd.append('postpaid_customer', customerId);

        Util.request.post(`ppcustomer/makepayment`, fd)
            .then(res => {
                setState(o => ({
                    ...o,
                    makingPayment: false,
                    makingPaymentDone: true,
                    makingPaymentMessage: Util.resolveResponseMessage(res),
                    makingPaymentFailed: !res.status,

                    errs: Util.resolveFormError(res)
                }));
                setAmount(0);
                if (res.status) {
                    state.data.balance -= res.data.amount
                }
            });

        setState(o => ({ ...o, makingPayment: true }));
    }

    return (
        <CardLayout
            cardElement='form'
            cardElementProps={{ onSubmit: handleMakePayment }}
            pageName="postpaid payment"
            pageIcon="receipt"
            cardFooter={<Button text='Make payment' icon='receipt' />}>

            <Busy busy={state.makingPayment} busyText='Making payment...' />

            <Busy busy={state.gettingData} busyText='Getting customer data...' />

            <SuccessStatusModal
                show={state.makingPaymentDone && !state.makingPaymentFailed}
                onDone={() => setState(o => ({ ...o, makingPaymentDone: false }))}
                text={state.makingPaymentMessage} />
            {
                typeof state.makingPaymentMessage === 'string' &&
                <ErrorStatusModal
                    show={state.makingPaymentDone && state.makingPaymentFailed}
                    onDone={() => setState(o => ({ ...o, makingPaymentDone: false }))}
                    text={state.makingPaymentMessage} />}


            <div className='row'>

                <div className='col-12 mb-4'>
                    <InputField name='customer' attrs={{ readOnly: true, disabled: true }} labelText='Customer' value={state.data.name} labelIcon='user' />
                </div>

                <div className='col-12 col-md-6 mb-4'>
                    <InputField name='_' labelText='Unpaid balance'
                        type='text' attrs={{ value: `N${Util.format(state.data.balance - amount)}`, disabled: true, readOnly: true }}
                        labelIcon='money-bill' />
                </div>

                <div className='col-12 col-md-6 mb-4'>
                    <InputField name='amount' errs={state.errs} labelText='Amount'
                        type='number' attrs={{ min: .01, max: state.data.balance, step: .01, onFocus: e => e.target.select(), value: amount, onInput: e => setAmount(Number(e.target.value)) }}
                        labelIcon='money-bill' />
                </div>

                

                <div className='col-12 col-md-6 mb-4'>
                    <div className="form-check">
                        <input className="form-check-input" name='to_cashbook' type="checkbox" id='existing' />
                        <label className="form-check-label" htmlFor="existing">
                            Move to cashbook
                        </label>
                    </div>
                </div>

            </div>

        </CardLayout>
    );
}



export default PostpaidMakePayment;