import React, { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Busy from '../components/Busy';
import Button from '../components/Button';
import ScrollEndTrigger from '../components/ScrollEndTrigger';
import Table from '../components/Table';
import CardLayout from '../layouts/CardLayout';
import Paths from '../Paths';
import Util from '../Util';
import ActionHeader from '../components/ActionHeader';


function Users(props) {
    const
        [state, setState] = useState({
            gettingStaff: false,
            gettingStaffError: false,
            gettingStaffText: '',
            staff: []
        }),

        { currentOutlet, user } = useContext(Util.UserContext),

        navigate = useNavigate();


    useEffect(() => {
        if (state.gettingStaff || state.staff.length) return;

        Util.request.get(`staff/${currentOutlet}`)
            .then(res => setState(o => ({
                ...o,
                gettingStaff: false,
                gettingStaffError: !res.status,
                gettingStaffText: res.message,
                staff: res.status ? res.data : []
            })));

        setState(o => ({ ...o, gettingStaff: true }))
    }, []);


    return (
        <CardLayout
            pageName="Staff"
            pageIcon="users"
            cardHeader={<ActionHeader actions={[{
                showIf: user.position.canManageOutlet,
                icon: 'plus',
                text: 'Create staff',
                onClick: () => navigate(Paths.STAFF.join(Paths.CREATE_STAFF).abs.$),
            }]} />}>


            <Busy busy={state.gettingStaff} busyText='Retrieving staff...' />

            <ScrollEndTrigger maxHeight='380px'>
                <Table
                    keys={['staff.lastname', 'staff.firstname', 'staff.position.name']}
                    tableHead={['Lastname', 'Firstname', 'Position']} data={state.staff}
                    actions={[
                        data => ({
                            icon: 'user',
                            text: 'View',
                            on: data => navigate(Paths.STAFF.join(Paths.VIEW_STAFF.parse(data.staff.username)).abs.$),
                            hideIf: data.staff.position.level > user.position.level
                        })
                    ]}
                />
            </ScrollEndTrigger>
        </CardLayout>
    );
}

export default Users;