import React, { useContext, useEffect } from 'react'

import ScrollEndTrigger from '../components/ScrollEndTrigger';
import CardLayout from '../layouts/CardLayout';
import Util from '../Util';
import { useQueryState } from '../hooks'
import Table2 from '../components/Table2';
import SelectInput from '../components/SelectInput';
import { useState } from 'react';
import { useMemo } from 'react';
import InputField from '../components/InputField';
import Button from '../components/Button';
import Dialog from '../components/Modal';
import Hint from '../components/Hint';
/**
 * 
 * @param {HTMLInputElement} input 
 */
function applyStyle(initial, current, isStringField = false) {

    const o = {
        boxSizing: 'border-box',
        minWidth: '9.5rem',
        padding: '.2rem',
        fontSize: '14px',
        boxShadow: 'none'
    };

    if (isStringField) {
        o.backgroundColor = current !== initial ? "#19875430" : '';
        o.borderColor = current !== initial ? 'teal' : '';
        o.color = current !== initial ? 'teal' : '';
        o.fontWeight = current !== initial ? 500 : ''
    }
    else {
        o.backgroundColor = current < initial ? "#dc354530" : (current > initial ? "#19875430" : '');
        o.borderColor = current < initial ? '#dc3545' : (current > initial ? 'teal' : '');
        o.color = current < initial ? '#dc3545' : (current > initial ? 'teal' : '');
        o.fontWeight = current !== initial ? 500 : ''
    }

    return o;
}



function Refresh({ onClick, active }) {
    return <span onClick={onClick} className='fa fa-refresh ms-1' style={{
        transform: 'scale(.7)', cursor: 'pointer',
        pointerEvents: active ? 'auto' : 'none',
        opacity: active ? 1 : .3
    }}></span>
}





const OverdueReadings = () => {
    const
        pumps = useQueryState([]),
        outlets = useQueryState([]),
        toggle = useQueryState(),
        readings = useQueryState(),

        [outlet, setOutlet] = useState({}),
        [hasNoReading, setHasNoReading] = useState(),
        [date, setDate] = useState(Util.today(true)),

        [data, setData] = useState([]),

        { currentOutlet: outletId } = useContext(Util.UserContext),
        columns = useMemo(() => {
            let cols = pumps.data.map(o => ({
                name: o.uniqueName,
                selector(data, row, col) {
                    /** @type{{id: number, reading: number, name: string, price: number, initial: number }} pump */
                    const {
                        id,
                        $reading,
                        reading,
                        price,
                        $price,
                        attendant,
                        $attendant,
                    } = data[col];

                    return <div className='p-2' key={`${id}-${date}-${col}`}>

                        <div className="d-flex align-items-center">
                            <input type='number' name={`pump-${id}-reading`}
                                onFocus={e => e.target.select()}
                                onInput={e => {
                                    setData(data => {
                                        data[row][col].reading = Number(e.target.value);

                                        for (let i = row + 1; i < data.length; i++) {
                                            data[i][col].$reading = data[i - 1][col].reading;
                                        }

                                        return [...data];
                                    });
                                }}
                                className='form-control text-end' step={.01} required value={reading} style={applyStyle($reading, reading)} />

                            <Refresh active={$reading !== reading} onClick={() => {
                                setData(data => {
                                    data[row][col].reading = data[row][col].$reading;
                                    return [...data];
                                })
                            }} />
                        </div>

                        <div className="d-flex align-items-center">
                            <input type='number' name={`pump-${id}-price`} required
                                onFocus={e => e.target.select()}
                                onInput={e => {
                                    setData(data => {
                                        const value = Number(e.target.value);
                                        data[row][col].price = value;
                                        return [...data];
                                    })
                                }}
                                className='form-control text-end' min={0} step={.01} value={price} style={applyStyle($price, price)} />
                            <Refresh active={$price !== price} onClick={() => {
                                setData(data => {
                                    data[row][col].price = data[row][col].$price;
                                    return [...data];
                                })
                            }} />
                        </div>

                        <div className="d-flex align-items-center">
                            <input type='text' name={`pump-${id}-attendant`}
                                onFocus={e => e.target.select()}
                                onInput={e => {
                                    setData(data => {
                                        const value = e.target.value;
                                        data[row][col].attendant = value;
                                        return [...data];
                                    })
                                }}
                                className='form-control text-end' placeholder='Attendant' value={attendant || ''} style={applyStyle($attendant, attendant, true)} />
                            <Refresh active={$attendant !== attendant} onClick={() => {
                                setData(data => {
                                    data[row][col].attendant = data[row][col].$attendant;
                                    return [...data];
                                })
                            }} />
                        </div>
                    </div>
                }
            }));
            cols.push({
                name: '',
                selector: (data, row, col) => (
                    <>
                        <Hint hint={'Duplicate row'}>
                            <button type='button' onClick={e => handleDuplicateRow(e, row)} style={{ border: 0, outline: 0, height: '100%' }}>
                                <i className="fa fa-copy text-primary"></i>
                            </button>
                        </Hint>

                        {row > 0 && <Hint hint={'Delete row'} backgroundColor={'rgb(220,53,69)'}>
                            <button type='button' onClick={e => handleDeleteRow(e, row)} style={{ border: 0, outline: 0, height: '100%' }}>
                                <i className="fa fa-trash text-danger"></i>
                            </button>
                        </Hint>}
                    </>
                )
            });
            return cols;
        }, [outlet?.outletId, data, date]);


    useEffect(() => {
        if (!outlet.outletId) return;

        let str = Util.dateString(new Date(date));

        Util.request.get(`pumpsreadings/${outlet.outletId}/${str}/${str}`)
            .then(res => {

                if (res.status) {
                    pumps.success('Successful', res.data.pumps);

                    setResultToData(res.data.dates, res.data.pumps);

                } else {
                    pumps.failed('An unexpected error occured');
                }
            })

        pumps.busy();

    }, [date, outlet?.outletId]);



    useEffect(() => {
        Util.request.get('outlets')
            .then(res => {
                outlets.fromResponse(res);
                res.status && setOutlet(res.data[0]);
            });

        outlets.busy();
    }, []);



    useEffect(() => {
        /**@type {(e: KeyboardEvent) => void} */
        const fn = e => {
            e.preventDefault();
            if (e.code === "Backspace" && document.activeElement.tagName !== 'INPUT') {
                setData(data => {
                    if (data.length > 1) {
                        data.pop();
                        return [...data];
                    }
                    return data;
                });
            }
        };

        document.addEventListener('keyup', fn);

        return () => document.removeEventListener('keyup', fn);
    }, []);



    function setResultToData(dates = [], pumps = [], isRolloverData = false) {

        setHasNoReading(dates.length === 0);

        if (dates.length) {

            if (isRolloverData) dates[0].readings = dates[0].readings.slice(-1);


            setData(dates[0].readings.map(row => row.map(o => ({
                id: o.pumpId,
                reading: o.raw,
                $reading: o.raw,
                price: o.price,
                $price: o.price,
                attendant: o.attendant,
                $attendant: o.attendant,
            }))))

        } else {

            setData([
                pumps.map(o => ({
                    id: o.pumpId,
                    reading: 0,
                    $reading: 0,
                    price: 0,
                    $price: 0,
                    attendant: "",
                    $attendant: "",
                }))
            ]);
        }
    }



    function handleGenerateRollover() {

        if (!outlet.outletId || !hasNoReading) return;

        let prevDay = new Date(date);
        prevDay.setDate(prevDay.getDate() - 1);
        prevDay = Util.dateString(prevDay);

        Util.request.get(`pumpsreadings/${outlet.outletId}/${prevDay}/${prevDay}`)
            .then(res => {

                if (res.status) {
                    pumps.success('Successful', res.data.pumps);

                    setResultToData(res.data.dates, res.data.pumps, true);

                } else {
                    pumps.failed('An unexpected error occured');
                }
            })

        pumps.busy();

    }




    function toggleOutlet(action) {
        Util.request.post(`outlet/${outletId}/toggle/${action}`)
            .then(res => {

                if (res.status) {
                    toggle.success('Successful');
                    setOutlet(res.data);
                } else {
                    toggle.failed(res.message)
                }
            })

        toggle.busy();
    }



    function handleSetReading() {

        Util.request.post(`clearsetreading/${outlet.outletId}/${date}`, new FormData(document.forms[0]))
            .then((res) => {
                readings.fromResponse(res)
            })

        readings.busy()
    }




    function handleDuplicateRow(e, rowIndex) {
        e.preventDefault();

        setData(data => {
            let
                newRow = data[rowIndex].map(o => ({
                    ...o,
                    $reading: o.reading,
                    $attendant: o.attendant,
                    $price: o.price,
                }));

            const newData = [...data.slice(0, rowIndex), newRow, ...data.slice(rowIndex)];

            return newData;
        })
    }




    function handleDeleteRow(e, rowIndex) {
        e.preventDefault();

        setData(data => {

            const newData = data.filter(($, row) => row !== rowIndex);

            return newData;
        })
    }






    return (
        <CardLayout
            pageName='Meter readings'
            pageIcon='calculator'
            cardElement='form'
            cardFooter={<div className='d-flex justify-content-between align-items-center'>
                <Button type={'button'} text='Save' icon='save' attrs={{ 'data-bs-toggle': 'modal', 'data-bs-target': '#submit-modal' }} />

                <Button type={'button'} onClick={e => handleDuplicateRow(e, data.length - 1)} sm outline icon='plus' />
            </div>}
        >

            <readings.Busy />
            <readings.Modal />

            <pumps.Busy />


            <outlets.Busy />


            <toggle.Busy />
            <toggle.Modal />


            <Dialog on={() => toggleOutlet('disable')} id='disable-modal' title={'Disable outlet'} type="danger" primary={{ text: 'Disable', icon: 'minus' }}>
                <p>Are you sure you want to <b>Disable</b> this outlet?</p>
                <b>{outlet.outletName}</b>
            </Dialog>

            <Dialog on={handleSetReading} id='submit-modal' title={'Submit reading?'} type="danger" primary={{ text: 'Continue', icon: 'check' }}>
                <p>Are you sure you want to submit these changes for <b>{outlet.outletName}</b>?</p>
                <p><b>Note:</b> This will delete all meter readings submitted on and after {date}</p>
            </Dialog>

            <Dialog on={() => toggleOutlet('enable')} id='enable-modal' title={'Enable outlet'} primary={{ text: 'Enable', icon: 'check' }}>
                <p>Are you sure you want to <b>Enable</b> this outlet?</p>
                <b>{outlet.outletName}</b>
            </Dialog>


            <div className="d-flex align-items-end">
                <SelectInput value={outlet.outletId} onChange={e => setOutlet(outlets.data.find(o => o.outletId === Number(e.target.value)))} labelText={'Select Outlet'} data={outlets.state.data} keys={['outletId', 'outletName']} name={'outlet'} />

                <div className="mx-2">
                    <InputField type="date" name='date' attrs={{ value: date, onChange: e => setDate(e.target.value) }} />
                </div>

                <div className="flex-grow-1"></div>


                <div className="d-flex align-items-center" style={{ transform: 'scale(.9)', transformOrigin: 'bottom right' }}>

                    {
                        hasNoReading && (
                            <button type='button' onClick={handleGenerateRollover} className="btn btn-sm btn-outline-primary">
                                <i className="fa fa-list me-2"></i>
                                Get rollover
                            </button>
                        )
                    }

                    <button data-bs-toggle='modal' data-bs-target='#disable-modal' type='button' className="btn btn-sm btn-danger ms-2">
                        <i className="fa fa-times me-2"></i>
                        Disable outlet
                    </button>
                    <button data-bs-toggle='modal' data-bs-target='#enable-modal' type='button' className="btn btn-sm btn-success ms-2">
                        <i className="fa fa-check me-2"></i>
                        Enable outlet
                    </button>
                </div>
            </div>



            <div className="my-4">
                <div className="d-flex align-items-center">
                    <h6 className='m-0 p-0'>Selected Outlet:</h6>
                    <div className='m-0 p-0 ms-1'>{outlet.outletName}</div>
                </div>
                <div className="d-flex align-items-center">
                    <h6 className='m-0 p-0'>Status:</h6>
                    <div className='m-0 p-0 ms-1' style={{ transform: 'scale(.7)' }}>{
                        outlet.disabled ?
                            <small className='d-inline-block p-2 bg-danger text-white rounded'>Disabled</small> :
                            <small className='d-inline-block p-1 bg-success text-white rounded'>Enabled</small>
                    }</div>
                </div>
            </div>



            <ScrollEndTrigger maxHeight='390px'>
                <Table2 data={data} columns={columns} />
            </ScrollEndTrigger>
        </CardLayout>
    )
}

export default OverdueReadings



