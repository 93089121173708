import React from 'react'
import style from './styles.module.css';

export default function Hint({ hint, children, color, backgroundColor }) {
    return (
        <div className={style.hint} >
            {children}
            <div className={style.popup} style={{ color, backgroundColor }}>
                {hint}
            </div>
        </div>
    )
}
