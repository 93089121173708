import React, { useContext, useEffect, useState } from 'react';
import CardLayout from '../layouts/CardLayout';
import Util from '../Util';
import Busy from '../components/Busy';
import { ErrorStatusModal, SuccessStatusModal } from '../components/StatusModal';
import Button from '../components/Button';
import InputField from '../components/InputField';
import DatetimeFields from '../components/DatetimeFields';
import ActionHeader from '../components/ActionHeader';


function OverdueTeller(props) {
    const
        [state, setState] = useState({

            uploadingTeller: false,
            uploadingTellerDone: false,
            uploadingTellerError: false,
            uploadingTellerText: '',
            errors: {}
        }),
        [date, setDate] = useState(Util.today(true)),
        { currentOutlet = 0} = useContext(Util.UserContext);




    const handleSubmit = (e) => {
        e.preventDefault();


        let fd = new FormData(e.target);
        fd.append(Util.overdueKey, true);

        Util.request.post(`overdue/${currentOutlet}/teller`, fd)
            .then(res => {
                setState(o => ({
                    ...o,
                    uploadingTeller: false,
                    uploadingTellerDone: true,
                    uploadingTellerError: !res.status,
                    uploadingTellerText: typeof res.message === 'string' ? res.message : '',
                    errors: typeof res.message === 'string' ? {} : res.message
                }))
            })

        setState(o => ({ ...o, uploadingTeller: true }))

    };

    const actionButton = <Button icon='upload' text='Upload' />;

    return (
        <CardLayout
            cardElement='form'
            cardElementProps={{ onSubmit: handleSubmit }}

            pageName='upload teller' pageIcon='upload'
            cardFooter={actionButton}
            cardHeader={<ActionHeader title='OVERDUE TELLER UPLOAD' />}
        >

            <Busy busy={state.uploadingTeller} busyText='Please wait...' />

            <ErrorStatusModal
                show={state.uploadingTellerDone && state.uploadingTellerError}
                onDone={() => setState(o => ({ ...o, uploadingTellerDone: false }))}
                text={state.uploadingTellerText} />

            <SuccessStatusModal
                show={state.uploadingTellerDone && !state.uploadingTellerError}
                onDone={() => setState(o => ({ ...o, uploadingTellerDone: false }))}
                text={state.uploadingTellerText} />


            <div className="mb-3">
                <DatetimeFields date={date}  onDateChange={setDate} />
            </div>



            <div className='mb-3'>
                <InputField errs={state.errors} name='amount' labelText='Amount' labelIcon='money' attrs={{ type: 'number', min: 0 }} />
            </div>



            <div className='mb-3'>
                <label className='form-label text-muted'>Comment</label>
                <textarea className='form-control' name='comment'></textarea>
            </div>


        </CardLayout>
    );
}

export default OverdueTeller;