import React, { useContext, useEffect, useRef, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import Busy from '../components/Busy';
import Button from '../components/Button';
import ScrollEndTrigger from '../components/ScrollEndTrigger';
import Table from '../components/Table';
import CardLayout from '../layouts/CardLayout';
import Paths from '../Paths';
import Util from '../Util';
import Modal from '../components/Modal';
import { useQueryState } from '../hooks';
import ActionHeader from '../components/ActionHeader';


function PostpaidPurchaseHistory(props) {
    const
        [state, setState] = useState({
            gettingHistory: false,
            gettingHistoryDone: false,
            gettingHistoryMessage: '',
            gettingHistoryFailed: false,

            data: { history: [], customer: { name: '' } },

        }),
        deleter = useQueryState(),
        deleteRef = useRef(0),

        { customerId = 0 } = useParams(),

        navigate = useNavigate(),
        { user: { position } } = useContext(Util.UserContext);


    useEffect(() => {

        Util.request.get(`ppcustomer/debts/${customerId}`)
            // -DMF
            .then(res => {
                setState(o => ({
                    gettingHistory: false,
                    gettingHistoryDone: true,
                    gettingHistoryFailed: !res.status,
                    gettingHistoryMessage: res.message,
                    data: res.status ? res.data : state.data
                }))
            })

        setState(o => ({ ...o, gettingHistory: true }));
    }, [deleteRef.current]);

    function handleDeleteEntry() {
        Util.request.post(`ppcustomer/debts/${customerId}/delete`)
            .then(res => {
                ++deleteRef.current;
                deleter.fromResponse(res)
            })
        deleter.busy('Deleting purchase entry.');
    }

    return (
        <CardLayout
            pageName="postpaid Customer debts"
            pageIcon="clock"
            cardFooter={<div className='d-flex justify-content-end'>
                <a href='#' data-bs-target='#modal-delete-entry' data-bs-toggle='modal' className='text-danger text-decoration-none'><b>Delete last entry</b></a>
            </div>}
            cardHeader={<ActionHeader title={`${state.data.customer.name}(DEBTS)`} actions={[{
                icon: 'money-bill',
                text: 'Make purchase',
                onClick: e => navigate(Paths.POSTPAID.join(Paths.POSTPAID_PURCHASE.parse(customerId)).abs.$),
                showIf: position.canMakePurchase,
            }]} />
            }>

            <Busy busy={state.gettingHistory} busyText='Retrieving purchase history...' />

            <Modal on={handleDeleteEntry} type='danger' id='modal-delete-entry' title={'Delete entry?'}>
                <p>Are you sure you want to delete last entry?</p>
                {/* <i className='text-warning'><b>Note:</b> This operation will fail if any purchase has been made since last subscription was created.</i> */}
            </Modal>

            <deleter.Busy />
            <deleter.Modal />

            <ScrollEndTrigger maxHeight='380px'>
                <Table
                    keys={['date', 'time', 'product.name', 'price', 'discount', 'litres', 'amount', 'check']}
                    tableHead={['Date', 'Time', 'Product', 'Purchase price', 'Discount', 'Litres', 'Amount', '']}
                    data={state.data.history.map(_h => {
                        let h = { ..._h };
                        h.price = `N${Util.format(h.price)}`;
                        h.litres = Util.format(h.litres);
                        h.amount = `N${Util.format(h.amount)}`;
                        h.balance = `N${Util.format(h.balance)}`;

                        return h;
                    })}
                    padRight={true}
                    tableFoot={[`Total: N${Util.format(state.data.history.reduce((acc, o) => acc + o.amount, 0))}`]}
                />
            </ScrollEndTrigger>
        </CardLayout>
    );
}


export default PostpaidPurchaseHistory;