import React, { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Busy from '../components/Busy';
import Button from '../components/Button';
import ScrollEndTrigger from '../components/ScrollEndTrigger';
import Table from '../components/Table';
import CardLayout from '../layouts/CardLayout';
import Paths from '../Paths';
import Util from '../Util';
import ActionHeader from '../components/ActionHeader';


function Users(props) {
    const
        [state, setState] = useState({
            gettingUsers: false,
            gettingUsersError: false,
            gettingUsersText: '',
            users: null
        }),

        { user } = useContext(Util.UserContext),

        navigate = useNavigate();

    useEffect(() => {
        if (state.users !== null) return;

        Util.request.get('users')
            .then(res => setState(o => ({
                ...o,
                gettingUsers: false,
                gettingUsersError: !res.status,
                gettingUsersText: res.message,
                users: res.status ? res.data : false
            })));

        setState(o => ({ ...o, gettingUsers: true }))
    }, []);


    return (
        <CardLayout
            pageName="Users"
            pageIcon="users"
            cardHeader={<ActionHeader actions={[{
                showIf: user.position.canCreateUsers,
                text: 'Create user',
                icon: 'plus',
                onClick: () => navigate(Paths.USERS.join(Paths.CREATE_USER).abs.$)

            }]} />}>


            <Busy busy={state.gettingUsers} gettingUsersText='Fetching users...' />


            <ScrollEndTrigger maxHeight='380px'>
                <Table
                    keys={['lastname', 'firstname', 'position.name']}
                    tableHead={['Lastname', 'Firstname', 'Position']} data={state.users}
                    actions={{ icon: 'eye', text: 'View', on: data => navigate(Paths.STAFF.join(Paths.VIEW_STAFF.parse(data.username)).abs.$) }}
                />
            </ScrollEndTrigger>
        </CardLayout>
    );
}

export default Users;