const p = {};

p.PATH_PREFIX = 'app';

class _Path {
    constructor(path, prefix='') {
        this.path = path;
        this.prefix = prefix;

        // below checks to see for the absence of a url param
        // and overrides the function so that it instantly 
        //returns the same _Path instance instead.
        if (path.search(/:\w+/) < 0)
            this.parse = function(){return this;}
    }

    parse(...values) {
        const path = this.path;
        let newPath = '', gaps = path.split(/:\w+/);

        values = values.slice(0, gaps.length - 1);

        for (let i = 0; i < gaps.length; ++i)
            newPath += gaps[i] + (values[i] || '');

        return new _Path(newPath, '');
    }

    params(o) {
        const args = Object.entries(o).map(e=>`${e[0]}=${e[1]}`).join('&');
        if ( ! args.length ) return this;
        return new _Path(`${this.path}?${args}`);
    }

    get bare() {
        return this.path;
    }

    get abs(){
        return new _Path(`/${this.path}`); 
    }

    join(..._path) {
        return new _Path(`${this.path}/${_path.map(p=>p.path).join('/')}`, '')
    }

    toString() {
        return this.path;
    }

    [Symbol.toPrimitive](hint) {
        return this.toString();
    }

    get str(){
        return this.toString();
    }

    get $(){
        return this.toString();
    }
}

const createPath = (path)=>new _Path(path);

p.SELECT_OUTLET = createPath('selotl');

p.MAKE_ANALYSIS = createPath('mkanals');
p.ANALYSIS = createPath('anals');
p.UPDATE_CASHBOOK = createPath('upcb');
p.AUDITING = createPath('audt');


p.CASHBOOK = createPath('cshbk');
p.CASHBOOK_HISTORY = createPath('chist');
p.CASHBOOK_ENTRIES = createPath('ntry');

p.EXPENSES = createPath('exp');
p.EXPENSES_HISTORY = createPath('ehist');
p.EXPENSES_ENTRIES = createPath('ntry');

p.CREDITS = createPath('cdt');
p.CREDITS_HISTORY = createPath('hist');
p.CREDITS_ENTRIES = createPath('ntry');

p.CASHBOOKS = createPath('casbks');

p.DAILY_SALES = createPath('sales');
p.SALES_HISTORY = createPath('shist');
p.SET_METER_READING = createPath('mtrrdn');
p.METER_READINGS = createPath('rdn');
p.PUMP_READING = createPath('pmpRdn/:pumpId');
p.PUMP_MODIFY = createPath('pmpMod/:pumpId');
p.READINGS_ROLLOVER = createPath('rdnrol');
p.CLEAR_READINGS = createPath('clrdn');
p.SLIPS = createPath('slps');
p.ADD_SLIP = createPath('mkslps');


p.TOTALIZERS = createPath('ttlz/:pumpId');
p.RESET_TOTALIZER = createPath('ttlz/:pumpId/set');

p.DASHBOARD = createPath('/');
p.OUTLET = createPath('otl');
p.CREATE_OUTLET = createPath('create');
p.OUTLETS = createPath('otls');
p.PUMPS = createPath('pumps');
p.CREATE_PUMP = createPath('crt');
p.ASSIGN_PUMP = createPath('passgn');

p.STOCK = createPath('stks');
p.STOCK_HISTORY = createPath('hist');
p.LOGOUT = createPath('logout');
p.LOGIN = createPath('login');
p.CHANGE_PASSWORD = createPath('chgpas');

p.SUPPLY = createPath('spl/:tankId');
p.SUPPLY_HISTORY = createPath('supplyHist');
p.SUPPLY_ENTRIES = createPath('supplyNtry/:supplyId');

p.USERS = createPath('usrs');
p.CREATE_USER = createPath('create');

p.TANKS = createPath('tnks');
p.TANK_VIEW = createPath('tnk/:tankId');
p.CREATE_TANK = createPath('createtank');
p.TANK_TRANSFER = createPath('ttfr');
p.TANK_TRANSFER_HISTORY = createPath('tfhist');



p.TELLERS = createPath('tllrs');
p.TELLERS_VERIFY = createPath('vfy');
p.UPLOAD_TELLER = createPath('upload');
p.PENDING_TELLERS = createPath('pndn');



p.PRODUCT_TYPE = createPath('ptyp');
p.PRODUCT_TYPE_VIEW = createPath('ptview/:id');
p.CREATE_PROUDCT_TYPE= createPath('createptype');

p.RECORDS_HISTORY = createPath('rechist');
p.RECORDS_ENTRIES = createPath('recntry/:entry');



p.CASH_REMIT = createPath('crmt');
p.CASH_REMIT_VIEW = createPath('view/:remitId');
p.CASH_REMIT_HISTORY = createPath('hist');

p.STAFF = createPath('staff');
p.CREATE_STAFF = createPath('c');
p.VIEW_STAFF = createPath('v/:username');

p.PROFILE = createPath('prf');


p.PREPAID = createPath('prp');
p.CUSTOMERS = createPath('custms');
p.NEW_CUSTOMER = createPath('mkcust');
p.SUBSCRIPTIONS = createPath('subs/:customerId');
p.SUBSCRIBE = createPath('mksub/:customerId');
p.PURCHASE = createPath('pchs/:customerId');
p.PURCHASES = createPath('pchses/:customerId');
p.TRANSFER_FUNDS = createPath('transfnds/:customerId');


p.POSTPAID = createPath('psp');
p.POSTPAID_PURCHASES = createPath('pspp/:customerId');
p.POSTPAID_CLEARED_PURCHASES = createPath('pspcp/:customerId');
p.POSTPAID_PURCHASE = createPath('mkpsp/:customerId');
p.POSTPAID_MAKE_PAYMENT = createPath('mkpmt/:customerId');
p.NEW_POSTPAID = createPath('mkpsp');


p.OVERDUE = createPath('ovd');





const Paths = p;

export default Paths;


