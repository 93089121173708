import React, { useContext, useEffect, useState } from 'react';
import CardLayout from '../layouts/CardLayout';
import Util from '../Util';
import Busy from '../components/Busy';
import ScrollEndTrigger from '../components/ScrollEndTrigger';
import { ErrorStatusModal, SuccessStatusModal } from '../components/StatusModal';
import InputField from '../components/InputField';
import Button from '../components/Button';
import { useNavigate } from 'react-router-dom';
import Paths from '../Paths';
import Table from '../components/Table';


function TankTransferHistory(props) {
    const
        [state, setState] = useState({
            retrievingTransfersFailed: false,
            retrievingTransfersDone: false,
            retrievingTransfersText: '',
            retrievingTransfers: false,

            transfers: null

        }),
        { currentOutlet } = useContext(Util.UserContext),
        navigate = useNavigate();


    useEffect(() => {
        if (state.transfers !== null) return;

        Util.request.get(`tanktransfers/${currentOutlet}`)
            .then(res=>setState(o=>({
                ...o,
                retrievingTransfers: false,
                retrievingTransfersDone: true,
                retrievingTransfersFailed: ! res.status,
                retrievingTransfersText: res.message,
                transfers: res.status? res.data : []
            })))
            

        setState(o => ({ ...o, retrievingTransfers: true }))
    }, []);



    return (
        <CardLayout
            pageName='Tank transfer' pageIcon='arrow-to-right'
            >

            <Busy busy={state.retrievingTransfers} busyText='Retreiving transfer...' />

            <ErrorStatusModal 
                show={state.retrievingTransfersDone && state.retrievingTransfersFailed}
                onDone={() => setState(o => ({ ...o, retrievingTransfersDone: false }))}
                text={state.retrievingTransfersText} />



            <ScrollEndTrigger maxHeight='390px'>
                {
                    state.transfers && 
                    <Table 
                        data={state.transfers} 
                        tableHead={['Date', 'Time', 'From', 'To', 'Quantity transfered']}
                        keys={['date', 'time', 'tankFrom.tankName', 'tankTo.tankName', 'quantity']}
                    />
                }
            </ScrollEndTrigger>


        </CardLayout>
    );
}


export default TankTransferHistory;