import React, { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Busy from '../components/Busy';
import Button from '../components/Button';
import ScrollEndTrigger from '../components/ScrollEndTrigger';
import Table from '../components/Table';
import CardLayout from '../layouts/CardLayout';
import Paths from '../Paths';
import Util from '../Util';
import ActionHeader from '../components/ActionHeader';


function PostpaidCustomers(props) {
    const
        [state, setState] = useState({
            customers: [],
            gettingCustomers: false,
            gettingCustomersDone: false,
            gettingCustomersMessage: false,
            gettingCustomersFailed: false
        }),

        { currentOutlet, user: { position } } = useContext(Util.UserContext),

        navigate = useNavigate();


    useEffect(() => {

        Util.request.get(`ppcustomer/list/${currentOutlet}`)
            .then(res => setState(o => ({
                customers: res.status ? res.data : state.customers,
                gettingCustomers: false,
                gettingCustomersDone: true,
                gettingCustomersMessage: res.message,
                gettingCustomersFailed: !res.status
            })));

        setState(o => ({ ...o, gettingCustomers: true }))
    }, []);


    return (
        <CardLayout
            pageName="postpaid customers"
            pageIcon="receipt"
            cardHeader={<ActionHeader title='Postpaid' actions={[{
                onClick: () => navigate(Paths.POSTPAID.join(Paths.NEW_POSTPAID).abs.$),
                showIf: position.canCreateCustomer,
                text: 'New postpaid',
                icon: 'plus',

            }]} />
        
        }>


            <Busy busy={state.gettingCustomers} busyText='Retrieving customers...' />

            <ScrollEndTrigger maxHeight='380px'>
                <Table
                    data={state.customers.map(o => { o._balance = 'N' + Util.format(o.balance); return o })}
                    keys={['date', 'name', 'phone', '_balance']}
                    tableHead={['Date created', 'Names', 'Phones', 'Unpaid balance']}
                    actions={[
                        o => ({ icon: 'minus', text: 'Purchase', on: () => navigate(Paths.POSTPAID.join(Paths.POSTPAID_PURCHASES.parse(o.id)).abs.$) }),
                        o => ({ icon: 'check', text: 'Paid', on: () => navigate(Paths.POSTPAID.join(Paths.POSTPAID_CLEARED_PURCHASES.parse(o.id)).abs.$) }),
                    ]}
                />
            </ScrollEndTrigger>
        </CardLayout>
    );
}


export default PostpaidCustomers;