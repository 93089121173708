import React, { useContext, useEffect, useRef, useState } from 'react';
import Busy from '../components/Busy';
import ScrollEndTrigger from '../components/ScrollEndTrigger';
import { ErrorStatusModal, SuccessStatusModal } from '../components/StatusModal';
import Table from '../components/Table';
import CardLayout from '../layouts/CardLayout';
import Util from '../Util';
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import Paths from '../Paths';
import Button from '../components/Button';
import InputField from '../components/InputField';

function CreateProductTypes(props) {

    const
        [state, setState] = useState({

            creatingType: false,
            creatingTypeDone: false,
            creatingTypeError: false,
            creatingTypeText: '',
            errs: {}
        });


    const handleSubmit = e => {
        e.preventDefault();

        Util.request.post('ptypes/create', new FormData(e.target))
            .then(res => {
                setState({
                    creatingType: false,
                    creatingTypeError: !res.status,
                    creatingTypeDone: true,
                    creatingTypeText: typeof res.message === 'string' ? res.message : '',
                    errs: typeof res.message === 'string' ? {} : res.message
                })
            });

        setState(o => ({ ...o, creatingType: true }));
    };

    return (
        <CardLayout
            pageName='Create product type' pageIcon='plus'
            cardFooter={<Button text='create' icon='plus' />}
            cardElement='form'
            cardElementProps={{ onSubmit: handleSubmit }}
        >

            <Busy busy={state.creatingType} busyText='Creating product type...' />

            <SuccessStatusModal
                onDone={() => setState(o => ({ ...o, creatingTypeDone: false }))}
                show={state.creatingTypeDone && !state.creatingTypeError} text={state.creatingTypeText} />


            <div className='mb-4'>
                <InputField name='name' errs={state.errs} labelText='Type name' />
            </div>



            <div className='mb-4'>
                <InputField
                    name='price_per_litre' errs={state.errs}
                    labelText='Price per litre'
                    attrs={{ type: 'number', min: 0, defaultValue: 0 }}
                />
            </div>


        </CardLayout>
    );
}


export default CreateProductTypes;