import React from 'react';
import PageHeader from '../layouts/PageHeader';

function CardLayout({cardElement, cardElementProps, pageName, pageIcon, cardHeader, cardFooter, children, cardIcon, }) {
    const
        element = typeof cardElement === 'string' ? cardElement : 'div',
        elementProps = cardElementProps ? cardElementProps : {};

    if (!elementProps.style || typeof elementProps.style !== 'object')
        elementProps.style = {};

    elementProps.style = { ...elementProps.style, maxHeight: '100%' };

    return (
        <>
            <PageHeader pageName={pageName} icon={pageIcon} />
            <div className='flex-grow-1 overflow-hidden mt-4 position-relative'>
                {React.createElement(element, { className: 'card bg-color-highlight position-relative', ...elementProps },
                    <>
                        {cardHeader && <div className='card-header'>
                            {cardIcon && <i className={`fa fa-${cardIcon}`}></i>}
                            <span>{cardHeader}</span>
                        </div>}
                        <div className='card-body' style={{ overflow: 'auto' }}>
                            {children}
                        </div>
                        {cardFooter && <div className='card-footer'>{cardFooter}</div>}
                    </>
                )}
               
            </div>
        </>
    );
}

export default CardLayout;