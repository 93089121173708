import React, { useContext, useEffect, useRef, useState } from 'react';
import Busy from '../components/Busy';
import ScrollEndTrigger from '../components/ScrollEndTrigger';
import Table from '../components/Table';
import CardLayout from '../layouts/CardLayout';
import Util from '../Util';
import Button from '../components/Button';
import InputField from '../components/InputField';
import Modal from '../components/Modal';
import { ErrorStatusModal, SuccessStatusModal } from '../components/StatusModal';
import SelectInput2 from '../components/SelectInput2';


// All the cashbooks by dates
function RecordsHistory(props) {

    const
        [date, setDate] = useState(new Date()),
        [state, setState] = useState(
            {
                gettingRecordsDone: false,
                gettingRecords: false,
                gettingRecordsError: false,
                gettingRecordsText: '',


                modifyingRecordsDone: false,
                modifyingRecords: false,
                modifyingRecordsError: false,
                modifyingRecordsText: '',


                deletingRecordsDone: false,
                deletingRecords: false,
                deletingRecordsError: false,
                deletingRecordsText: '',

                recordTypeKey: null,

                unpaidCredit: []
            }),
        { currentOutlet, user: { position } } = useContext(Util.UserContext);


    useEffect(() => {

        if (state.recordTypeKey === null) {

            setState(o => ({ ...o, recordTypeKey: Object.keys(recordTypes.current)[0] }))
        }

    }, []);


    let
        recordTypes = useRef({
            cashbookentries: { type: 'Cashbook history', records: [[]] },
            creditentries: { type: 'Credit history', records: [[]] },
            expenseentries: { type: 'Expenses history', records: [[]] },
        }),
        { current: creditPaidCount } = useRef({ $: 0 });



    useEffect(() => {
        Util.request.get(`credithistory/${currentOutlet}/unpaid`)
            .then(res => {
                setState(o => ({
                    ...o,
                    gettingRecords: false,
                    unpaidCredit: res.status ? res.data : state.unpaidCredit
                }));
            })

        setState(o => ({ ...o, gettingRecords: true }));
    }, [creditPaidCount.$]);



    function handleGetRecords(e) {

        e?.preventDefault();

        if (state.recordTypeKey === null || state.gettingRecords) return;

        //this approad is done for the closure of recordTypeKey,
        //should it change its value in the state, we don't worry about that.
        (function () {
            let currentKey = state.recordTypeKey,
                o = recordTypes.current[currentKey],
                _date = Util.dateString(date);

            Util.request.get(`${currentKey}/${currentOutlet}/${_date}`)
                .then(res => {
                    if (res.status) o.records = res.data;

                    if (o.records.length === 0) o.records = [[]];

                    setState(o => ({
                        ...o,
                        gettingRecords: false,
                        gettingRecordsDone: true,
                        gettingRecordsError: !res.status,
                        gettingRecordsText: res.message,
                    }))
                });

            setState(o => ({ ...o, gettingRecords: true }));
        })();
    }




    function handleEditRecords(e) {
        e.preventDefault();

        const ds = e.target.dataset, fd = new FormData(e.target);

        Util.request.post(`modify${ds.type}/${ds.id}`, fd)
            .then(res => {
                if (res.status)
                    for (const groupRecords of recordTypes.current[state.recordTypeKey].records)
                        for (const record of groupRecords)
                            if (record.id === Number(ds.id)) {
                                record.subhead = fd.get('subhead');
                                record.amount = Number(fd.get('amount'));
                            }

                setState(o => ({
                    ...o,
                    modifyingRecords: false,
                    modifyingRecordsDone: true,
                    modifyingRecordsError: !res.status,
                    modifyingRecordsText: res.message
                }));

                handleGetRecords()
            });

        setState(o => ({ ...o, modifyingRecords: true }));
    }



    function handleDeleteRecord(id, type) {

        Util.request.post(`delete${type}/${id}`)
            .then(res => {
                if (res.status) {
                    const recs = recordTypes.current[state.recordTypeKey].records
                    for (let i = 0; i < recs.length; ++i)
                        recs[i] = recs[i].filter(o => o.id !== id);
                }

                setState(o => ({
                    ...o,
                    deletingRecords: false,
                    deletingRecordsDone: true,
                    deletingRecordsError: !res.status,
                    deletingRecordsText: res.message
                }))
            });

        setState(o => ({ ...o, deletingRecords: true }))
    }




    function handleCreditCleared(record) {


        Util.request.post(`setcreditpaid/${record.id}`)
            .then(res => {

                if (res.status) {
                    record.dateRepaid = res.data.dateRepaid;
                    ++creditPaidCount.$;
                }

                setState(o => ({
                    ...o,
                    modifyingRecords: false,
                    modifyingRecordsDone: true,
                    modifyingRecordsError: !res.status,
                    modifyingRecordsText: res.message
                }));

            });

        setState(o => ({ ...o, modifyingRecords: true }));
    }




    const cardHeader = <form className='d-flex align-items-center' onSubmit={handleGetRecords}>
        <select
            className='form-select flex-grow-1 me-3'
            defaultValue={state.recordTypeKey}
            onChange={e => setState(o => ({ ...o, recordTypeKey: e.target.selectedOptions[0].value }))}>
            {Object.entries(recordTypes.current).map(([url, { type }]) => <option key={url} value={url}>{type}</option>)}
        </select>

        <InputField type='date' name='date' attrs={{ onChange: e => setDate(new Date(e.target.value))}} value={Util.dateString(date)} />
        <i className='mx-2'></i>
        <Button text='Get records' icon='list' />
    </form>;


    return (
        <CardLayout
            pageName='Cashbook history'
            pageIcon='clock'
            cardHeader={cardHeader}
        >
            <Busy busy={state.gettingRecords} fixed text={`Retrieving records...`} />

            <Busy busy={state.modifyingRecords} fixed text={`Modifying record...`} />

            <Busy busy={state.deletingRecords} fixed text={`Deleting record...`} />



            <SuccessStatusModal
                show={state.modifyingRecordsDone && !state.modifyingRecordsError}
                onDone={() => setState(o => ({ ...o, modifyingRecordsDone: false }))}
                text={state.modifyingRecordsText} />

            <ErrorStatusModal
                show={state.modifyingRecordsDone && state.modifyingRecordsError}
                onDone={() => setState(o => ({ ...o, modifyingRecordsDone: false }))}
                text={state.modifyingRecordsText} />





            <SuccessStatusModal
                show={state.deletingRecordsDone && !state.deletingRecordsError}
                onDone={() => setState(o => ({ ...o, deletingRecordsDone: false }))}
                text={state.deletingRecordsText} />

            <ErrorStatusModal
                show={state.deletingRecordsDone && state.deletingRecordsError}
                onDone={() => setState(o => ({ ...o, deletingRecordsDone: false }))}
                text={state.deletingRecordsText} />


            {
                state.recordTypeKey &&
                recordTypes.current[state.recordTypeKey].records.reduce((ar, records) => [...ar, ...records], [])
                    .map((rec, i) => {
                        return (
                            <div key={i}>

                                {
                                    position.canEnterCredits &&
                                    <Modal
                                        id={`debt-paid-modal-${rec.recordType}-${rec.id}`}
                                        title={`Clear credit?`}
                                        on={() => handleCreditCleared(rec)}>
                                        <p className='m-0'>Confirm that this credit has been cleared?</p>
                                        <p className="m-0 text-primary">{rec.subhead} (N{Util.format(rec.amount)})</p>
                                        <small className='text-muted small'><b>Note:</b> This can not be undone.</small>
                                    </Modal>
                                }

                                {position.canMutateRecords && <>
                                    <Modal
                                        id={`${rec.recordType}-${rec.id}`}
                                        title={`Delete record`} type='danger'
                                        on={() => handleDeleteRecord(rec.id, rec.recordType.toLowerCase())}>
                                        Are you sure you want to delete <b>{rec.subhead}</b> ?
                                    </Modal>

                                    <div className="modal fade" id={`edit-${rec.recordType}-${rec.id}`} data-bs-backdrop="static" data-bs-keyboard="false" tabIndex="-1" aria-hidden="true">
                                        <div className="modal-dialog">
                                            <form
                                                key={rec.id + rec.recordType}
                                                data-type={rec.recordType.toLowerCase()}
                                                data-id={rec.id}
                                                className="modal-content"
                                                onSubmit={handleEditRecords}>
                                                <div className="modal-header">
                                                    <h4 className="modal-title fs-6 ">Edit record</h4>
                                                    <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                                </div>
                                                <div className="modal-body">
                                                    <h1 className={`fs-4 text-muted text-center`}>
                                                        <i className={`fa fa-edit`}></i>
                                                    </h1>
                                                    <div className='my-3'>
                                                        <InputField type={'date'} key={rec.id + rec.recordType} name='date' value={Util.dateString(new Date(rec.date))} labelText='Date' />
                                                    </div>

                                                    <div className='my-3'>
                                                        <InputField key={rec.id + rec.recordType} name='subhead' value={rec.subhead} labelText='Subhead' />
                                                    </div>

                                                    {

                                                        rec.recordType === 'Cashbook' && (
                                                            <div className='my-3'>
                                                                <SelectInput2
                                                                    label='Type' key={rec.id + rec.recordType}
                                                                    data={Util.CASHBOOK_TYPES} name='type' defaultValue={rec.type}
                                                                    option={o => ({ value: o.name, children: o.name })}
                                                                />
                                                            </div>
                                                        )
                                                    }

                                                    <InputField key={rec.id + rec.recordType} attrs={{ step: '.0001' }} value={rec.amount} name='amount' type='number' labelText='Amount' />
                                                </div>
                                                <div className="modal-footer">
                                                    <button type="button" className="btn btn-outline-secondary btn-sm border-0" data-bs-dismiss="modal">
                                                        <i className={`fa fa-times me-2`}></i>
                                                        Cancel
                                                    </button>
                                                    <button onClick={e => e.target.previousElementSibling.click()} className={`fw-bold btn btn-primary btn-sm outline-0 border-0`}>
                                                        <i className={`fa fa-edit me-2`}></i>
                                                        Edit
                                                    </button>
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                </>
                                }

                            </div>
                        )
                    })
            }

            <ScrollEndTrigger maxHeight='380px'>

                {state.recordTypeKey === 'creditentries' && <>

                    {
                        state.unpaidCredit.map((o, i) => <Modal
                            key={i}
                            id={`mini-modal-debt-paid-modal-${o.recordType}-${o.id}`}
                            title={`Clear credit?`}
                            on={() => handleCreditCleared(o)}>
                            <p className='m-0'>Confirm that this credit has been cleared?</p>
                            <p className="m-0 text-primary">{o.subhead} (N{Util.format(o.amount)})</p>
                            <small className='text-muted small'><b>Note:</b> This can not be undone.</small>
                        </Modal>)
                    }

                    <div className="modal fade" id="unpaid-credit" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
                        <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable modal-xl">
                            <div className="modal-content">
                                <div className="modal-header">
                                    <h5 className="m-0 fs-6" id="staticBackdropLabel">All unpaid credit</h5>
                                    <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                </div>
                                <div className="modal-body">
                                    <div className="table-responsive">
                                        <table className="table table-striped table-hover table-bordered">
                                            <thead className='text-primary'>
                                                <tr>
                                                    <th></th>
                                                    <th>Date</th>
                                                    <th>Time</th>
                                                    <th>Subhead</th>
                                                    <th>Amount</th>
                                                    <th></th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {
                                                    !state.unpaidCredit.length ?
                                                        <tr><td colSpan={99}><h6 className='text-muted text-center m-0'><small>You have no unpaid credit</small></h6></td></tr>
                                                        :
                                                        state.unpaidCredit.map((o, i) => <tr key={i}>
                                                            <th>{i + 1}</th>
                                                            <td>{o.date}</td>
                                                            <td>{o.time}</td>
                                                            <td>{o.subhead}</td>
                                                            <td>N{Util.format(o.amount)}</td>
                                                            <td>
                                                                <Button outline secondary sm icon='check' attrs={{
                                                                    ['data-bs-toggle']: 'modal',
                                                                    ['data-bs-target']: `#mini-modal-debt-paid-modal-${o.recordType}-${o.id}`
                                                                }} />
                                                            </td>
                                                        </tr>)
                                                }
                                            </tbody>
                                            <tfoot>
                                                <tr>
                                                    <td></td>
                                                </tr>
                                            </tfoot>
                                        </table>
                                    </div>
                                </div>
                                <div className="modal-footer">
                                    <button type="button" className="btn btn-outline-secondary btn-sm " data-bs-dismiss="modal">Close</button>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="text-end">
                        <Button sm secondary outline text='View unpaid credits'
                            attrs={{ 'data-bs-target': '#unpaid-credit', 'data-bs-toggle': 'modal' }} />
                    </div>
                </>}

                {
                    state.recordTypeKey &&
                    recordTypes.current[state.recordTypeKey].records.map((records, i) =>
                        <div key={i} className='mt-5'>
                            <h6 className='text-primary fw-bold'>Entry {i + 1}</h6>
                            {
                                state.recordTypeKey !== 'creditentries' ?

                                    <Table
                                        noDataText={`No ${recordTypes.current[state.recordTypeKey].type} found!`}
                                        tableHead={['Time', 'Subhead', 'Amount']}
                                        keys={['time', 'subhead', 'amount']}
                                        data={records.map($ => {
                                            const o = { ...$ };
                                            o.amount = `N${Util.format(o.amount)}`;
                                            return o;
                                        })}
                                        padRight
                                        tableFoot={[`Total: N${Util.format(records.reduce((accum, record) => accum + record.amount, 0))}`]}
                                        actions={position.canMutateRecords ? [
                                            dat => ({
                                                icon: 'trash',
                                                text: 'Delete',
                                                cls: 'btn-outline-danger',
                                                attrs: { ['data-bs-toggle']: 'modal', ['data-bs-target']: `#${dat.recordType}-${dat.id}` }
                                            }),
                                            dat => ({ icon: 'edit', text: 'Edit', attrs: { ['data-bs-toggle']: 'modal', ['data-bs-target']: `#edit-${dat.recordType}-${dat.id}` } })
                                        ] : undefined}
                                    />

                                    :

                                    <>



                                        <Table
                                            noDataText={`No ${recordTypes.current[state.recordTypeKey].type} found!`}
                                            tableHead={['Time', 'Subhead', 'Amount', 'Date repaid']}
                                            keys={['time', 'subhead', 'amount', 'dateRepaid']}
                                            data={records.map($ => {
                                                const o = { ...$ };
                                                o.amount = `N${Util.format(o.amount)}`;
                                                return o;
                                            })}
                                            padRight
                                            tableFoot={[`Total: N${Util.format(records.reduce((accum, { amount }) => accum + amount, 0))}`]}
                                            actions={[

                                                dat => ({
                                                    attrs: {
                                                        ['data-bs-toggle']: 'modal',
                                                        ['data-bs-target']: `#debt-paid-modal-${dat.recordType}-${dat.id}`
                                                    },
                                                    icon: 'check',
                                                    text: 'Paid',
                                                    hideIf: !position.canEnterCredits || !!dat.dateRepaid
                                                }),

                                                ...(position.canMutateRecords ? [

                                                    dat => ({
                                                        icon: 'trash',
                                                        text: 'Delete',
                                                        cls: 'btn-outline-danger',
                                                        attrs: { ['data-bs-toggle']: 'modal', ['data-bs-target']: `#${dat.recordType}-${dat.id}` }
                                                    }),
                                                    dat => ({ icon: 'edit', text: 'Edit', attrs: { ['data-bs-toggle']: 'modal', ['data-bs-target']: `#edit-${dat.recordType}-${dat.id}` } })
                                                ] : [])]}
                                        />
                                    </>
                            }
                            <hr />
                        </div>
                    )

                }
            </ScrollEndTrigger>
        </CardLayout>
    )
}

export default RecordsHistory;

