import React, { useContext } from 'react';
import { NavLink } from 'react-router-dom';
import Paths from '../Paths'
import Util from '../Util';

function Header(props) {

    const ctx = useContext(Util.UserContext);

    return (
        <header className='d-flex p-3 mb-4 shadow align-items-center justify-content-between bg-color-highlight'>
            <div className='flex-grow-1'>
                {
                    ctx && ctx.user ?
                        <>
                            <div className='d-md-flex align-items-center'>
                                <h6 className='m-0 text-primary'>{
                                    ctx.user.fullnames
                                    // ctx.user.staffOutlet ? ctx.user.fullnames :
                                    //     (ctx.currentOutlet === -1 ? <p className='text-muted m-0'>No outlet specified!</p> : ctx.user.position.canSelectOutlets && `Selected outlet: ${ctx.outletObject.outletName}`)

                                }</h6>
                                <small className='small text-muted d-block'>, {ctx.user.position.name}</small>
                            </div>
                            <small style={{maxWidth: '300px'}} className='text-truncate d-none d-md-inline'>
                                {
                                    (ctx.currentOutlet === -1 ?
                                        <p className='text-muted m-0'>No outlet specified!</p> :
                                        (ctx.user.position.canSelectOutlets ?
                                            `Selected outlet: ${ctx.outletObject.outletName}` :
                                            ctx.user.outletName || ctx.user.staffOutlet && ctx.user.staffOutlet.outletName
                                        ))
                                }
                            </small>
                        </> :
                        <small className='small text-muted'>Welcome</small>
                }
            </div>

            {ctx && !!props.authenticated && <div className="dropdown">
                <button className="btn border-0 dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                    <i className='fa fa-user-circle'></i>
                </button>
                <ul className="dropdown-menu p-0 overflow-hidden">


                    <li><NavLink className="dropdown-item p-2 px-3" to={Paths.PROFILE.$}>
                        <i className='fa fa-user me-1'></i>
                        Profile
                    </NavLink></li>


                    {
                        (ctx.user.position.canManageOutlet) && <li><NavLink className="dropdown-item p-2 px-3" to={Paths.STAFF.$}>
                            <i className='fa fa-users me-1'></i>
                            Staff
                        </NavLink></li>
                    }


                    {
                        (ctx.user.position.canViewUsers && !ctx.user.position.canBeStaff) && <li><NavLink className="dropdown-item p-2 px-3" to={Paths.USERS.$}>
                            <i className='fa fa-users me-1'></i>
                            Users
                        </NavLink></li>
                    }


                    {
                        ctx.user.position.canCreateOutlets && <li><NavLink className="dropdown-item p-2 px-3" to={Paths.OUTLET.join(Paths.OUTLETS).$}>
                            <i className='fa fa-house me-1'></i>
                            Outlets
                        </NavLink></li>
                    }

                    {/* 
                    {
                        ctx.user.position.canManageOutlet && <li><NavLink className="dropdown-item p-2 px-3" to={Paths.OUTLET}>
                            <i className='fa fa-house me-1'></i>
                            My Outlet
                        </NavLink></li>
                    } */}

                    {!!props.authorized &&
                        <li><NavLink className="dropdown-item p-2 px-3" to={Paths.CHANGE_PASSWORD.$}>
                            <i className='fa fa-lock me-1'></i>
                            Change password
                        </NavLink></li>}




                    <li><NavLink className="dropdown-item p-2 px-3" to={Paths.LOGIN.params({ logout: 1 }).$}>
                        <i className='fa fa-lock me-1'></i>
                        Logout
                    </NavLink></li>
                </ul>
            </div>}




        </header>
    );
}

export default Header;