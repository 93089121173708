import React, { useContext, useEffect, useRef, useState } from 'react';
import Busy from '../components/Busy';
import ScrollEndTrigger from '../components/ScrollEndTrigger';
import Table from '../components/Table';
import CardLayout from '../layouts/CardLayout';
import Util from '../Util';
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import Button from '../components/Button';
import Paths from '../Paths';
import { ErrorStatusModal } from '../components/StatusModal';
import { useQueryState } from '../hooks'
import ActionHeader from '../components/ActionHeader';
function Pumps(props) {
    const
        [state, setState] = useState({
            records: [],
            gettingPumpsDone: false,
            gettingPumps: false,
            gettingPumpsFailed: false,
            gettingPumpsMessage: ''
        }),
        rollover = useQueryState(),
        navigate = useNavigate(),
        { currentOutlet: outletId, user: { position = {} } } = useContext(Util.UserContext);

    useEffect(() => {
        Util.request.get(`getpumps/${outletId}`)
            .then(res => {
                setState({
                    records: res.status ? res.data : [],
                    gettingPumpsDone: true,
                    gettingPumps: false,
                    gettingPumpsFailed: !res.status,
                    gettingPumpsMessage: res.message
                });
            });
        setState(o => ({ ...o, gettingPumps: true }));
    }, []);


    function handleRollover() {

        Util.request.post(`readingsrollover/${outletId}`)
            .then(res => rollover.fromResponse(res))

        rollover.busy('Rolling over...');
    }


    return (
        <CardLayout
            pageName='Pumps'
            pageIcon='gas-pump'
            cardHeader={<ActionHeader actions={[{
                text: 'Clear readings',
                icon: 'times',
                onClick: e => navigate(Paths.DAILY_SALES.join(Paths.CLEAR_READINGS).abs.$),
                showIf: position?.canClearMeterReading
            },{
                text: 'Rollover',
                icon: 'turn-down',
                onClick: handleRollover,
                showIf: position?.canRolloverMeterReading
            },{
                text: 'Create pump',
                icon: 'plus',
                onClick: e => navigate(Paths.DAILY_SALES.join(Paths.CREATE_PUMP).abs.$),
                showIf: position?.canCreatePumps
            }]} />}
        >
            <Busy busyText='Retrieving pumps...' busy={state.gettingPumps} />

            <rollover.Busy />

            <rollover.Modal />

            <ErrorStatusModal
                show={state.gettingPumpsDone && state.gettingPumpsFailed}
                onDone={() => setState(o => ({ ...o, gettingPumpsDone: false }))}
                text={state.gettingPumpsMessage} />

            <ScrollEndTrigger maxHeight='390px'>
                <Table
                    tableHead={['Unique name', 'Pump type', 'Last reading', 'Date of reading', 'Assigned to']}
                    keys={['uniqueName', 'tank.tankType.name', 'lastMeterReading', 'dateOfReading', 'assignedTo.fullnames']}
                    data={state.records}
                    actions={[
                        { icon: 'refresh', hideIf: !position.canViewTotalizerOffsets, text: 'Pump resets', on: pump => navigate(Paths.DAILY_SALES.join(Paths.TOTALIZERS.parse(pump.pumpId)).abs.$) },
                        data => ({ icon: 'calculator', text: 'Readings', on: () => navigate(Paths.DAILY_SALES.join(Paths.PUMP_READING.parse(data.pumpId)).abs.$) }),
                        data => ({ icon: 'edit', hideIf: !position.canCreatePumps, text: 'Edit', on: () => navigate(Paths.DAILY_SALES.join(Paths.PUMP_MODIFY.parse(data.pumpId)).abs.$) }),
                    ]}
                />
            </ScrollEndTrigger>
        </CardLayout>
    )
}

export default Pumps;