import React, { useContext, useEffect, useState, useRef } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import Busy from '../components/Busy';
import Button from '../components/Button';
import ScrollEndTrigger from '../components/ScrollEndTrigger';
import { ErrorStatusModal } from '../components/StatusModal';
import Table from '../components/Table';
import CardLayout from '../layouts/CardLayout';
import Paths from '../Paths';
import Util from '../Util';
import { useQueryState } from '../hooks';
import Modal from '../components/Modal';
import ActionHeader from '../components/ActionHeader';

function SupplyEntries(props) {

    const
        [state, setState] = useState({
            entries: null,
            gettingSupplyEntries: false,
            gettingSupplyEntriesDone: false,
            gettingSupplyEntriesText: '',
            gettingSupplyEntriesError: false
        }),
        deleter = useQueryState(),
        deleteRef = useRef(0),
        { supplyId = 0, tankId = 0 } = useParams(),
        { user: { position } } = useContext(Util.UserContext),
        navigate = useNavigate();

    useEffect(() => {
        Util.request.get(`supply/entries/${supplyId}`)
            .then(res => {
                setState(o => ({
                    entries: res.status ? res.data : [],
                    gettingSupplyEntries: false,
                    gettingSupplyEntriesDone: true,
                    gettingSupplyEntriesText: res.message,
                    gettingSupplyEntriesError: !res.status
                }));
            })

        setState(o => ({ ...o, gettingSupplyEntries: true }))
    }, [deleteRef.current])

    function handleDeleteEntry() {
        const fd = new FormData();


        Util.request.post(`supply/entries/${supplyId}/delete`)
            .then(res => {
                ++deleteRef.current;
                deleter.fromResponse(res)
            })
        deleter.busy('Deleting supply entry.');
    }

    return (
        <CardLayout pageName='Supply entries'
            pageIcon='keyboard'
            cardFooter={<div className='d-flex justify-content-end'>
                <a href='#' data-bs-target='#modal-delete-entry' data-bs-toggle='modal' className='text-danger text-decoration-none'><b>Delete last entry</b></a>
            </div>}
            cardHeader={<ActionHeader actions={[{
                showIf: position.canEnterSupplies,
                text: 'Add entry for this tank',
                icon: 'plus',
                onClick: () => navigate(Paths.SUPPLY.parse(tankId).abs.$),
            }]} title={state.entries && state.entries.length ? `Supply entries for ${state.entries[0].supply.tank.tankName}` : 'No entries for specified tank'} />}
        >
            <Modal on={handleDeleteEntry} type='danger' id='modal-delete-entry' title={'Delete entry?'}>
                <p>Are you sure you want to delete last entry?</p>
                {/* <i className='text-warning'><b>Note:</b> This operation will fail if any purchase has been made since last subscription was created.</i> */}

            </Modal>

            <deleter.Busy />
            <deleter.Modal />

            {/* GETTING SUPPLY */}
            <Busy busy={state.gettingSupplyEntries} fixed={true} busyText='Checking for open supply...' />

            <ErrorStatusModal
                onDone={() => setState(o => ({ ...o, gettingSupplyEntriesDone: false }))}
                show={state.gettingSupplyEntriesDone && state.gettingSupplyEntriesError} text={state.gettingSupplyEntriesText}
            />
            {/* END OF GETTING SUPPLY */}


            {state.entries && <ScrollEndTrigger maxHeight={'54vh'}>
                <Table
                    data={state.entries}
                    tableHead={['Date', 'Type', "Driver's name", "Vehicle's number", 'Quantity in', 'Quantity out', 'Receiver', 'Balance']}
                    keys={['date', 'supplyType', 'driversName', 'vehicleNumber', 'quantityIn', 'qunatityOut', 'reveiver', 'balance']}
                />
            </ScrollEndTrigger>}

        </CardLayout>
    );
}

export default SupplyEntries;

// ['Date','Product', "Driver's name", "Vehicle's number", 'Quantity in', 'Quantity out', 'Receiver', 'Balance']