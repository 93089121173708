import React, { useContext, useEffect, useState } from 'react';
import CardLayout from '../layouts/CardLayout';
import Util from '../Util';
import Busy from '../components/Busy';
import ScrollEndTrigger from '../components/ScrollEndTrigger';
import { ErrorStatusModal, SuccessStatusModal } from '../components/StatusModal';
import Button from '../components/Button';
import { useNavigate } from 'react-router-dom';
import Paths from '../Paths';


function AssignPump(props) {

    const [state, setState] = useState({
        pumps: [],
        staff: [],
        assignments: {},

        gettingOutletData: false,
        gettingOutletDataDone: false,
        gettingOutletDataError: false,
        gettingOutletDataText: '',

        assigningStaff: false,
        assigningStaffDone: false,
        assigningStaffError: false,
        assigningStaffText: '',

        clearingAssignment: false,
        clearingAssignmentDone: false,
        clearingAssignmentError: false,
        clearingAssignmentText: '',
    }),

        { currentOutlet, user } = useContext(Util.UserContext),

        navigate = useNavigate();


    useEffect(() => {
        if (state.gettingOutletData) return;

        Util.request.get(`bundle/assign_pumps/${currentOutlet}`)
            .then(res => {
                let assignments = {};

                for (const { username, pumpId } of res.data.assignedPumps) {
                    if (!assignments[username])
                        assignments[username] = [];
                    assignments[username].push(pumpId);
                }


                for (const { staff: { username } } of res.data.staff)
                    if (!assignments[username])
                        assignments[username] = ['default'];



                setState(o => ({
                    ...o,
                    gettingOutletData: false,
                    gettingOutletDataDone: true,
                    gettingOutletDataError: !res.status,
                    staff: res.data.staff,
                    pumps: res.data.pumps,
                    assignments
                }))
            });



        setState(o => ({ ...o, gettingOutletData: true }));
    }, []);


    const handleClearAssignment = e => {
        Util.request.post(`clearpumpassign/${currentOutlet}`)
            .then(res => {
                const assignments = {};

                for (const { staff: { username } } of state.staff)
                    assignments[username] = ['default'];

                setState(o => ({
                    ...o,
                    clearingAssignment: false,
                    clearingAssignmentDone: true,
                    clearingAssignmentError: !res.status,
                    clearingAssignmentText: res.message,
                    assignments: assignments
                }))
            });

        setState(o => ({ ...o, clearingAssignment: true }))
    };


    const handleClearSingleAssignment = attendant => {
        Util.request.post(`clearpumpassign/${currentOutlet}/${attendant}`)
            .then(res => {
                const assignments = {};

                if (res.status)
                    assignments[attendant] = ['default'];

                setState(o => ({
                    ...o,
                    clearingAssignment: false,
                    clearingAssignmentDone: true,
                    clearingAssignmentError: !res.status,
                    clearingAssignmentText: res.message,
                    assignments: assignments
                }))
            });

        setState(o => ({ ...o, clearingAssignment: true }))
    };


    const handleAssignPump = (e) => {
        e.preventDefault();

        Util.request.post('assignpumps', new FormData(e.target))
            .then(res => setState(o => ({
                ...o,
                assigningStaff: false,
                assigningStaffDone: true,
                assigningStaffError: !res.status,
                assigningStaffText: res.message
            })));

        setState(o => ({ ...o, assigningStaff: true }))
    };

    function getHeader() {

        if (state.staff === false) return <h6 className='text-muted text-center m-0'>You have no staff</h6>;
        if (state.pumps === false) return <h6 className='text-muted text-center m-0'>You have no pumps</h6>;

        return null;
    }

    function getPumpSelect(staffUsername) {

        return <select
            className='form-select'
            onChange={e => setState(o => ({ ...o, assignments: { ...state.assignments, [staffUsername]: Array.from(e.target.selectedOptions).map(o => Number(o.value)) } }))}
            multiple
            value={state.assignments[staffUsername]}
            style={{ height: '10rem', lineHeight: '1rem' }}
            name={staffUsername}>
            <option disabled value={'default'}>No assignment</option>
            {state.pumps.map(p => <option key={p.pumpId} value={p.pumpId}>{p.uniqueName}</option>)}
        </select>

    }


    return (
        <CardLayout
            cardElement='form'
            cardElementProps={{ method: 'post', onSubmit: handleAssignPump }}
            pageName='Pump assignment' pageIcon='arrow-up'
            cardFooter={!!(state.staff.length && state.pumps.length) && <div className='d-flex justify-content-between align-items-center'>
                {state.pumps && state.staff && <Button text='Assign pump' icon='arrow-up' />}

                <Button type='button' onClick={handleClearAssignment} sm outline text='Clear assignment' icon='refresh' />
            </div>}
            cardHeader={getHeader()}>

            <Busy busy={state.gettingOutletData} busyText='Retrieving assignment data...' />

            <Busy busy={state.assigningStaff} busyText='Assigning pumps...' />

            <Busy busy={state.clearingAssignment} busyText='Clearing all assignments...' />

            <SuccessStatusModal
                show={state.assigningStaffDone && !state.assigningStaffError}
                onDone={() => setState(o => ({ ...o, assigningStaffDone: false }))}
                text={state.assigningStaffText} />


            <ErrorStatusModal
                show={state.assigningStaffDone && state.assigningStaffError}
                onDone={() => setState(o => ({ ...o, assigningStaffDone: false }))}
                text={state.assigningStaffText} />



            <SuccessStatusModal
                show={state.clearingAssignmentDone && !state.clearingAssignmentError}
                onDone={() => setState(o => ({ ...o, clearingAssignmentDone: false }))}
                text={state.clearingAssignmentText} />



            <ErrorStatusModal
                show={state.clearingAssignmentDone && state.clearingAssignmentError}
                onDone={() => setState(o => ({ ...o, clearingAssignmentDone: false }))}
                text={state.clearingAssignmentText} />


            <ErrorStatusModal
                show={state.gettingOutletDataDone && state.gettingOutletDataError}
                onDone={() => setState(o => ({ ...o, gettingOutletDataDone: false }))}
                text={state.gettingOutletDataText} />

            <ScrollEndTrigger maxHeight='350px'>
                {(function () {
                    if (state.staff.length < 1) {
                        return <p className='m-0 text-muted text-center'>There are no <b>staff</b> for the selected outlet.</p>
                    }


                    else if (state.pumps.length < 1) {
                        return <p className='m-0 text-muted text-center'>There are no <b>pumps</b> for the selected outlet.</p>
                    }


                    else if (state.staff.length && state.pumps.length) {
                        return <table className='table table-striped'>
                            <thead>
                                <tr>
                                    <th></th>
                                    <th>Staff</th>
                                    <th>Select pump</th>
                                    <th></th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    state.staff.map(({ staff }, i) => <tr key={'stf-' + i}>
                                        <th>{i + 1}</th>
                                        <td>{staff.fullnames}</td>
                                        <td>{getPumpSelect(staff.username)}</td>
                                        <td>
                                            <Button icon='times' sm outline type='button' onClick={e => handleClearSingleAssignment(staff.username)} />
                                        </td>
                                    </tr>)
                                }
                            </tbody>
                        </table>
                    }
                })()}
            </ScrollEndTrigger>


        </CardLayout>
    );
}


export default AssignPump;