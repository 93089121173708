import React, { useContext, useEffect, useState } from 'react';
import CardLayout from '../layouts/CardLayout';
import Util from '../Util';
import Busy from '../components/Busy';
import ScrollEndTrigger from '../components/ScrollEndTrigger';
import { ErrorStatusModal, SuccessStatusModal } from '../components/StatusModal';
import InputField from '../components/InputField';
import { useNavigate, useParams } from 'react-router-dom';


function TankView(props) {
    const
        [state, setState] = useState({

            modifyingTank: false,
            modifyingTankDone: false,
            modifyingTankFailed: false,
            modifyingTankMessage: '',

            gettingTank: false,
            gettingTankDone: false,
            gettingTankFailed: false,
            gettingTankMessage: false,

            tank: null,

            inputErrors : {}
        }),

        { user: { productTypes } } = useContext(Util.UserContext),

        { tankId } = useParams(), modalDuration = 1000,

        navigate = useNavigate();


    useEffect(() => {
        if (state.tank !== null) return;

        Util.request.get(`tank/${tankId}`)
            .then(res => {
                setState(o => ({
                    ...o,
                    gettingTank: false,
                    gettingTankDone: true,
                    gettingTankFailed: !res.status,
                    gettingTankMessage: res.message,
                    tank: res.status ? res.data : false
                }));
                if (!res.status)
                    setTimeout(() => {
                        navigate(-1);
                    }, modalDuration);

            });

        setState(o=>({...o, gettingTank: true}));
    }, []);



    const handleSubmit = (e) => {
        e.preventDefault();

        Util.request.post(`tank/${tankId}/modify`, new FormData(e.target))
            .then(res => {
                setState(o => ({
                    ...o,
                    modifyingTank: false,
                    modifyingTankDone: true,
                    modifyingTankFailed: !res.status,
                    modifyingTankMessage: typeof res.message ==='string' ? res.message : 'Error',

                    tank: res.status ? res.data : state.tank,

                    inputErrors : typeof res.message !== 'string'? res.message : {}

                }));
            });
        setState(o => ({ ...o, modifyingTank: true }))
    };

    const actionButton = <button type='submit' className='btn btn-primary'>
        <i className='fa fa-edit me-2'></i>
        <span>Modify tank</span>
    </button>;

    return (
        <CardLayout cardElement='form' cardElementProps={{ method: 'post', onSubmit: handleSubmit }}
            pageName='Create tank' pageIcon='plus' cardFooter={actionButton}>

            <Busy busy={state.modifyingTank} busyText='Modifying tank...' />

            <Busy busy={state.gettingTank} busyText='Retrieving tank data...' />

            <SuccessStatusModal
                show={state.modifyingTankDone && !state.modifyingTankFailed}
                onDone={() => setState(o => ({ ...o, modifyingTankDone: false }))}
                text={state.modifyingTankMessage} />

            <ErrorStatusModal
                show={state.modifyingTankDone && state.modifyingTankFailed}
                onDone={() => setState(o => ({ ...o, modifyingTankDone: false }))}
                text={state.modifyingTankMessage} />

            <ErrorStatusModal
                show={state.gettingTankDone && state.gettingTankFailed}
                onDone={() => setState(o => ({ ...o, gettingTankDone: false }))}
                text={state.gettingTankMessage} duration={modalDuration} />



            <ScrollEndTrigger>
                <div style={{ minHeight: '100px' }}>
                    {
                        state.tank && <div className='row'>

                            <div className='col-12 col-md-6'>
                                <div className='mb-3'>
                                    <label className='form-label text-muted' htmlFor='manager'>Tank type</label>
                                    <select className='form-select' name='tank_type' defaultValue={state.tank.tankType.id}>
                                        {productTypes.map(t => <option key={t.id} value={t.id}>{t.name}</option>)}
                                    </select>
                                </div>
                            </div>




                            <div className='mb-3 col-12 col-md-6'>
                                <div className='mb-3'>
                                    <label className='form-label text-muted' htmlFor='manager'>Category</label>
                                    <select className='form-select' name='category' defaultValue={state.tank.category}>
                                        <option value="Service">Service</option>
                                        <option value="Reserve">Reserve</option>
                                    </select>
                                </div>
                            </div>



                            <div className='mb-3 col-12 col-md-6'>
                                <InputField name='tank_name' value={state.tank.tankName} labelText='Tank name' errs={state.inputErrors} />
                            </div>



                            <div className='mb-3 col-12 col-md-6'>
                                <InputField name='capacity' value={state.tank.capacity} labelText='Capacity' errs={state.inputErrors} attrs={{ min: 0 }} />
                            </div>


                        </div>
                    }
                </div>
            </ScrollEndTrigger>


        </CardLayout>
    );
}

export default TankView;