import React, { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Busy from '../components/Busy';
import Button from '../components/Button';
import ScrollEndTrigger from '../components/ScrollEndTrigger';
import Table from '../components/Table';
import CardLayout from '../layouts/CardLayout';
import Paths from '../Paths';
import Util from '../Util';
import ActionHeader from '../components/ActionHeader';


function Outlets(props) {
    const
        [state, setState] = useState({
            gettingOutlets: false,
            gettOutletsgettOutletsError: false,
            gettingOutletsText: '',
            records: null
        }),

        { user } = useContext(Util.UserContext),

        navigate = useNavigate();


    useEffect(() => {
        if (state.records !== null) return;

        Util.request.get('outlets')
            .then(res => setState({
                gettingOutlets: false,
                gettOutletsgettOutletsError: !res.status,
                gettingOutletsText: res.message,
                records: res.status ? res.data : []
            }));

        setState(o => ({ ...o, gettingOutlets: true }));
    }, []);
    return (
        <CardLayout
            pageName="Outlets"
            pageIcon="house"
            cardHeader={<ActionHeader actions={[{
                showIf: user.position.canCreateOutlets,
                icon: 'plus',
                text: 'Create outlet',
                onClick: e => navigate(Paths.OUTLET.join(Paths.CREATE_OUTLET).abs.$),
            }]} />}>


            <Busy show={state.gettingOutlets} />


            <ScrollEndTrigger maxHeight='430px'>
                <Table
                    actions={{ icon: 'eye', text: 'View', on: outlet => navigate(Paths.OUTLET.params({ id: outlet.outletId }).abs.$) }}
                    keys={['outletName', 'manager.fullnames']}
                    tableHead={['Outlet name', 'Manager']}
                    data={state.records} />
            </ScrollEndTrigger>
        </CardLayout>
    );
}

export default Outlets;