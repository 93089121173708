import React, { useContext } from 'react';
import Paths from '../Paths';
import NavPopup from '../components/NavPopup';
import { NavLink } from 'react-router-dom';
import Util from '../Util';

function SideNav(props) {

    let context = useContext(Util.UserContext), pos = null;

    if (!context.user) return null;

    pos = context.user.position;

    return (
        <aside className='bg-primary pt-3'>
            <ul className='list-unstyled'>
                <li><NavLink to={Paths.DASHBOARD.$} className='px-4 px-md-3 nav-toggle position-relative d-flex align-items-center justify-content-between nav-toggle p-3 flex-column w-100 mb-1 text-white'>
                    <i className='fa fa-dashboard'></i>
                    <span className="d-none d-md-inline">Dashboard</span>
                </NavLink></li>



                {pos.canSelectOutlets && <li><NavLink to={Paths.SELECT_OUTLET.$} className='px-4 px-md-3 nav-toggle position-relative d-flex align-items-center justify-content-between nav-toggle p-3 flex-column w-100 mb-1 text-white text-center'>
                    <i className='fa fa-house'></i>
                    <span className="d-none d-md-inline">Select outlet</span>
                </NavLink></li>}



                {
                    Util.any(pos.canViewAnalysis) &&
                    <li><NavPopup icon='chart-pie' text='Analysis'
                        paths={[
                            { path: Paths.ANALYSIS.join(Paths.MAKE_ANALYSIS).$, visible: true, text: 'Daily analysis', icon: 'chart-bar' },
                            { path: Paths.ANALYSIS.join(Paths.AUDITING).$, visible: pos.canSelectOutlets, text: 'Auditing', icon: 'print' },
                        ]} /></li>
                }

                {
                    Util.any(
                        pos.canEnterMeterReading,
                        pos.canViewMeterReading,
                        pos.canAssignPump,
                        pos.canBeAssignedPumps,
                        pos.canCreateTanks,
                        pos.canViewTanks,
                        pos.canDeleteSlips,
                        pos.canEnterSlips,
                    ) &&
                    <li><NavPopup icon='money-bill' text='Sales'
                        paths={[
                            { path: Paths.DAILY_SALES.$, visible: Util.any(pos.canEnterMeterReading), text: 'Daily sales', icon: 'calculator' },
                            {
                                path: Paths.DAILY_SALES.join(Paths.SLIPS).$, visible: Util.any(pos.canDeleteSlips, pos.canEnterSlips), text: 'Slips', icon: 'receipt'
                            },
                            { path: Paths.DAILY_SALES.join(Paths.PUMPS).$, visible: Util.any(pos.canViewPumps), text: 'Pumps', icon: 'gas-pump' },
                            { path: Paths.DAILY_SALES.join(Paths.ASSIGN_PUMP).$, visible: Util.any(pos.canAssignPump), text: 'Assign pump', icon: 'arrow-up' },
                            { path: Paths.DAILY_SALES.join(Paths.PRODUCT_TYPE).$, visible: Util.any(pos.canCreateTanks), text: 'Product types', icon: 'oil-can' },
                            { path: Paths.DAILY_SALES.join(Paths.TANKS).$, visible: Util.any(pos.canViewTanks), text: 'Tanks', icon: 'oil-can' },
                            { path: Paths.DAILY_SALES.join(Paths.METER_READINGS).$, visible: Util.any(pos.canViewMeterReading), text: 'Meter readings', icon: 'box' }
                        ]} /></li>
                }



                {
                    Util.any(
                        pos.canEnterCashbook,
                        pos.canEnterExpensis,
                        pos.canEnterCredits,
                        pos.canViewCredits,
                        pos.canViewCashbook,
                        pos.canViewExpensis
                    ) &&
                    <li><NavPopup icon='book' text='Records'
                        paths={[
                            { path: Paths.CASHBOOK.$, visible: Util.any(pos.canEnterCashbook), text: 'Cashbook', icon: 'book-open' },
                            { path: Paths.EXPENSES.$, visible: Util.any(pos.canEnterExpensis), text: 'Expenses', icon: 'book-open' },
                            { path: Paths.CREDITS.$, visible: Util.any(pos.canEnterCashbook), text: 'Credits', icon: 'book-open' },
                            { path: Paths.RECORDS_HISTORY.$, visible: Util.any(pos.canViewCashbook, pos.canViewExpensis, pos.canViewCredits), text: 'Records history', icon: 'clock' },
                        ]} /></li>
                }



                {
                    Util.any(pos.canViewPrepaid, pos.canViewPostpaid) &&
                    <li><NavPopup icon='users' text='Customers'
                        paths={[
                            { path: Paths.PREPAID.$, visible: Util.any(pos.canViewPrepaid), text: 'Prepaid', icon: 'money-bill' },
                            { path: Paths.POSTPAID.$, visible: Util.any(pos.canViewPostpaid), text: 'Postpaid', icon: 'receipt' },
                        ]} /></li>
                }



                {
                    Util.any(
                        pos.canMakeCashbookStaleUploads,
                        pos.canMakeCreditStaleUploads,
                        pos.canMakeExpenseStaleUploads,
                        pos.canMakeTellerStaleUploads,
                        pos.canMakeSlipStaleUploads,
                        pos.canCorrectMeterReadings,
                    ) &&
                    <li><NavPopup icon='clock' text='Overdue'
                        paths={[
                            { path: Paths.OVERDUE.join(Paths.CASHBOOK).abs.$, visible: pos.canMakeCashbookStaleUploads, text: 'Cashbook', icon: 'book-open' },
                            { path: Paths.OVERDUE.join(Paths.CREDITS).abs.$, visible: pos.canMakeCreditStaleUploads, text: 'Credits', icon: 'book-open' },
                            { path: Paths.OVERDUE.join(Paths.EXPENSES).abs.$, visible: pos.canMakeExpenseStaleUploads, text: 'Expenses', icon: 'book-open' },
                            { path: Paths.OVERDUE.join(Paths.TELLERS).abs.$, visible: pos.canMakeTellerStaleUploads, text: 'Tellers', icon: 'receipt' },
                            { path: Paths.OVERDUE.join(Paths.SLIPS).abs.$, visible: pos.canMakeSlipStaleUploads, text: 'Slips', icon: 'receipt' },
                            { path: Paths.OVERDUE.join(Paths.OUTLETS).abs.$, visible: pos.canCorrectMeterReadings, text: 'Reading Errors', icon: 'exclamation-triangle' },
                        ]} /></li>
                }

                {
                    Util.any(pos.canViewTellers) &&
                    <li><NavPopup icon='receipt' text='Tellers'
                        paths={[
                            { path: Paths.TELLERS.$, visible: true, text: 'Upload Tellers', icon: 'upload' },
                            { path: Paths.TELLERS.join(Paths.TELLERS_VERIFY).$, visible: pos.canConcludeTellers, text: 'Verify Tellers', icon: 'check' },
                        ]} /></li>
                }

            </ul>
        </aside>
    );
}

export default SideNav;




