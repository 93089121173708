import React, { useState, useEffect, useRef } from 'react';
import Busy from '../components/Busy';
import Button from '../components/Button';
import InputField from '../components/InputField';
import CardLayout from '../layouts/CardLayout';
import Util from '../Util';
import { ErrorStatusModal, SuccessStatusModal } from '../components/StatusModal';
import SelectInput from '../components/SelectInput';
import { useParams } from 'react-router-dom';
import SelectInput2 from '../components/SelectInput2';
import Table2 from '../components/Table2';



function MakePurchase(props) {
    const
        [state, setState] = useState({
            gettingData: false,
            gettingDataDone: false,
            gettingDataMessage: '',
            gettingDataFailed: false,

            makingPurchase: false,
            makingPurchaseDone: false,
            makingPurchaseMessage: '',
            makingPurchaseFailed: false,

            data: { products: [], customer: { balance: 0 } },

            errs: {}
        }),
        [snapshot, setSnapshot] = useState({ price: 0 }),
        [product, setProduct] = useState({}),
        [discount, setDiscount] = useState(0),
        [litres, setLiters] = useState(0),
        { customerId = 0 } = useParams(),
        dep = useRef(0);


    function handleSetSelected({ target }) {
        setSnapshot(state.data.customer.snapshots.find(o => o.productType.id === Number(target.value)));
        setProduct(state.data.products.find(o => o.id === Number(target.value)));
    }


    useEffect(() => {
        Util.request.get(`bundle/makepurchase/${customerId}`)
            .then(res => {
                if (res.status) {
                    setSnapshot(res.data.customer.snapshots[0] || { price: 0 });
                    setProduct(res.data.products[0] || {});
                }
                setState(o => ({
                    ...o,
                    gettingData: false,
                    gettingDataDone: true,
                    gettingDataFailed: !res.status,
                    gettingDataMessage: Util.resolveResponseMessage(res),
                    data: res.status ? res.data : state.data,
                    errs: Util.resolveFormError(res)
                }));
            });

        setState(o => ({ ...o, gettingData: true }));
    }, [dep.current])


    function handleMakePurchase(e) {

        e.preventDefault();

        const fd = new FormData(e.target);

        Util.request.post(`purchase/create/${customerId}`, fd)
            .then(res => {
                res.status && ++dep.current;
                setState(o => ({
                    ...o,
                    makingPurchase: false,
                    makingPurchaseDone: true,
                    makingPurchaseMessage: Util.resolveResponseMessage(res),
                    makingPurchaseFailed: !res.status,

                    errs: Util.resolveFormError(res)
                }))
            });

        setState(o => ({ ...o, makingPurchase: true }));
    }

    return (
        <CardLayout
            cardElement='form'
            cardElementProps={{ onSubmit: handleMakePurchase }}
            pageName="prepaid purchase"
            pageIcon="money-bill"
            cardFooter={<Button text='Make prepaid purchase' icon='money-bill' />}>

            <Busy busy={state.makingPurchase} busyText='Making purchase...' />

            <Busy busy={state.gettingData} busyText='Getting customer data...' />

            <SuccessStatusModal
                show={state.makingPurchaseDone && !state.makingPurchaseFailed}
                onDone={() => setState(o => ({ ...o, makingPurchaseDone: false }))}
                text={state.makingPurchaseMessage} />
            {
                typeof state.makingPurchaseMessage === 'string' &&
                <ErrorStatusModal
                    show={state.makingPurchaseDone && state.makingPurchaseFailed}
                    onDone={() => setState(o => ({ ...o, makingPurchaseDone: false }))}
                    text={state.makingPurchaseMessage} />}


            <div className='row'>

                <p className="small text-muted m-0 fs-6">Current balance:</p>
                <div className="fs-3 text-primary m-0 mb-3" style={{ fontWeight: 300, lineHeight: '100%' }}>N{Util.format(state.data.customer.balance.toFixed(2))}</div>

                <Table2
                    data={state.data.customer.snapshots || []}
                    columns={[
                        {
                            name: 'Product',
                            selector: data => data.productType.name,
                        }, {
                            name: 'Current rate',
                            selector: data => `N${Util.format(data.productType.pricePerLitre)}/L`,
                        }, {
                            name: 'Customer rate',
                            selector: data => <span className='text-primary' style={{ fontWeight: 500 }}>N{`${Util.format(data.price)}/L`}</span>,
                        }, {
                            name: 'Litres',
                            selector: data => `${(state.data.customer.balance / data.price).toFixed(2)}/L`,
                        },
                    ]}
                />

                <div className='col-12 mb-4'>
                    <InputField name='customer' attrs={{ readOnly: true, disabled: true }} labelText='Customer' value={state.data.customer.name} labelIcon='user' />
                </div>

                <div className='col-12 mb-4'>
                    <SelectInput2
                        label='Select product'
                        data={state.data.products}
                        name='product'
                        option={data => ({
                            children: data.name,
                            value: data.id,
                        })}
                        value={product.id}
                        onChange={handleSetSelected}
                    />
                </div>

                <div className='col-12 col-md-6 mb-4'>
                    <InputField name='litres' errs={state.errs} labelText='Litres'
                        type='number' attrs={{
                            min: .01,
                            value: litres,
                            onInput: e => setLiters(Number(e.target.value)), step: .01,
                            max: state.data.customer.allowCreditPurchase ?
                                undefined : (state.data.customer.balance / snapshot.price).toFixed(2)
                        }}
                        labelIcon='gas-pump'

                    />
                </div>

                <div className='col-12 col-md-6 mb-4'>
                    <InputField name='discount' errs={state.errs} labelText='Discount'
                        type='number' attrs={{ min: 0, max: snapshot.price, onInput: e => setDiscount(Number(e.target.value)), value: discount, step: .01 }}
                        labelIcon='arrow-down' />
                </div>

            </div>

            <hr />

            <div className=''>
                {
                    discount === 0 ?
                        <p className='text-primary m-0 h5'>Amount: N{Util.format(litres * snapshot.price)}</p> :
                        <div>
                            <p className='text-muted m-0'><del>Amount: N{Util.format(litres * snapshot.price)}</del></p>
                            <p className='text-primary m-0 h5'>Amount: N{Util.format(litres * (snapshot.price - discount))}</p>
                        </div>
                }
            </div>

        </CardLayout>
    );
}



export default MakePurchase;