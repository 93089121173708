import React from 'react';

function Busy({ busy, busyText }) {

    if (!!!busy) return null;

    return (
        <div className='busy-component d-flex justify-content-center align-items-center flex-column position-fixed'>
            <span className="loader"></span>
            <p className='m-0 mt-3'>{busyText || 'Please wait...'}</p>
        </div>
    );
}

export default Busy;