import React from 'react';

function PageHeader(props) {
    return (
        <div>
            
            <h6 className='m-0 fg-color-highlight text-uppercase'>
                {props.icon && <i className={`fa fa-${props.icon} me-2`}></i>}
                {props.pageName}
            </h6>
        </div>
    );
}

export default PageHeader;