import React, { useContext, useEffect, useState } from 'react';
import CardLayout from '../layouts/CardLayout';
import Util from '../Util';
import Busy from '../components/Busy';
import { ErrorStatusModal, SuccessStatusModal } from '../components/StatusModal';
import Button from '../components/Button';
import InputField from '../components/InputField';


function CashRemit(props) {
    const
        [state, setState] = useState({

            remitting: false,
            remittingDone: false,
            remittingError: false,
            remittingText: ''

        }),
        [errors, setErrors] = useState({}),

        { user: { productTypes } } = useContext(Util.UserContext);



    const handleSubmit = (e) => {
        e.preventDefault();

        Util.request.post('remit', new FormData(e.target))
            .then(res => {
                if (res.status) {
                    setState(o => ({
                        ...o,
                        remitting: false,
                        remittingDone: true,
                        remittingText: res.message,
                        remittingError: !res.status
                    }));

                    setErrors({});

                }
                else {
                    setState(o => ({
                        ...o,
                        remitting: false,
                        remittingDone: true,
                        remittingText: res.message,
                        remittingError: !res.status
                    }));
                    setErrors(res.message);
                }
            });

        setState(o => ({ ...o, remitting: true }))
    };

    const actionButton = <Button icon='send' text='Send' />;

    return (
        <CardLayout cardElement='form' cardElementProps={{ method: 'post', onSubmit: handleSubmit }}
            pageName='attendants daily sales' pageIcon='money' cardFooter={actionButton}>

            <Busy busy={state.remitting} />

            <ErrorStatusModal
                show={state.remittingDone && state.remittingError}
                onDone={() => setState(o => ({ ...o, remittingDone: false }))}
                text={state.remittingText} />

            <SuccessStatusModal
                show={state.remittingDone && !state.remittingError}
                onDone={() => setState(o => ({ ...o, remittingDone: false }))}
                text={state.remittingText} />

            <div className='mb-3'>
                <InputField errs={errors} name='amount' labelText='Amount' attrs={{ type: 'number', min: 0, defaultValue: 0, step: '.001' }} />
            </div>


        </CardLayout>
    );
}

export default CashRemit;