import React, { useEffect, useState } from 'react';
import Busy from '../components/Busy';
import Button from '../components/Button';
import InputField from '../components/InputField';
import CardLayout from '../layouts/CardLayout';
import Util from '../Util';
import { ErrorStatusModal, SuccessStatusModal } from '../components/StatusModal'
import { useSearchParams } from 'react-router-dom';



function CreateCustomer(props) {
    const
        [state, setState] = useState({
            creatingCustomer: false,
            creatingCustomerDone: false,
            creatingCustomerFailed: false,
            creatingCustomerMessage: '',
            errs: {},


            gettingCustomer: false,
            gettingCustomerDone: false,
            gettingCustomerFailed: false,
            gettingCustomerMessage: '',


            editingCustomer: false,
            editingCustomerDone: false,
            editingCustomerFailed: false,
            editingCustomerMessage: '',

            customer: {
                name: '',
                phone: '',
                allowCreditPurchase: false
            }
        }),

        [params, setParam] = useSearchParams(),

        isNewCustomer = !params.get('_id');



    useEffect(() => {
        if (isNewCustomer) return;

        Util.request.get(`customer/get/${params.get('_id')}`)
            .then(res => {

                setState(o => ({
                    ...o,
                    customer: res.status ? res.data : state.customer,
                    gettingCustomer: false,
                    gettingCustomerDone: true,
                    gettingCustomerFailed: !res.status,
                    gettingCustomerMessage: res.message
                }));

                if (!res.status) params.delete('_id');
            });

        setState(o => ({ ...o, gettingCustomer: true }));

    }, []);


    function handleCreateCustomer(e) {

        e.preventDefault();

        Util.request.post(`customer/create`, new FormData(e.target))
            .then(res => setState(o => ({
                ...o,
                creatingCustomer: false,
                creatingCustomerDone: true,
                creatingCustomerMessage: Util.resolveResponseMessage(res),
                creatingCustomerFailed: !res.status,
                errs: Util.resolveFormError(res)
            })));

        setState(o => ({ ...o, creatingCustomer: true }))
    }


    function handleEditCustomer(e) {

        e.preventDefault();

        Util.request.post(`customer/edit/${state.customer.customerId || 0}`, new FormData(e.target))
            .then(res => setState(o => ({
                ...o,
                editingCustomer: false,
                editingCustomerDone: true,
                editingCustomerMessage: Util.resolveResponseMessage(res),
                editingCustomerFailed: !res.status,
                errs: Util.resolveFormError(res)
            })));

        setState(o => ({ ...o, editingCustomer: true }));
    }


    return (
        <CardLayout
            cardElement='form'
            cardElementProps={{ onSubmit: isNewCustomer ? handleCreateCustomer : handleEditCustomer }}
            pageName={(isNewCustomer ? 'add' : 'edit') + " customer"}
            pageIcon={isNewCustomer ? 'plus' : 'edit'}
            cardFooter={<Button text={(isNewCustomer ? 'Add' : 'Edit') + " customer"}
                icon={isNewCustomer ? 'plus' : 'edit'} />}>

            <Busy busy={state.creatingCustomer} busyText='Creating customer...' />


            <Busy busy={state.gettingCustomer || state.edittingCustomer} />


            <SuccessStatusModal
                show={state.creatingCustomerDone && !state.creatingCustomerFailed}
                onDone={() => setState(o => ({ ...o, creatingCustomerDone: false }))}
                text={state.creatingCustomerMessage} />


            <SuccessStatusModal
                show={state.editingCustomerDone && !state.editingCustomerFailed}
                onDone={() => setState(o => ({ ...o, editingCustomerDone: false }))}
                text={state.editingCustomerMessage} />


            <ErrorStatusModal
                show={state.gettingCustomerDone && state.gettingCustomerFailed}
                onDone={() => setState(o => ({ ...o, gettingCustomerDone: false }))}
                text={state.gettingCustomerMessage} />


            <ErrorStatusModal
                show={state.editingCustomerDone && state.editingCustomerFailed}
                onDone={() => setState(o => ({ ...o, editingCustomerDone: false }))}
                text={state.editingCustomerMessage} />



            <div className='row'>

                <div className='col-12 col-md-6 mb-4'>
                    <InputField name='name' labelText='Names' errs={state.errs}
                        attrs={{ defaultValue: state.customer.name }} labelIcon='user' />
                </div>

                <div className='col-12 col-md-6 mb-4'>
                    <InputField name='phone' errs={state.errs} labelText='Phone' labelIcon='phone'
                        attrs={{ defaultValue: state.customer.phone }} />
                </div>


                <div className='col-12 col-md-6 mb-4'>
                    <div className={`form-check ${state.customer.allowCreditPurchase ? 'text-primary' : ''}`}>
                        <input className="form-check-input" type="checkbox" name='allow_credit_purchase' id="flexCheckDefault"
                            checked={state.customer.allowCreditPurchase} onChange={e => setState(o => ({ ...o, customer: { ...state.customer, allowCreditPurchase: e.target.checked } }))} />
                        <label  style={{ cursor: 'pointer' }} className="form-check-label" htmlFor="flexCheckDefault">
                            <b>Allow credit purchase</b> <br />
                            <small style={{ lineHeight: '110%', display: 'inline-block' }}>
                                If this is checked, purchase will be allowed even if subscription balance is 0. Otherwise purchase is disallowed.</small>
                        </label>
                    </div>
                </div>


                <div className='col-12 col-md-6 mb-4'>
                    <div className={`form-check ${state.customer.takePriceSnapshot ? 'text-primary' : ''}`}>
                        <input className="form-check-input" type="checkbox" name='take_price_snapshot' id="take_price_snapshot"
                            checked={state.customer.takePriceSnapshot} onChange={e => setState(o => ({ ...o, customer: { ...state.customer, takePriceSnapshot: e.target.checked } }))} />
                        <label style={{ cursor: 'pointer' }} className="form-check-label" htmlFor="take_price_snapshot">
                            <b>Take price snapshot</b><br />
                            <small style={{ lineHeight: '110%', display: 'inline-block' }}>
                                If this is checked, on every new subscription, a snapshot of the prices for all products will be taken and future
                                purchases will be made against the snapshot regardless of price changes until the subscription is renewed.
                                Otherwise purchases will always be made against the current set prices of all the products.
                            </small>
                        </label>
                    </div>
                </div>



            </div>


        </CardLayout>
    );
}


export default CreateCustomer;