import React, { useContext, useEffect, useState, useRef } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import Busy from '../components/Busy';
import Button from '../components/Button';
import ScrollEndTrigger from '../components/ScrollEndTrigger';
import Table from '../components/Table';
import CardLayout from '../layouts/CardLayout';
import Paths from '../Paths';
import Util from '../Util';
import Modal from '../components/Modal';
import { useQueryState } from '../hooks';
import ActionHeader from '../components/ActionHeader';
import InputField from '../components/InputField';


function PurchaseHistory(props) {
    const
        [state, setState] = useState({
            gettingHistory: false,
            gettingHistoryDone: false,
            gettingHistoryMessage: false,
            gettingHistoryFailed: false,
            data: { customer: {}, purchases: [] }
        }),
        deleter = useQueryState(),
        editor = useQueryState(),
        deleteRef = useRef(0),

        { customerId = 0 } = useParams(),

        navigate = useNavigate(),
        { user: { position } } = useContext(Util.UserContext);


    useEffect(() => {

        Util.request.get(`purchase/list/${customerId}`)
            // -DMF
            .then(res => setState(o => ({
                gettingHistory: false,
                gettingHistoryDone: true,
                gettingHistoryFailed: !res.status,
                gettingHistoryMessage: res.message,
                data: res.status ? res.data : state.data
            })))

        setState(o => ({ ...o, gettingHistory: true }));
    }, [deleteRef.current]);


    function handleDeleteEntry() {
        Util.request.post(`purchase/list/${customerId}/delete`)
            .then(res => {
                ++deleteRef.current;
                deleter.fromResponse(res)
            })
        deleter.busy('Deleting purchase entry.');
    }


    function handleEditEntry(e) {
        e.preventDefault();

        Util.request.post(`purchase/list/${customerId}/edit`, new FormData(e.target))
            .then(res => {
                ++deleteRef.current;
                editor.fromResponse(res);
            })
        editor.busy('Editing purchase entry');
    }

    return (
        <CardLayout
            pageName="prepaid Customer Purchases"
            pageIcon="clock"
            cardHeader={<ActionHeader title={state.data.customer.name} actions={[{
                text: 'Make purchase',
                icon: 'money-bill',
                onClick: e => navigate(Paths.PREPAID.join(Paths.PURCHASE.parse(customerId)).abs.$),
                showIf: position.canMakePurchase,
            }]} />}>

            <Busy busy={state.gettingHistory} busyText='Retrieving purchase data...' />

            <deleter.Busy />
            <deleter.Modal />

            <editor.Busy />
            <editor.Modal />

            <Modal on={handleDeleteEntry} type='danger' id='modal-delete-entry' title={'Delete entry?'}>
                Are you sure you want to delete last entry?
            </Modal>

            {
                !!state.data.purchases.length && (
                    <div className="modal fade" id='modal-edit-entry' data-bs-backdrop="static" data-bs-keyboard="false" tabIndex="-1" aria-hidden="true">
                        <div className="modal-dialog">
                            <form
                                key={state.data.purchases[0].id}
                                className="modal-content"
                                onSubmit={handleEditEntry}>
                                <div className="modal-header">
                                    <h4 className="modal-title fs-6 ">Edit last entry</h4>
                                    <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                </div>
                                <div className="modal-body">
                                    <h1 className={`fs-4 text-muted text-center`}>
                                        <i className={`fa fa-edit`}></i>
                                    </h1>
                                    <div className='my-3'>
                                        <InputField key={state.data.purchases[0].id} type={'date'} name='date' value={state.data.purchases[0].date} labelText='Date' />
                                    </div>

                                    <div className='row'>
                                        <div className='my-3 col-12 col-md-6'>
                                            <InputField key={state.data.purchases[0].id} name='litres' type={'number'} attrs={{ step: .2 }} value={state.data.purchases[0].litres} labelText='Litres' />
                                        </div>
                                        <div className='my-3 col-12 col-md-6'>
                                            <InputField key={state.data.purchases[0].id} name='price' type={'number'} attrs={{ step: .2 }} value={state.data.purchases[0].price} labelText='Price/Litre' />
                                        </div>
                                    </div>

                                </div>
                                <div className="modal-footer">
                                    <button type="button" className="btn btn-outline-secondary btn-sm border-0" data-bs-dismiss="modal">
                                        <i className={`fa fa-times me-2`}></i>
                                        Cancel
                                    </button>
                                    <button onClick={e => e.target.previousElementSibling.click()} className={`fw-bold btn btn-primary btn-sm outline-0 border-0`}>
                                        <i className={`fa fa-edit me-2`}></i>
                                        Edit
                                    </button>
                                </div>
                            </form>
                        </div>
                    </div>
                )
            }



            <ScrollEndTrigger maxHeight='380px'>
                <Table
                    keys={['date', 'time', 'product.name', '$price', 'discount', '$litres', '$amount', '$balance']}
                    tableHead={['Date', 'Time', 'Product', 'Purchase price', 'Discount', 'Litres', 'Amount', 'Balance']}
                    data={state.data.purchases.map(h => {
                        h.$price = `N${Util.format(h.price)}`;
                        h.$litres = Util.format(h.litres);
                        h.$amount = `N${Util.format(h.amount)}`;
                        h.$balance = `N${Util.format(h.balance)}`;

                        return h;
                    })}
                    actions={[
                        (data, i) => {
                            if (i === 0) {
                                return ({
                                    icon: 'edit',
                                    text: 'Edit',
                                    attrs: { 'data-bs-toggle': 'modal', 'data-bs-target': '#modal-edit-entry' },
                                    on() { },
                                })
                            }
                        },
                        (data, i) => {
                            if (i === 0) {
                                return ({
                                    icon: 'trash',
                                    text: 'Delete',
                                    cls: 'btn-outline-danger',
                                    attrs: { 'data-bs-toggle': 'modal', 'data-bs-target': '#modal-delete-entry' },
                                    on() { },
                                })
                            }
                        }
                    ]}
                />
            </ScrollEndTrigger>
        </CardLayout>
    );
}


export default PurchaseHistory;