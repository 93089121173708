import React, { useContext, useEffect, useRef, useState } from 'react';
import Busy from '../components/Busy';
import Button from '../components/Button';
import InputField from '../components/InputField';
import CardLayout from '../layouts/CardLayout';
import Util from '../Util';
import { ErrorStatusModal, SuccessStatusModal } from '../components/StatusModal'
import NumberInput from '../components/NumberInput';
import useQueryState from '../hooks/query';







function AddSlip(props) {
    const
        ctx = useContext(Util.UserContext),
        state = useQueryState();


    const handleAddSlip = async e => {

        e.preventDefault();

        Util.request.post('newslip',new FormData(e.target))
            .then(res => {
                state.fromResponse(res);
            });

        state.busy();
    }



    return (
        <CardLayout
            cardElement='form'
            cardElementProps={{ onSubmit: handleAddSlip }}
            pageName="submit pos slips"
            pageIcon="plus"
            cardFooter={<Button text='Add slip' icon='plus' />}>

            <state.Busy />

            <state.ModalSuccess />
            <state.ModalError />

            <InputField name="amount" labelIcon={'money-bill'} labelText={'Amount'} type={'number'} attrs={{ min: 0, step: .01 }} />

        </CardLayout>
    );
}


export default AddSlip;