import React, { useContext, useEffect, useState } from 'react';
import CardLayout from '../layouts/CardLayout';
import Util from '../Util';
import ScrollEndTrigger from '../components/ScrollEndTrigger';
import Button from '../components/Button';
import InputField from '../components/InputField';
import ActionHeader from '../components/ActionHeader';
import { useQueryState } from '../hooks';
import Table2 from '../components/Table2';


function MeterReadings(props) {
    let
        readings = useQueryState({ columns: [], rows: [] }),
        { currentOutlet } = useContext(Util.UserContext),
        [startDate, setStartDate] = useState(Util.today()),
        [stopDate, setStopDate] = useState(Util.today());


    function getReading() {
        Util.request.get(`pumpsreadings/${currentOutlet}/${startDate}/${stopDate}`)
            .then(res => {
                if (res.status) {
                    const flatReadings = [];

                    for (const { date, readings } of res.data.dates) {

                        // We're monkey patching the date to the row array
                        for (const reading of readings) reading.date = date;

                        flatReadings.push(readings);
                    }

                    // We're flattening the array to be compatible with our table.
                    readings.success(res.message, {
                        columns: res.data.pumps.map(o => ({
                            name: o.uniqueName,
                            selector: (data, row, col) => {
                                data = data[col - 2];
                                return (
                                    <div style={{ fontSize: '1.1rem', minWidth: '140px' }}>

                                        <div style={{ fontSize: '.7em', opacity: .9 }}>N{Util.format(data.price)}/L</div>
                                        {/* We're subtracting 2 from col to account for the extra S/N and Date prefixed columns */}
                                        <div style={{ fontWeight: 400, textAlign: 'center' }}>{data.raw}</div>
                                        <div style={{ fontSize: '.7em', opacity: .7, textAlign: 'right', whiteSpace: 'nowrap', textOverflow: 'ellipsis', overflow: 'hidden' }}>{data.attendant}</div>
                                    </div>
                                )
                            },
                            footer: (data, col) => <div className='text-center'>{Util.format(data[data.length - 1][col - 2].reading - data[0][col - 2].reading)}L</div>
                        })),
                        rows: flatReadings.flat(),
                    });
                } else {
                    readings.failed(res.message, readings.data);
                }
            });

        readings.busy();
    }


    useEffect(() => getReading(), []);



    function handleGetReading(e) {
        e.preventDefault();
        getReading();
    }


    return (
        <CardLayout
            pageName='Pump reading' pageIcon='calculator'
            cardHeader={<ActionHeader title={`Reading from ${startDate} to ${stopDate}`} />}
        >

            <readings.Busy />
            <readings.ModalError />

            <ScrollEndTrigger
                stretch
                maxHeight={'400px'}
            >

                <form onSubmit={handleGetReading}>
                    <div className='row mb-5'>
                        <div className='col-12 col-md-6 col-lg-2'>
                            <InputField value={Util.today()} attrs={{ onChange: e => setStartDate(e.target.value) }} type='date' name='startDate' labelText='Start date' labelIcon='calendar' />
                        </div>

                        <div className='col-12 col-md-6 col-lg-2'>
                            <InputField value={Util.today()} attrs={{ onChange: e => setStopDate(e.target.value) }} type='date' name='stopDate' labelText='Stop date' labelIcon='calendar' />
                        </div>

                        <div className='col-12 mt-1'>
                            <Button text='Get' icon='download' />
                        </div>
                    </div>
                </form>


                <Table2
                    data={readings.data.rows || []}
                    columns={[
                        Table2.serialColumn,
                        {
                            name: 'Date',
                            selector: o => o.date,
                        },
                        ...(!!readings.data.columns.length ? readings.data.columns : []),
                    ]}
                />
            </ScrollEndTrigger>

        </CardLayout>
    );
}

export default MeterReadings;