import React, { useContext, useState } from 'react';
import CardLayout from '../layouts/CardLayout';
import Util from '../Util';
import Busy from '../components/Busy';
import ScrollEndTrigger from '../components/ScrollEndTrigger';
import { ErrorStatusModal, SuccessStatusModal } from '../components/StatusModal';
import InputField from '../components/InputField';


function CreateTank(props) {
    const
        [state, setState] = useState({

            creatingTank: false,
            creatingTankDone: false,
            creatingTankError: false,
            creatingTankText: ''

        }),
        [errors, setErrors] = useState({}),

        { user: { productTypes } } = useContext(Util.UserContext);



    const handleSubmit = (e) => {
        e.preventDefault();

        Util.request.post('createtank', new FormData(e.target))
            .then(res => {
                if (res.status) {
                    setState(o => ({
                        ...o,
                        creatingTank: false,
                        creatingTankDone: true,
                        creatingTankText: res.message,
                        creatingTankError: !res.status
                    }));

                    setErrors({});

                }
                else {
                    setState(o => ({
                        ...o,
                        creatingTank: false,
                        creatingTankDone: true,
                        creatingTankText: res.message,
                        creatingTankError: !res.status
                    }));
                    setErrors(res.message);
                }
            });
        setState(o => ({ ...o, creatingTank: true }))
    };

    const actionButton = <button type='submit' className='btn btn-primary'>
        <i className='fa fa-send me-2'></i>
        <span>Create tank</span>
    </button>;

    return (
        <CardLayout cardElement='form' cardElementProps={{ method: 'post', onSubmit: handleSubmit }}
            pageName='Create tank' pageIcon='plus' cardFooter={actionButton}>

            <Busy busy={state.creatingTank} busyText='Creating tank...' />

            <SuccessStatusModal
                show={state.creatingTankDone && !state.creatingTankError}
                onDone={() => setState(o => ({ ...o, creatingTankDone: false }))}
                text={state.creatingTankText} />

            <ErrorStatusModal
                show={state.creatingTankDone && state.creatingTankError}
                onDone={() => setState(o => ({ ...o, creatingTankDone: false }))}
                text={state.creatingTankText} />



            <ScrollEndTrigger>
                <div className='row'>

                    <div className='col-12 col-md-6'>
                        <div className='mb-3'>
                            <label className='form-label text-muted' htmlFor='manager'>Tank type</label>
                            <select className='form-select' name='tank_type'>
                                {productTypes.map(t => <option key={t.id} value={t.id}>{t.name}</option>)}
                            </select>
                        </div>
                    </div>



                    <div className='mb-3 col-md-6'>
                        <InputField name='tank_name' labelText='Tank name' errs={errors} />
                    </div>



                    <div className='mb-3 col-md-6'>
                        <InputField name='capacity' labelText='Capacity' errs={errors} attrs={{ min: 0, defaultValue: 0 }} />
                    </div>



                    {/* 
                    <div className='mb-3 col-md-6'>
                        <InputField name='tank_adjustment' type='number' labelText='Adjustment' errs={errors} attrs={{ min: 0, defaultValue: 1, max: 1, step:'.01' }} />
                    </div> */}


                    <div className='col-12 col-md-6'>
                        <div className='mb-3'>
                            <label className='form-label text-muted' htmlFor='manager'>Category</label>
                            <select className='form-select' name='category'>
                                <option value="Service">Service</option>
                                <option value="Reserve">Reserve</option>
                            </select>
                        </div>
                    </div>

                </div>
            </ScrollEndTrigger>


        </CardLayout>
    );
}

export default CreateTank;